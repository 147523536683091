import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Browser } from '@capacitor/browser';
import { PlatformService } from '../../../platform-disable-content/platform.service';

@Component({
  selector: 'roma-ios-activation',
  templateUrl: './ios-activation.component.html',
  styleUrls: ['./ios-activation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IosActivationComponent {
  public readonly url = 'https://app.taclia.com';

  constructor(
    private dialogRef: MatDialogRef<IosActivationComponent>,
    private platformService: PlatformService
  ) {}

  get urlParsed(): string {
    return this.url.replace('https://', '');
  }

  get mobileHeight(): string {
    return window.innerHeight < 820 ? 'mobile-70h' : 'mobile-50h';
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public async openAndroidBrowser(): Promise<void> {
    if (this.platformService.isAndroid()) {
      await Browser.open({ url: this.url });
    }
  }
}
