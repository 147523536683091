import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import {
  AuthService,
  SandboxService,
  StorageService,
} from '@web-frontend/shared/services';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import AmplitudeEvents from '../../../../../types/amplitude.enum';
import { PlanService } from '../plans/plans.service';

@Component({
  selector: 'roma-pro-disclaimer-banner',
  templateUrl: './pro-disclaimer-banner.component.html',
  styleUrls: ['./pro-disclaimer-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProDisclaimerBannerComponent implements OnInit, OnDestroy {
  remainingDays = null;
  proPlanActivated = false;
  feature = null;
  @Input() rootModule: string;

  @Input() showDisclaimer = false;

  @Input() text: string;

  @Output() emitRefresh: EventEmitter<boolean> = new EventEmitter();
  trialPeriodUsed = false;

  maxUsersDisclaimer = false;
  featureRef = Feature;
  originalRootModule: string;
  resize$ = this.sandBoxService.screenBusChannel$;
  sub$ = new Subscription();

  buttonLabel: string;

  constructor(
    private i18n: TranslateService,
    public permissionService: PermissionService,
    public authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private sandBoxService: SandboxService,
    private readonly analyticsService: AnalyticsService,
    private readonly planService: PlanService
  ) {}

  ngOnInit(): void {
    this.originalRootModule = this.rootModule;
    this.sub$.add(
      this.authService.isNoLoginUser$.subscribe((isNoLogin) => {
        this.buttonLabel = isNoLogin
          ? 'pro.disclaimer.buttonNoLogin'
          : 'pro.disclaimer.button';
      })
    );

    if (environment.MembershipSchema === 'ONE_PRO_PLAN')
      this.rootModule = 'taclia';

    this.sub$.add(
      this.permissionService.trialStatus$
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.remainingDays = res.remainingDays;
          this.proPlanActivated = res.proPlanActivated;

          this.draw();
        })
    );
    this.checkTrial();
    this.showMaxUsersDisclaimer();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  async checkTrial(): Promise<void> {
    const res = await this.permissionService.checkTrialSubscription(
      StorageService.CompanyId,
      'service'
    );
    if (res.remainingDays >= 0 && res.remainingDays !== null) {
      this.permissionService.setTrialStatus({
        proPlanActivated: res.proPlanActivated,
        remainingDays: res.remainingDays,
      });
    }

    this.draw();
  }

  private draw() {
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  geti18nParsedString(): string {
    let translation = '';

    if (environment.MembershipSchema === 'ONE_PRO_PLAN') {
      this.feature = Feature.Sale.DownloadBillExcel;

      this.sub$.add(
        this.resize$.subscribe((res) => {
          if (res.payload.mobile) {
            translation = this.i18n.instant(
              'pro.disclaimer.bannerTrialActiveMobile'
            );
          } else {
            translation = this.i18n.instant('pro.disclaimer.bannerTrialActive');
          }
        })
      );

      if (this.text === 'pro.clockHour.disclaimer.maxUsers') {
        translation = this.i18n.instant(this.text);
      }
    } else {
      const rootModuleFeature = {
        service: Feature.Deal.DownloadExcel,
        final: Feature.Final.DownloadExcel,
        task: Feature.Activity.DownloadExcel,
        sales: Feature.Sale.DownloadBillExcel,
        timetracking2: Feature.ClockHour.DownloadExcel,
      };
      const rootModuleText = {
        service: 'pro.deal.disclaimer.bannerTrialActive',
        final: 'pro.final.disclaimer.bannerTrialActive',
        task: 'pro.activity.disclaimer.bannerTrialActive',
        sales: 'pro.sale.disclaimer.bannerTrialActive',
        timetracking2: 'pro.clockHour.disclaimer.bannerTrialActive',
      };

      this.feature = rootModuleFeature[this.rootModule];
      translation = this.i18n.instant(rootModuleText[this.rootModule]);

      if (this.rootModule === 'timetracking2' && !this.text) {
        this.text = 'pro.clockHour.disclaimer.bannerTrialActive';
      }
    }

    // let translation = this.i18n.instant(translate);
    if (translation.includes('{arg}')) {
      translation = translation.replace(`{arg}`, this.remainingDays);
    }
    return translation;
  }

  // find element inside array of objects using includes method in javascript

  openBuyModel(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze'],
      eventName: AmplitudeEvents.account_becomePro_banner,
    });
    this.planService.openPlansModal({
      onClose: () => this.draw(),
    });
  }

  showMaxUsersDisclaimer(): void {
    this.permissionService
      .hasFeatureFn(Feature.ClockHour.ViewTimer)
      .pipe(
        finalize(() => {
          this.draw();
        })
      )
      .subscribe((resp) => {
        this.maxUsersDisclaimer = !resp;
      });
  }

  get isLowerThan1450px(): boolean {
    return window.innerWidth < 1450;
  }
}
