<form *ngIf="showButton" class="equipment-notes-footer" [formGroup]="form">
  <div class="row">
    <div class="col-6">
      <p
        style="
          color: #15152e;
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 0;
        "
      >
        {{ 'clockHour.shiftsHours' | translate }}
      </p>
    </div>
    <div class="col-6" style="text-align: right">
      <button
        class="add-hours-btn"
        [disabled]="!form.valid"
        (click)="addDate()"
      >
        {{ 'clockHour.create.form.addHour' | translate }}
        <img src="/assets/images/gl_plus-circle.svg" alt="Añadir" />
      </button>
    </div>
  </div>
  <ng-container formArrayName="dates">
    <div
      *ngFor="let datesForm of dates.controls; let i = index"
      class="shift-wrapper"
      [formGroup]="datesForm"
    >
      <div class="inputs">
        <div class="inputs-container">
          <div class="time-input-wrapper" [ngClass]="{ disabled: disabled }">
            <input
              class="form-control"
              id="startDate"
              aria-describedby="startDateHelp"
              formControlName="startDate"
              [placeholder]="'clockHour.selectProject' | translate"
              matInput
              mask="Hh:m0"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
              [disabled]="disabled"
              (change)="onChangeEvent(i)"
              (blur)="onChangeEvent(i)"
            />
            <div class="time-list-wrapper" *ngIf="startTimePicker === i">
              <div class="time-list">
                <button
                  *ngFor="let item of hours"
                  [ngClass]="{ active: hour === item.hour }"
                  (click)="hourChange(item.hour)"
                >
                  {{ item.hour }}
                </button>
              </div>
              <div class="time-list">
                <button
                  *ngFor="let item of minutes"
                  [ngClass]="{ active: min === item.min }"
                  (click)="minutesChange(item.min)"
                >
                  {{ item.min }}
                </button>
              </div>
              <mat-icon class="set-time" (click)="setTime(i, 'startDate')">
                check
              </mat-icon>
            </div>
          </div>
          <div class="time-input-wrapper" [ngClass]="{ disabled: disabled }">
            <input
              class="form-control"
              id="endDate"
              aria-describedby="endDateHelp"
              formControlName="endDate"
              [placeholder]="
                'clockHour.create.form.endDatePlaceholder' | translate
              "
              matInput
              mask="Hh:m0"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
              [disabled]="disabled"
              (change)="onChangeEvent(i)"
              (blur)="onChangeEvent(i)"
            />
            <div *ngIf="endTimePicker === i" class="time-list-wrapper">
              <div class="time-list">
                <button
                  *ngFor="let item of hours"
                  [ngClass]="{ active: hour === item.hour }"
                  (click)="hourChange(item.hour)"
                >
                  {{ item.hour }}
                </button>
              </div>
              <div class="time-list">
                <button
                  *ngFor="let item of minutes"
                  (click)="minutesChange(item.min)"
                  [ngClass]="{ active: min === item.min }"
                >
                  {{ item.min }}
                </button>
              </div>
              <mat-icon class="set-time" (click)="setTime(i, 'endDate')">
                check
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="selects-container">
          <div
            class="internal-data-options-item wrapper-pro-feature-user"
            *ngIf="(idActiveModules$ | async)?.includes(idModuleRef.Projects)"
            romaProSelect
            [featureUserHide]="featureRefUser.Project.show"
          >
            <rm-select-register
              id="project_id"
              class="select-payments"
              [isClearable]="true"
              [placeholderText]="'clockHour.selectProject' | translate"
              [items]="projects"
              [isLoading]="isLoadingProjects"
              [activeItems]="getProject(i)"
              [isAbsolute]="true"
              [fullWidthList]="true"
              [allOptionsEnabled]="false"
              [listWidth]="'100%'"
              [loadDefaultItem]="false"
              [disabled]="false"
              inputHeight="36px"
              (changeItems)="changeProject(i, $event)"
            >
            </rm-select-register>
          </div>

          <div
            class="internal-data-options-item wrapper-pro-feature-user"
            *ngIf="(idActiveModules$ | async)?.includes(idModuleRef.Deals)"
            romaProSelect
            [featureUser]="featureRefUser.Deal.show"
          >
            <rm-select-register
              id="category_id"
              class="select-payments"
              [isClearable]="true"
              [placeholderText]="'clockHour.selectDeal' | translate"
              [items]="deals"
              [isLoading]="isLoadingDeals"
              [activeItems]="getDeal(i)"
              [isAbsolute]="true"
              [fullWidthList]="true"
              [allOptionsEnabled]="false"
              [listWidth]="'100%'"
              [loadDefaultItem]="false"
              [disabled]="false"
              inputHeight="36px"
              (changeItems)="changeDeals(i, $event)"
            >
            </rm-select-register>
          </div>
        </div>
      </div>

      <div class="button-delete">
        <div class="container-actions" *ngIf="!disabled">
          <img
            *ngIf="!isMobile"
            src="/assets/icons/gl_trash.svg"
            (click)="delete(i)"
          />
          <div class="delete-text" *ngIf="isMobile" (click)="delete(i)">
            {{ 'clockHour.deleteShift' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>
