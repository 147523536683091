<div
  romaProSelect
  [feature]="feature"
  [options]="{
    className: 'roma-label warning right-center'
  }"
  class="wrapper-pro-feature"
  [ngClass]="{ sm__size: smSize }"
>
  <select
    #selectBoolean
    class="select-editable form-control"
    [ngClass]="{ hidden: type !== 'boolean', 'form-control': true }"
    [value]="convertInput(value)"
    (change)="submit(selectBoolean)"
  >
    <option value="true">{{ 'general.yes' | translate }}</option>
    <option value="false">{{ 'general.no' | translate }}</option>
  </select>

  <select
    #selectArrayString
    class="select-editable form-control"
    [ngClass]="{ hidden: type !== 'array-string' }"
    [attr.multiple]="isMultiple ? true : null"
    (change)="submit(selectArrayString)"
  >
    <option value="">Sin asignar</option>

    <option
      *ngFor="let item of elements"
      [value]="item"
      [attr.selected]="item === value ? true : null"
    ></option>
    <option value=" ">{{ 'general.option_select' | translate }}</option>
    <option value="true">{{ 'general.yes' | translate }}</option>
    <option value="false">{{ 'general.no' | translate }}</option>
  </select>

  <select
    #selectArrayString
    class="select-editable"
    [ngClass]="{ hidden: type !== 'array-string' }"
    [attr.multiple]="isMultiple ? true : null"
    (change)="submit(selectArrayString)"
  >
    <option value="">Sin asignar</option>

    <option
      *ngFor="let item of elements"
      [value]="item"
      [attr.selected]="item === value ? true : null"
    >
      {{ item }}
    </option>
  </select>

  <ng-select
    [ngClass]="{ hidden: type !== 'array-string-multiple' }"
    [items]="elements"
    [multiple]="true"
    [(ngModel)]="value"
    placeholder="{{ 'activity.edit.selectOption' | translate }}"
    [notFoundText]="'general.notFoundText' | translate"
    (change)="submitNgSelect($event)"
    style="margin-bottom: 0 !important; width: 100%"
  ></ng-select>

  <input
    #inputNumberEditable
    type="number"
    class="input-editable form-control"
    [ngClass]="{ hidden: type !== 'number' }"
    [value]="convertInput(value)"
    placeholder="{{ 'general.label_editable_field' | translate }}"
    (focus)="inputFocus()"
    (blur)="submit(inputNumberEditable)"
  />

  <ng-container *ngIf="type === 'date'">
    <mat-form-field appearance="fill" #date class="full-field">
      <input
        #inputDateEditable
        class="input-editable date-input"
        id="inputDateEditable"
        [ngClass]="{ hidden: type !== 'date' }"
        [placeholder]="company_date_format"
        [value]="convertInput(currentValue)"
        (focus)="picker.open(); inputFocus()"
        (click)="picker.open()"
        (dateChange)="submit(inputDateEditable)"
        (blur)="submit(inputDateEditable)"
        matInput
        [matDatepicker]="picker"
        onkeydown="return false;"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        style="height: 35px"
        (click)="picker.open(); inputFocus()"
      >
        <mat-icon
          matDatepickerToggleIcon
          style="
            height: 35px;
            position: relative;
            display: flex;
            align-items: center;
          "
        >
          <img class="calendar-icon" src="assets/icons/gl_calendar.svg" />
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <div
        *ngIf="inputDateEditable.value === ''"
        class="fake-placeholder"
        (click)="picker.open(); inputFocus()"
      >
        {{ placeholderDate | translate }}
      </div>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div
      class="calendar-clean-icon"
      matSuffix
      (click)="inputDateEditable.value = ''; submit(inputDateEditable)"
      *ngIf="inputDateEditable.value !== ''"
    >
      <mat-icon matDatepickerToggleIcon style="position: relative">
        <img class="calendar-icon" src="assets/icons/gl_close-input.svg" />
      </mat-icon>
    </div>
  </ng-container>

  <input
    #inputPhoneEditable
    type="text"
    class="input-editable form-control"
    [ngClass]="{ hidden: type !== 'phone' }"
    [value]="value"
    [maxLength]="20"
    placeholder="{{ 'general.label_editable_field' | translate }}"
    onpaste="return false;"
    (focus)="inputFocus()"
    (blur)="submit(inputPhoneEditable)"
  />

  <input
    #inputEditable
    type="text"
    class="input-editable form-control"
    [ngClass]="{ hidden: type !== 'text' }"
    [value]="value || ''"
    [maxLength]="maxLength"
    placeholder="{{ 'general.label_editable_field' | translate }}"
    (focus)="inputFocus()"
    (blur)="submit(inputEditable)"
    (input)="validateLength($event?.target)"
  />
  <!--
<mat-form-field appearance="outline" >
  <mat-label>{{ label | translate }}</mat-label>
  <input
    #inputEditablemat
    matInput
    type="text"
    [value]="value"
    [ngClass]="{ hidden: (style !== 'mat-form' && type !== 'text')  }"
    [maxLength]="maxLength"
    [placeholder]="labelPh | translate"
    (focus)="inputFocus()"
    (blur)="submit(inputEditable)"
  />

</mat-form-field> -->

  <input
    #inputJiraEditable
    type="text"
    class="input-editable form-control"
    [ngClass]="{ hidden: type !== 'jira-time' }"
    [value]="convertInput(value)"
    [maxLength]="maxLength"
    placeholder="Ej: 4h 30m"
    (focus)="inputFocus()"
    (blur)="resolveJiraTime(inputJiraEditable)"
  />

  <input
    *ngIf="type === 'google-address'"
    #inputGoogleEditable
    romaGooglePlaces
    type="text"
    class="input-editable form-control"
    placeholder="{{ 'general.label_editable_field' | translate }}"
    [ngClass]="{ hidden: type !== 'google-address' }"
    [attr.value]="value"
    (focus)="inputFocus()"
    (blur)="saveCloseFocus($event?.target?.value)"
    (input)="autoCompleteChange($event?.target?.value)"
    (placeSelected)="autoCompleteAddress($event)"
  />


  <textarea
    #textAreaEditable
    [class]="editableClass"
    [ngClass]="{ hidden: type !== 'long-text' }"
    [value]="value"
    placeholder="{{ 'general.label_editable_field' | translate }}"
    (focus)="inputFocus()"
    [maxLength]="maxLength"
    (blur)="submit(textAreaEditable)"
  ></textarea>

  <div *ngIf="isFocus || isDanger" class="container-options">
    <div *ngIf="isDanger && !isWarning" style="position: absolute; left: 0; bottom: -24px">
      <mat-error class="error-label">{{
        'general.requiredField' | translate
      }}</mat-error>
    </div>

    <button *ngIf="isFocus" mat-icon-button color="primary">
      <mat-icon>done</mat-icon>
    </button>
    <div *ngIf="isWarning && maxLengthErrorMessage" style="position: absolute; left: 0; bottom: -24px">
      <mat-error class="error-label">{{maxLengthErrorMessage}}</mat-error>
    </div>

    <button *ngIf="isFocus" mat-icon-button color="primary" (click)="abort()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
