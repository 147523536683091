import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModuleType } from '../../../../../types-legacy/lib/types';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../../services/analytics/analytics.service';

interface Feature {
  name: string;
  pro?: boolean | string;
  basic?: boolean | string;
  otherSection: boolean;
}

interface Plan {
  id: string;
  name: string;
  price: string;
  pricePerYear: string;
}

@Component({
  selector: 'roma-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
})
export class PlanSelectionComponent {
  @Input() moduleName: string;
  @Input() moduleIcon: string;
  @Input() moduleType: ModuleType;
  @Input() description: string;
  @Input() startingPrice: string;
  @Input() isFullPlan: boolean;
  @Input() currency: string;
  @Input() isPeriodYearly: boolean;
  @Input() plans: Array<{
    id: string;
    name: string;
    price: string;
    pricePerYear: string;
  }>;
  @Input() features: Array<Feature>;
  @Output() selectPlan = new EventEmitter();

  isCollapsed = window.innerWidth < 768;
  moduleStyles = {
    [ModuleType.CRM]: {
      background: 'rgba(248, 235, 251, 0.60)',
      color: '#9F2BB6',
      priceBackground: '#F8EBFB',
    },
    [ModuleType.SALES]: {
      background: 'rgba(225, 247, 235, 0.60)',
      color: '#066D38',
      priceBackground: '#E1F7EB',
    },
    [ModuleType.FULL]: {
      background: 'rgba(233, 234, 253, 0.60)',
      color: '#2B33A8',
      priceBackground: '#E9EAFD',
    },
    [ModuleType.TIME_TRACKING]: {
      background: 'rgba(255, 241, 217, 0.60)',
      color: '#905E09',
      priceBackground: '#FFF1D9',
    },
  };

  showMoreFeatures = false;

  constructor(
    private i18n: TranslateService,
    private readonly analyticsService: AnalyticsService
  ) {}

  get featuresFiltered(): Array<Feature> {
    return this.features.filter(
      (feature) => this.showMoreFeatures || !feature.otherSection
    );
  }

  toggleFeatures(): void {
    this.showMoreFeatures = !this.showMoreFeatures;
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onSelectPlan(plan: Plan): void {
    this.selectPlan.emit(plan);
    this.analyticsService.trackEvent({
      sources: ['braze'],
      eventName: `${plan.id}_subscribe`,
    });
  }

  getStyles(): { background: string; color: string } {
    return (
      this.moduleStyles[this.moduleType] || {
        background: 'transparent',
        color: '#000000',
        priceBackground: '#FFFFFF',
      }
    );
  }

  isBoolean(value: string | boolean): boolean {
    return typeof value === 'boolean';
  }

  getPlanTranslationKey(name: string): string {
    return `pro.planSelection.${name}`;
  }

  isUnlimited(value: string): boolean {
    if (!value) return false;
    const translatedValue = this.i18n.instant(value).trim();
    return /^(ilimitados|unlimited)$/i.test(translatedValue);
  }
}
