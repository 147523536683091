<roma-modal-template
  [title]="'general.addFinal' | translate"
  [showDefaultButtons]="false"
  (cancel)="close()"
  size="md"
  class="create-final-partially"
  [footerTemplate]="footerButtons"
>
  <div class="client-form">
    <form [formGroup]="form">
      <div class="form-group">
        <label class="form-text" for="name">
          {{ 'finals.create.nameOrSocial' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>
        <input
          type="text"
          class="general-form-control md form-control"
          id="name"
          aria-describedby="name"
          formControlName="name"
          (input)="changeName($event)"
          [placeholder]="'finals.create.namePh' | translate"
          [ngClass]="{
            'is-invalid':
              form.controls.name?.invalid && form.controls.name?.touched
          }"
        />
        <roma-field-error-display
          #fieldName
          fieldName="name"
          [errorMsg]="'general.required'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>
      <div class="form-group">
        <label class="form-text" for="nif">
          {{ 'finals.create.nif' | translate }}
        </label>
        <input
          type="text"
          class="general-form-control md form-control"
          id="nif"
          aria-describedby="nif"
          formControlName="nif"
          [placeholder]="'finals.create.nifPh' | translate"
        />
      </div>
      <div class="form-group">
        <label class="form-text" for="address">
          {{ 'finals.create.address' | translate }}
        </label>
        <input
          type="text"
          class="general-form-control md form-control"
          id="address"
          aria-describedby="address"
          formControlName="address"
          [placeholder]="'finals.create.addressPh' | translate"
          matInput
          romaGooglePlaces
          name="address"
          (placeSelected)="autocompleteSelected($event)"
        />
      </div>
      <div class="dual-column">
        <div class="form-group client-form">
          <label class="form-text" for="address">
            {{ 'finals.create.email' | translate }}
          </label>
          <input
            type="text"
            class="general-form-control md form-control input"
            id="email"
            aria-describedby="email"
            formControlName="email"
            [placeholder]="'finals.create.emailPh' | translate"
            matInput
            [ngClass]="{
              'is-invalid': form.get('email').errors || false
            }"
            name="address"
          />
        </div>
        <div class="form-group client-form">
          <label class="form-text" for="address">
            {{ 'finals.create.phone' | translate }}
          </label>
          <roma-rm-phone-select-input
            [phoneValue]="phone || null"
            [inputClass]="'placeholderBlue'"
            [autoPrefix]="!phone.pre"
            (phoneChanges)="phoneChanges($event)"
          ></roma-rm-phone-select-input>
        </div>
      </div>
      <div class="info-banner">
        <img src="/assets/icons/gl_exclamation-circle.svg" />
        <div>
          {{ 'finals.create.banner' | translate }}
        </div>
      </div>
    </form>
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="footer-actions">
    <base-button
      type="submit"
      [disabled]="isLoading || form.invalid"
      [isLoading]="isLoading"
      (mouseenter)="displayTooltip(true)"
      (mouseleave)="displayTooltip(false)"
      (click)="submit()"
    >
      {{ 'forms.save' | translate }}
    </base-button>
    <div class="tooltip-container">
      <div
        *ngIf="showToolTip && form.invalid"
        class="title-tooltip-wrapper"
      ></div>
      <div
        class="help-tooltip"
        *ngIf="showToolTip && form.invalid"
        romaRomaToolTip
        [showToolTip]="showToolTip"
      >
        <div
          [innerHTML]="
            'budgets.settings.create.form.tooltip-invalid' | translate
          "
        ></div>
      </div>
    </div></div
></ng-template>
