<roma-modal-template
  [title]="
    mode === 'ADD'
      ? ('clockHour.create.form.title' | translate)
      : ('clockHour.edit.form.title' | translate)
  "
  [isLoading]="flightRequest"
  (success)="submit()"
  (cancel)="close({ reload: false })"
  [showCancelBtn]="false"
  [askBeforeClose]="haveChanges"
  [disableSuccess]="wrongTime || hasOverlapError"
  [footerTemplate]="footerCustomTemplate"
  [showDefaultButtons]="false"
>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="user">
            {{ 'clockHour.create.form.user' | translate }}
          </label>
          <rm-select
            id="idOwner"
            [placeholderText]="'general.select' | translate"
            [items]="authors"
            [isLoading]="loadingAuthors"
            [activeItems]="owner"
            (addElement)="addAuthors()"
            inputHeight="44px"
            type="users"
            [hasError]="
              form.controls['idOwner'].errors?.required && idOwnerInvalid
            "
            [disabled]="mode === 'EDIT' ? true : false"
            (changeItems)="changeAuthors($event)"
            (click)="onClickSelect('authors')"
          >
          </rm-select>
          <div
            *ngIf="form.controls['idOwner'].errors?.required && idOwnerInvalid"
            class="invalid-feedback"
          >
            {{ 'clockHour.create.form.idOwnerError' | translate }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            for="date"
            class="{ 'text-danger': date.displayError || form.get('date').errors?.clockHourRepeatDate || false}"
          >
            {{ 'clockHour.create.form.date' | translate }}
          </label>
          <input
            type="date"
            class="form-control"
            id="date"
            aria-describedby="dateHelp"
            formControlName="date"
            [placeholder]="'clockHour.create.form.datePlaceholder' | translate"
            [ngClass]="{
              'is-invalid':
                date.displayError ||
                form.get('date').errors?.clockHourRepeatDate ||
                false
            }"
            [max]="today"
          />

          <roma-field-error-display
            #date
            fieldName="date"
            [errorMsg]="'clockHour.create.form.dateRequired'"
            [form]="form"
          >
          </roma-field-error-display>
          <div
            *ngIf="form.get('date').errors?.clockHourRepeatDate"
            class="invalid-feedback"
          >
            {{ 'clockHour.create.form.dateRepeatError' | translate }}
          </div>
        </div>
      </div>
    </div>

    <roma-add-time
      [mode]="mode"
      [data]="histories || []"
      (changes)="changeClockHistories($event)"
      class="add-time"
    >
    </roma-add-time>

    <div class="error-container">
      <label class="errorLabel" *ngIf="wrongTime">
        <ng-container *ngIf="!equalTime"
          >*{{
            'clockHour.create.form.errorHistories' | translate
          }}</ng-container
        >
      </label>

      <label class="errorLabel" *ngIf="hasOverlapError">
        {{ 'clockHour.create.form.overlapError' | translate }}
      </label>
    </div>

    <p class="note-label">
      {{ 'general.notes' | translate }}
    </p>

    <textarea
      cols="30"
      rows="1"
      class="note-input"
      formControlName="note"
      maxlength="255"
      [placeholder]="'equipments.create.notePh' | translate"
    >
    </textarea>

    <div class="total-hours">
      TOTAL
      <span class="hours">{{ totalHoursClock.replace(':', 'h ') }}m</span>
    </div>
  </form>
</roma-modal-template>

<ng-template #footerCustomTemplate>
  <div class="row gx-2 m-2">
    <div class="col">
      <base-button
        romaClickDelete
        buttonClass="tertiary"
        (wantDelete)="delete()"
      >
        {{ 'general.remove' | translate }}
      </base-button>
    </div>
    <div class="col">
      <base-button buttonClass="primary" (click)="submit()">
        {{ 'general.save' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
