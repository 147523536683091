import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-no-login-register-modal',
  templateUrl: './no-login-register-modal.component.html',
  styleUrls: ['./no-login-register-modal.component.scss'],
})
export class NoLoginRegisterModalComponent {
  constructor(public dialogRef: MatDialogRef<NoLoginRegisterModalComponent>) {}

  exit(): void {
    this.dialogRef.close();
  }
}
