import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../storage';
import { Observable, of } from 'rxjs';

export type ResourceType = 'videoTutorial';

export type TutorialModule =
  | 'deals'
  | 'activities'
  | 'finals'
  | 'internal-vendors'
  | 'budgets'
  | 'projects'
  | 'assets'
  | 'equipments'
  | 'reports'
  | 'expenses'
  | 'rates'
  | 'templates'
  | 'clock-hours-mine'
  | 'clock-hours-team'
  | 'clock-absence-mine'
  | 'clock-absence-team'
  | 'tracking'
  | 'payments'
  | 'calendar'
  | 'person'
  | 'sales'
  | 'booking'
  | 'customization'
  | 'users'
  | 'bills'
  | 'company-modules'
  | 'help-delete'
  | 'notifications'
  | 'serial-numbers'
  | 'general-settings'
  | 'holidays';

export type TutorialResponse = {
  id?: string;
  source: string;
  description?: string;
  title?: string;
  module?: TutorialModule;
  countries?: ('ES' | 'EN')[];
  languages?: ('ES' | 'EN')[];
  createdAt?: string;
  academy?: string;
};

const URL_MS_TUTORIALS = 'tutorials';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  constructor(private http: HttpClient, private i18n: TranslateService) {}

  get(module: TutorialModule): Observable<TutorialResponse> {
    const userLocale =
      StorageService.GetItem('USER_LANG') || this.i18n.currentLang || 'es';

    return this.http
      .get<TutorialResponse>(
        `:API_BASE_URL${URL_MS_TUTORIALS}/tutorials/${module}/languages/${userLocale}`
      )
      .pipe(
        map((value) => {
          if (value) {
            return {
              ...value,
            };
          }
          return this.notFoundModuleHandler(module);
        }),
        catchError(() => of(this.notFoundModuleHandler(module)))
      );
  }

  private notFoundModuleHandler(module: TutorialModule) {
    return { source: '', academy: '', module };
  }
}
