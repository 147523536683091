import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ClockAbsence } from '../../../../../types-legacy';
import { AddClockAbsence } from '../../../../shared/components/clock-absences';

interface AbsenceCount {
  type: keyof typeof ClockAbsence.AbsenceType;
  count: number;
  color?: string;
  displayName?: string;
}

@Component({
  selector: 'roma-absence-summary-counter',
  templateUrl: './absence-summary-counter.component.html',
  styleUrls: ['./absence-summary-counter.component.scss'],
})
export class AbsenceSummaryCounterComponent implements OnInit {
  @Input() title = '';
  @Input() loadingTotal = false;

  private _absenceCounts: AbsenceCount[] = [];
  @Input() set absenceCounts(items: AbsenceCount[]) {
    this._absenceCounts = items;
    this.updateAbsenceCounts();
  }
  get absenceCounts(): AbsenceCount[] {
    return this.displayAbsenceCounts;
  }

  displayAbsenceCounts: AbsenceCount[] = [];

  private readonly colorMap: Record<ClockAbsence.AbsenceType, string> = {
    [ClockAbsence.AbsenceType.Vacation]: '#8861FA',
    [ClockAbsence.AbsenceType.Sickness]: '#FDB53A',
    [ClockAbsence.AbsenceType.MaternityOrPaternity]: '#E2A7ED',
    [ClockAbsence.AbsenceType.SicknessOfAFamilyMember]: '#FC742B',
    [ClockAbsence.AbsenceType.Other]: '#34B8FA',
  } as const;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.updateAbsenceCounts();
  }

  private updateAbsenceCounts(): void {
    this.displayAbsenceCounts = this._absenceCounts.map(this.createCountItems);
    this.cdr.detectChanges();
  }

  private createCountItems = (item: AbsenceCount): AbsenceCount => {
    return {
      ...item,
      displayName: this.mapTypeName(item.type),
      color: this.getColor(item.type),
    };
  };

  private mapTypeName(type: keyof typeof ClockAbsence.AbsenceType): string {
    const absenceType = AddClockAbsence.ClockAbsenceTypes.find(
      (item) => item.value === ClockAbsence.AbsenceType[type]
    );
    return absenceType?.title ?? 'unknown';
  }

  private getColor(type: keyof typeof ClockAbsence.AbsenceType): string {
    return this.colorMap[ClockAbsence.AbsenceType[type]] ?? '#3B82F6';
  }
}
