import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ClockHour, Company, Feature, FeatureUser } from '@tacliatech/types';
import { hours, minutes } from './time-input-objects';
import { RmSelect } from '../globals/rm-select/rm-select.types';
import { DealService, ProjectService } from '../../services';
import { Subscription } from 'rxjs';
import { CompanyModuleService } from '../../services/company';
import { filter, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'roma-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTimeComponent implements OnInit {
  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  set data(value: ClockHour.Histories) {
    this._data = value;
    this.patchParams();
  }

  get data() {
    return this._data;
  }

  @Input()
  showButton = true;

  @Input()
  disabled = false;

  @Output()
  changes = new EventEmitter<ClockHour.Histories>();

  histories: ClockHour.Histories = [];

  private sub$ = new Subscription();

  deals: RmSelect.Items = [];
  isLoadingDeals = true;
  selectedDeal: RmSelect.Items;

  projects: RmSelect.Items = [];
  isLoadingProjects = true;
  selectedProject: RmSelect.Items;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  initialStateForm = () =>
    this.fb.group({
      dates: this.fb.array([]),
    });

  initialStateFormWithOneElement = () =>
    this.fb.group({
      dates: this.fb.array([
        this.fb.group({
          startDate: ['', Validators.required],
          endDate: ['', Validators.required],
          duration: [{}],
          startHour: [{}],
          endHour: [{}],
          dealId: [''],
          projectId: [''],
        }),
      ]),
    });

  form = this.initialStateForm();

  editMode = {
    index: null,
    active: false,
  };

  hour = '01';
  min = '00';

  hours = hours;
  minutes = minutes;

  startTimePicker = null;
  endTimePicker = null;

  private _data: ClockHour.Histories = [];

  constructor(
    private fb: FormBuilder,
    private changeDetectionRef: ChangeDetectorRef,
    private projectService: ProjectService,
    private dealService: DealService,
    private companyModuleService: CompanyModuleService,
    private i18n: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadDeals();
    this.loadProjects();
  }

  get isMobile() {
    return window.innerWidth < 768;
  }

  hourChange(hour) {
    this.hour = hour;
  }

  minutesChange(min) {
    this.min = min;
  }

  resetTimePlaceholder() {
    this.hour = '01';
    this.min = '00';
  }

  openTimePicker(i, input) {
    const arrayControl = this.form.controls['dates'] as FormArray;
    this.resetTimePlaceholder();
    if (input === 'startDate') {
      this.startTimePicker = i;
      this.endTimePicker = null;
      if (arrayControl.at(i).value.startDate != '') {
        this.convertTimeValues(arrayControl.at(i).value.startDate);
      }
    }

    if (input === 'endDate') {
      this.endTimePicker = i;
      this.startTimePicker = null;
      if (arrayControl.at(i).value.endDate != '') {
        this.convertTimeValues(arrayControl.at(i).value.endDate);
      }
    }
  }

  setTime(i, input) {
    const selectedTime = this.hour + ':' + this.min;
    const langArr = <FormArray>this.form.controls['dates'];

    if (input === 'startDate') {
      langArr.controls[i].patchValue({ startDate: selectedTime });
      this.startTimePicker = null;
      this.onChangeEvent(i);
    } else {
      langArr.controls[i].patchValue({ endDate: selectedTime });
      this.endTimePicker = null;
      this.onChangeEvent(i);
    }
  }

  convertTimeValues(time) {
    const ts = time.toString();
    this.hour = ts.substr(0, 2);
    this.min = ts.substr(3, 2);
  }

  onChangeEvent(index: number) {
    const arrayControl = this.form.controls['dates'] as FormArray;
    if (
      arrayControl.at(index).value.startDate &&
      arrayControl.at(index).value.endDate
    ) {
      if (this.histories.length > index) {
        this.editMode.index = index;
        this.editMode.active = true;
        this.draw();
        this.handleEdit(index);
      } else {
        this.handleAdd(index);
      }

      this.replaceHistoriesEndHour();
      this.draw();
    }
  }

  get dates() {
    return this.form.controls['dates'] as FormArray;
  }

  addDate() {
    const datesForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      duration: [{}],
      startHour: [{}],
      endHour: [{}],
      dealId: [''],
      projectId: [''],
    });
    this.dates.push(datesForm);
    this.draw();
  }

  private handleAdd(i) {
    const history: ClockHour.History = this.getHistoryData(i);

    this.histories = [
      ...this.histories,
      {
        ...history,
      },
    ];
  }

  private handleEdit(i) {
    const editedHistory: ClockHour.History = this.getHistoryData(i);
    this.histories = this.histories.map((history, index) => {
      return this.editMode.index === index
        ? {
            ...history,
            ...editedHistory,
          }
        : history;
    });
    this.resetEditMode();
  }

  private getHistoryData(i: number) {
    const arrayControl = this.form.controls['dates'] as FormArray;

    const startHour: ClockHour.Hour = ClockHour.fromStringHourToObjectHour(
      arrayControl.at(i).value.startDate
    );
    const endHour: ClockHour.Hour = ClockHour.fromStringHourToObjectHour(
      arrayControl.at(i).value.endDate
    );

    const startDate = arrayControl.at(i).value.startDate as string;
    const endDate = arrayControl.at(i).value.endDate as string;

    const duration: ClockHour.Hour = ClockHour.getDifferenceHours(
      endDate,
      startDate
    );

    const history: ClockHour.History = {
      startDate,
      endDate,
      duration,
      startHour,
      endHour,
      dealId: arrayControl.at(i).value.dealId?.[0]?.id,
      projectId: arrayControl.at(i).value.projectId?.[0]?.id,
    };
    return history;
  }

  private resetEditMode() {
    this.editMode.index = null;
    this.editMode.active = false;
    this.draw();
  }

  delete(index: number) {
    this.histories = this.histories.filter((item, i) => index !== i);
    this.dates.clear();
    this.replaceHistoriesEndHour();
    this.resetEditMode();
  }

  private patchParams() {
    this.histories = [...this.data];

    if (this.histories.length) {
      this.form = this.initialStateForm();

      for (let index = 0; this.histories.length > index; index++) {
        const deal = this.deals.filter(
          (p) => p.id === this.histories[index].dealId
        );
        const project = this.projects.filter(
          (p) => p.id === this.histories[index].projectId
        );
        const datesForm = this.fb.group({
          startDate: [this.histories[index].startDate, Validators.required],
          endDate: [this.histories[index].endDate, Validators.required],
          duration: [this.histories[index].duration],
          startHour: [this.histories[index].startHour],
          endHour: [this.histories[index].endHour],
          dealId: [deal],
          projectId: [project],
        });
        this.dates.push(datesForm);
      }
    } else {
      this.form = this.initialStateFormWithOneElement();
    }
    this.draw();
  }

  private replaceHistoriesEndHour() {
    this.histories = this.histories.map((history) => {
      const endHour: ClockHour.Hour = ClockHour.fromStringHourToObjectHour(
        history.endDate
      );
      return {
        ...history,
        endHour,
      };
    });
    this.changes.emit(this.histories);
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  async loadDeals() {
    const isModuleActivePromise = new Promise<boolean>((resolve) => {
      this.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
        .subscribe((isActive) => resolve(!!isActive));
    });

    try {
      const isModuleActive = await isModuleActivePromise;

      if (isModuleActive) {
        this.isLoadingDeals = true;
        this.draw();

        const dealsPromise = new Promise<any[]>((resolve, reject) => {
          this.dealService
            .findAllDealsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.isLoadingDeals = false;
                this.draw();
              })
            )
            .subscribe(
              (res) => resolve(res),
              (error) => reject(error)
            );
        });

        const res = await dealsPromise;

        this.deals = res.map((res) => {
          return {
            title: res.name || this.i18n.instant('general.untitle'),
            value: res._id,
            id: res._id,
          };
        });

        this.isLoadingDeals = false;
      } else {
        this.isLoadingDeals = false;
      }
    } catch (error) {
      console.error('Error loading deals', error);
      this.isLoadingDeals = false;
    }
    this.patchParams();
    this.draw();
  }

  changeDeals(index: number, ev: RmSelect.Items): void {
    const arrayControl = this.form.controls['dates'] as FormArray;
    const dealId = ev;

    arrayControl.at(index).patchValue({
      dealId,
    });
    this.editMode.index = index;
    this.editMode.active = true;
    this.draw();
    this.handleEdit(index);
    this.changes.emit(this.histories);
  }

  changeProject(index: number, ev: RmSelect.Items): void {
    const arrayControl = this.form.controls['dates'] as FormArray;

    const projectId = ev;
    arrayControl.at(index).patchValue({
      projectId,
    });

    this.editMode.index = index;
    this.editMode.active = true;
    this.draw();
    this.handleEdit(index);
    this.changes.emit(this.histories);
  }

  async loadProjects() {
    try {
      this.isLoadingProjects = true;
      this.draw();

      const isModuleActivePromise = new Promise<boolean>((resolve) => {
        this.companyModuleService.idActiveModules$
          .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
          .subscribe(() => resolve(true));
      });

      const isModuleActive = await isModuleActivePromise;

      if (isModuleActive) {
        const projectsPromise = new Promise<any[]>((resolve, reject) => {
          this.projectService.findAllProjectsByCompany_SELECT().subscribe(
            (data) => resolve(data),
            (error) => reject(error)
          );
        });

        const data = await projectsPromise;

        this.projects = data.map((res) => {
          return {
            title: res.name,
            id: res._id,
            value: res._id,
          };
        });

        this.isLoadingProjects = false;
      } else {
        this.isLoadingProjects = false;
      }
    } catch (error) {
      console.error('Error loading projects', error);
      this.isLoadingProjects = false;
    }
    this.patchParams();
    this.draw();
  }

  getProject(index: number) {
    const arrayControl = this.form.controls['dates'] as FormArray;
    if (arrayControl && arrayControl.at(index)) {
      return arrayControl.at(index).value.projectId;
    }
  }

  getDeal(index: number) {
    const arrayControl = this.form.controls['dates'] as FormArray;
    if (arrayControl && arrayControl.at(index)) {
      return arrayControl.at(index).value.dealId;
    }
  }
}
