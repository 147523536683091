<div *ngIf="isNoLoginUser" id="no-login-banner" class="no-login-banner">
  <div class="banner-text">
    <img class="shield-icon" src="assets/icons/shield-check.svg" />
    <div>
      <span class="title"
        >{{ 'noLogin.title' | translate }}
        <span class="subtitle">{{ 'noLogin.subtitle' | translate }} </span>
      </span>
      <a class="web-link" (click)="openWeb()">
        {{ 'noLogin.web' | translate }} <span>taclia</span></a
      >
    </div>
  </div>
  <button class="web-button" (click)="openWeb()">
    {{ 'noLogin.web' | translate }} <span>taclia</span>
  </button>
  <button clickNoLoginRegister>
    {{ 'noLogin.register' | translate }}
  </button>
</div>
