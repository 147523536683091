import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoLoginRegisterModalComponent } from './no-login-register-modal.component';
import { AuthService } from '../../services';

@Directive({
  selector: '[clickNoLoginRegister]',
})
export class NoLoginRegisterDirective {
  @Output()
  registerComplete = new EventEmitter();

  constructor(
    private readonly dialog: MatDialog,
    private readonly authService: AuthService
  ) {}

  @HostListener('click', ['$event'])
  click(evt: MouseEvent): void {
    this.authService.isNoLoginUser$.subscribe((isNoLogin) => {
      if (isNoLogin) {
        evt.preventDefault();
        evt.stopPropagation();

        const dialogRef = this.dialog.open(NoLoginRegisterModalComponent, {
          id: 'no-login-register-modal-directive',
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.registerComplete.next();
          }
        });
      } else {
        this.registerComplete.emit(evt);
      }
    });
  }
}
