import {
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;

@Directive({
  selector: '[romaGooglePlaces]',
})
export class GooglePlacesDirective implements OnInit {
  @Output() placeSelected = new EventEmitter<any>();

  private autocomplete!: google.maps.places.Autocomplete;

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  ngOnInit(): void {
    if (!window.google) {
      console.error('Google Maps API failed to load');
      return;
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      this.el.nativeElement,
      {
        types: ['geocode', 'establishment'],
      }
    );

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete.getPlace();
        if (place.geometry) {
          this.placeSelected.emit(place);
        }
      });
    });
  }
}
