<roma-modal-template
  [size]="'sm'"
  [contentMinHeight]="true"
  [headerBottomMargin]="'2px'"
  (cancel)="exit()"
  [showDefaultButtons]="false"
  class="no-login-register-modal"
>
  <roma-mf-auth [isNoLogin]="true"></roma-mf-auth>
</roma-modal-template>
