import { Component, Inject, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SalesService, SandboxService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { FormatEmailValidator } from '@web-frontend/shared/validators/email';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roma-send-email-sale-modal-light',
  templateUrl: './send-email-sale-modal-light.component.html',
  styleUrls: ['./send-email-sale-modal-light.component.scss'],
})
export class SendEmailSaleModalLightComponent implements OnDestroy {
  private sub$ = new Subscription();
  form: FormGroup = this.fb.group({
    to: new FormControl(null, [Validators.required, FormatEmailValidator()]),
  });
  isLoading = false;
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private dialogRef: MatDialogRef<SendEmailSaleModalLightComponent>,
    public i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private salesService: SalesService,
    private toastService: ToastService,
    private sandBoxService: SandboxService,
    private budgetService: BudgetService
  ) {}

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  close(param = null): void {
    this.dialogRef.close(param);
  }

  send(): void {
    if (this.form.invalid) return;

    this.isLoading = true;
    const data = {
      emails: [this.form.value.to],
    };

    const serviceMap = {
      sales: this.salesService,
      budgets: this.budgetService,
    };

    const service = serviceMap[this.data.module];
    if (!service) {
      this.isLoading = false;
      return;
    }

    this.sub$.add(
      service.sendPaymentLinkEmail(this.data.idSale, data).subscribe({
        next: () => {
          this.toastService.show({
            text: 'general.mailSent',
            type: 'success',
            msDuration: 4000,
          });
          this.close('emailSent');
        },
        error: () => {
          this.toastService.show({
            text: 'general.apiError',
            type: 'error',
            msDuration: 4000,
          });
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      })
    );
  }
}
