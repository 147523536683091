import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
} from '@angular/core';
import { MicrofrontendComponent } from '../microfrontend.component';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { HttpClient } from '@angular/common/http';

type EventPayload = { id: string };

type SendEventPayload = { id: string };

export enum EventType {
  calendarIsReady = 'calendarIsReady',
  getEvents = 'getEvents',
  onEventClick = 'onEventClick',
  onDayClick = 'onDayClick',
  setYear = 'setYear',
}

type EventData = {
  type: EventType;
  payload?: EventPayload;
};

type SendEventData = {
  type: EventType;
  payload?: SendEventPayload;
};

export type CalendarEventType = {
  id: string | number;
  start: Date;
  end?: Date;
  title: string;
  type?: string;
  isPending?: boolean;
  isHalfDay?: boolean;
  isHoliday?: boolean;
  totalDays?: number;
};

@Component({
  selector: 'roma-mf-calendar',
  template: `<mf-calendar></mf-calendar>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfCalendarComponent extends MicrofrontendComponent<
  EventData,
  SendEventData
> {
  @Input() appName = 'mf-calendar';
  @Input() appUrl: string = localStorage.getItem('mf-calendar');

  private _calendarEvents: CalendarEventType;

  @Input() set calendarEvents(value: CalendarEventType) {
    this._calendarEvents = value;
    this.loadEvents();
  }
  @Input() set year(value: number) {
    this.setYear(value);
  }

  @Input() onEventClick;
  @Input() onDayClick;

  constructor(private _httpClient: HttpClient, private _el: ElementRef) {
    super(_httpClient, _el);
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
  }

  onInit(): void {}

  calendarIsReady(): void {
    this.loadEvents();
  }

  loadEvents(): void {
    const event = new CustomEvent(EventType.getEvents, {
      detail: { type: EventType.getEvents, value: this._calendarEvents },
    });
    window.dispatchEvent(event);
  }

  setYear(customYear: number): void {
    const event = new CustomEvent(EventType.setYear, {
      detail: { type: EventType.setYear, value: customYear },
    });
    window.dispatchEvent(event);
  }

  handleCustomEvent(event: CustomEvent): void {
    const eventMappedToCalendarEvent = {
      [EventType.calendarIsReady]: () => this.calendarIsReady(),
      [EventType.getEvents]: () => this.loadEvents(),
      [EventType.onEventClick]: () =>
        this.onEventClick && this.onEventClick(event.detail?.payload?.resource),
      [EventType.onDayClick]: () =>
        this.onDayClick && this.onDayClick(event.detail?.payload?.resource),
    };
    eventMappedToCalendarEvent[event.detail.type]();
  }

  onDestroy(): void {}
}
