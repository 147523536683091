import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@web-frontend/shared/services/users';
import { I18nService } from '@web-frontend/shared/i18n';
import { FormatEmailValidator } from '@web-frontend/shared/validators/email';
import { authRoutes } from '../../auth-routing.module';

@Component({
  selector: 'roma-recover',
  templateUrl: './recover.component.html',
  styleUrls: [
    '../../styles.scss',
    '../login.component.scss',
    './recover.component.scss',
  ],
})
export class RecoverComponent {
  form = new FormGroup({
    email: new FormControl('', [Validators.required, FormatEmailValidator()]),
  });
  error = false;
  success = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private i18nService: I18nService
  ) {}

  onSubmit(): void {
    const data = {
      email: this.form.get('email').value?.toLowerCase(),
    };

    this.userService.recoverPassword(data).subscribe(
      () => {
        this.success = true;
        this.router.navigate(['login']);
      },
      () => {
        this.error = true;
      }
    );
  }

  getImageUrl(): string {
    return `/assets/img/login/img_taclia-${this.i18nService.defaultLang()}.svg`;
  }

  get loginUrl(): string {
    return authRoutes.login;
  }
}
