import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalTemplateModule } from '../../components/modal-template';
import { NoLoginRegisterDirective } from './no-login-register.directive';
import { NoLoginRegisterModalComponent } from './no-login-register-modal.component';
import { MfAuthNoRoutingModule } from '../../../microfrontends/mf-auth/mf-auth-no-routing.module';

@NgModule({
  declarations: [NoLoginRegisterDirective, NoLoginRegisterModalComponent],
  imports: [CommonModule, ModalTemplateModule, MfAuthNoRoutingModule],
  exports: [NoLoginRegisterDirective],
})
export class NoLoginRegisterModule {}
