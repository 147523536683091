<roma-modal-template
  [title]="'general.sendByEmailLong' | translate"
  [contentMinHeight]="false"
  size="sm"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (cancel)="close()"
  [responsiveFull]="(resize$ | async)?.payload?.mobile ? false : true"
>
  <div class="form-body">
    <form [formGroup]="form">
      <label class="my-2 d-flex" for="to"
        ><span class="font-weight-bold">{{
          'general.emailTag' | translate
        }}</span>
        &nbsp;
      </label>
      <input
        id="to"
        formControlName="to"
        type="text"
        class="form-control"
        [ngClass]="{ 'invalid-border': form.invalid && form.touched }"
        [placeholder]="'general.exampleEmail' | translate"
      />
      <p class="invalid-text" *ngIf="form.invalid && form.touched">
        {{ 'register.error.valid_email' | translate }}
      </p>
    </form>
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter">
    <div class="groupEndDiv">
      <base-button color="link" buttonClass="tertiary" (click)="close()">
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button
        (click)="send()"
        [disabled]="form.invalid || isLoading"
        [isLoading]="isLoading"
      >
        {{ 'general.send' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
