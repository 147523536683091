import { Component, OnInit } from '@angular/core';
import { environment } from '@web-frontend/environments';

@Component({
  selector: 'roma-recaptcha-script',
  template: ``,
})
export class RecaptchaScriptComponent implements OnInit {
  ngOnInit() {
    this.loadScript(
      `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaV3SiteKey}`
    );
  }

  private loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
}
