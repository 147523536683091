import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { environment } from '@web-frontend/environments';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private app: firebase.app.App;

  constructor() {
    this.initializeFirebase();
  }

  private initializeFirebase() {
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(environment.firebaseConfig);
    } else {
      this.app = firebase.app();
    }
  }

  get firestore() {
    return this.app.firestore();
  }

  get auth() {
    return this.app.auth();
  }

  get storage() {
    return this.app.storage();
  }
}
