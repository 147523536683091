import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { RmSelect } from '../globals/rm-select/rm-select.types';

import {
  Deal,
  Company,
  DynamicPropertyRef,
  IAsset,
  ICategoryExpense,
  ICustomer,
  IDeal,
  IEquipment,
  IFinal,
  IInternalVendor,
  IProject,
  IUser,
  MapperFinal,
  StatusExpense,
  TypeExpenseTicket,
  ValidateAllFormFields,
  ICustomProperty,
  Feature,
  FeatureUser,
} from '@tacliatech/types';

import { AlertService } from '@web-frontend/shared/helpers/alert';
import { UserService } from '@web-frontend/shared/services/users';
import { EquipmentService } from '@web-frontend/shared/services/equipment';
import { SelectRecurrenceOutput } from '@web-frontend/shared/components/selector-recurrence';

import {
  ActivitySearchService,
  ActivityService,
  AssetService,
  AuthService,
  DealSearchService,
  DealService,
  FinalService,
  InternalVendorSearchService,
  InternalVendorService,
  ProjectService,
  StorageService,
} from '@web-frontend/shared/services';

import { DataParamsExpense } from './create-expense.types';
import { InfoService } from '../info/info.service';
import {
  ExpenseSearchService,
  ExpenseService,
} from '@web-frontend/shared/services/expense';
import { CategoryExpensesService } from '@web-frontend/shared/services/category-expenses';
import { TypeExpenseService } from '@web-frontend/shared/services/type-expense';
import { FilesUploadService } from '@web-frontend/shared/services/file-upload';
import { ModalInfoComponent } from '@web-frontend/shared/components/modal-info-shared-ui';
import { AddCategoryCommonService } from '../categories-base-manager/add-categories/add-categories-common.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';
import { translateExpensesName } from '@web-frontend/shared/helpers/translations/translate-util';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { EditCustomPropertiesComponent } from '../edit-custom-properties';
import { CreateProjectService } from '../create-project';
import { AddInternalVendorService } from '../../../core/admin/internal-vendor/add-internal-vendor';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from '@web-frontend/shared/utils/custom-date-adapter';

import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';

@Component({
  selector: 'roma-create-expense',
  templateUrl: './create-expense.component.html',
  styleUrls: ['./create-expense.component.scss'],
  providers: [{ provide: DateAdapter, useExisting: CustomDateAdapter }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateExpenseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(EditCustomPropertiesComponent)
  editCustomProperties: EditCustomPropertiesComponent;

  @ViewChild('customerVendor')
  customerVendorSelect: NgSelectComponent;

  @ViewChild('dealSelect')
  dealSelect: NgSelectComponent;

  @Input()
  charge = false;

  @Input()
  type2: '';

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  @Output()
  changeDeal = new EventEmitter<void>();

  file: string;

  dealLoadData = false;

  typeExpenses = this.typeExpenseService.findAll();
  statusExpenses = this.typeExpenseService.findAllStatusExpenses();

  private deleteImages = new Subject<boolean>();

  deleteImages$ = this.deleteImages.asObservable();

  form: FormGroup = this.fb.group({
    number: new FormControl(null),
    name: new FormControl(null),
    concept: new FormControl(null),
    dateTime: new FormControl(null),
    type: new FormControl(TypeExpenseTicket.EXPENSE_TICKET),
    dueDate: new FormControl(null),
    deal: new FormControl(null),
    imgs: new FormControl(null),
    category: new FormControl(null),
    users: new FormControl(null),
    createdBy: new FormControl(StorageService.UserId, [Validators.required]),
    customer: new FormControl(null),
    hasBeenNotified: new FormControl(false),
    total: new FormControl(null, [Validators.required]),
    subtotal: new FormControl(null, [Validators.required]),
    vendor: new FormControl(null),
    status: new FormControl(StatusExpense.PENDING),
    idProject: new FormControl(null),
    customProperties: [[]],
  });
  user: IUser;
  mode: string;

  featureRef = Feature;
  featureRefUser = FeatureUser;
  deals: RmSelect.Items = [];
  selectedDeal: RmSelect.Items = [];
  dealsCharged = false;
  dealsLoading = false;
  users: IUser[] = [];
  allUsers: IUser[] = [];
  allUsersCharged = false;
  allUsersLoading = false;
  assetList: IAsset[] = [];
  equipmentsList: IEquipment[] = [];
  finalsList: IFinal[] = [];
  projectsList: RmSelect.Items = [];
  selectedProject: RmSelect.Items = [];
  projectsCharged = false;
  projectsLoading = false;
  internalVendorList: RmSelect.Items = [];
  selectedVendor: RmSelect.Items = [];
  internalVendorCharged = false;
  internalVandorLoading = false;
  categoryExpensesList: ICategoryExpense[] | any[] = [];
  categoryExpensesCharged = false;
  categoryExpensesLoading = false;
  categoryExpensesFiltered: ICategoryExpense[] = [];
  customerVendorRef: ICustomer;
  markAsDone = false;
  expenseImg: Array<string> = [];
  typePropertyRef = DynamicPropertyRef;
  typeExpenseSelected: number = null;
  statusExpenseSelected: number = StatusExpense.PENDING;

  disableSubmit = false;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;
  public innerWidth: number;
  private sub$ = new Subscription();
  private refreshModalOnClose = false;
  isLoading = false;
  propertiesIsLoading = false;
  customProperties: ICustomProperty[] = [];
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;

  inputDateTime = null;
  inputDueDate = null;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private fb: FormBuilder,
    private modalService: MatDialog,
    private alertService: AlertService,
    private authService: AuthService,
    private dealSearchService: DealSearchService,
    private dealService: DealService,
    private filesUploadService: FilesUploadService,
    private expenseService: ExpenseService,
    private typeExpenseService: TypeExpenseService,
    private expenseSearchService: ExpenseSearchService,
    private changeDetectionRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateExpenseComponent>,
    private i18n: TranslateService,
    private userService: UserService,
    public assetService: AssetService,
    private finalService: FinalService,
    private equipmentService: EquipmentService,
    private projectService: ProjectService,
    private categoryExpensesService: CategoryExpensesService,
    private internalVendorService: InternalVendorService,
    private infoService: InfoService,
    private addCategoryCommonService: AddCategoryCommonService,
    private amplitudeService: AmplitudeService,
    private companyModuleService: CompanyModuleService,
    private createProjectService: CreateProjectService,
    private addInternalVendorService: AddInternalVendorService,

    private brazeService: BrazeService,
    @Inject(MAT_DIALOG_DATA) public data: DataParamsExpense,
    private toastService: ToastService,
    private dialog: MatDialog
  ) {}

  initialForm: any = {};
  get haveChanges() {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  clear(e) {}

  get getMyImages() {
    return this.form.get('imgs').value;
  }
  get controls() {
    return this.form.controls;
  }

  get expeseditRef() {
    return this?.data?.expense;
  }

  get featureUserScreen() {
    if (this.type == 'ADD') {
      return FeatureUser.Expense.create;
    } else {
      return FeatureUser.Expense.update;
    }
  }
  ngOnInit(): void {
    this.resolveEdit();
    this.initialCharge();
    this.innerWidth = window.innerWidth;
    this.initialForm = { ...this.form.value };
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  initialCharge() {
    if (this.charge) {
      this.resolveDeals();
      // this.dealsCharged = true;
      this.findProjects();
      this.projectsCharged = true;
      this.getPersons();
      this.allUsersCharged = true;
      this.findInternalVendors();
      this.internalVendorCharged = true;
      this.findExpensesCategory();
      this.categoryExpensesCharged = true;
    }
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'deals':
        if (!this.dealsCharged) {
          // this.dealsCharged = true;
          this.resolveDeals();
        }
        break;
      case 'projects':
        if (!this.projectsCharged) {
          this.projectsCharged = true;
          this.findProjects();
        }
        break;
      case 'users':
        if (!this.allUsersCharged) {
          this.allUsersCharged = true;
          this.getPersons();
        }
        break;
      case 'vendor':
        if (!this.internalVendorCharged) {
          this.internalVendorCharged = true;
          this.findInternalVendors();
        }
        break;
      case 'categoryExpenses':
        if (!this.categoryExpensesCharged) {
          this.categoryExpensesCharged = true;
          this.findExpensesCategory();
        }
        break;
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.data?.deal) {
        this.dealLoadData = true;
        //this.dealSelect?.setDisabledState(true);
      }
    });

    this.authService.user$.subscribe((res) => {
      this.user = res;
      this.mode = this.user?.mode;
    });
  }

  updateImages(images: string[]): void {
    this.form.patchValue({
      imgs: images,
    });

    if (this.data?.expense) {
      this.data.expense.imgs = images;
    }

    this.draw();
  }

  toOpen(item) {
    const split = item.img.split('.');
    const ext = split[split.length - 1];

    if (ext !== 'pdf') {
      const modalRef = this.modalService.open(ModalInfoComponent);

      (modalRef.componentInstance as ModalInfoComponent).images = [item];
      (modalRef.componentInstance as ModalInfoComponent).title = 'Vista Previa';
      (modalRef.componentInstance as ModalInfoComponent).type = 'image';
    } else {
      const filterPipe = new ImageFirebasePipe();
      const filteredImage = filterPipe.transform(item.img, item.type);
      window.open(filteredImage, '_blank');
    }
  }

  translateName(item: string) {
    if (item) {
      const result = translateExpensesName(item);
      return result;
    }
    return '';
  }

  deleteImageExpense(evt: { type: string; img: string }) {
    const evidences = this.data?.expense.imgs.filter(
      (evidence) => evidence !== evt.img
    );

    const preventRepeat = Array.from(new Set([...evidences]));

    this.sub$.add(
      this.expenseService
        .updateOne(this.data?.expense?._id, {
          imgs: preventRepeat,
        })
        .subscribe(() => {
          this.changeDeal.emit();
        })
    );
  }

  addImageExpense(evt: File, index: number) {
    this.sub$.add(
      this.filesUploadService
        .uploadFiles('expense', [evt])
        .pipe(
          switchMap((resp: any) => {
            this.data?.expense?.imgs.splice(index, 0, resp[0].filename);
            return this.expenseService.updateOne(this.data?.expense?._id, {
              imgs: this.data?.expense?.imgs,
            });
          })
        )
        .subscribe(() => {
          this.draw();
          this.changeDeal.emit();
        })
    );
  }

  setTypeCategoryExpense(type: number, reset?: boolean) {
    const typeExpenseID = this.typeExpenses.find((s) => s.id === type)?.id;
    this.typeExpenseSelected = typeExpenseID;
    this.filterListCategory(typeExpenseID, reset);
  }

  /*  setCategory(id: number) {
    let value = this.typeExpenses.find((s) => s.id === type)?.id;
    this.typeExpenseSelected = value;
  } */

  setStatusExpense(type: number) {
    const value = this.statusExpenses.find((s) => s.id === type)?.id;
    this.statusExpenseSelected = value;
  }

  changeTypeExpense(typeExpenseID: number) {
    //this.filterListCategory(typeExpenseID,true);
  }

  changeCategory(event: string) {
    this.form.patchValue({
      category: event,
    });
  }

  changeDealEvent(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        deal: first,
      });
    } else {
      this.selectedDeal = [];
      this.form.patchValue({
        deal: null,
      });
    }
    this.draw();
  }

  changeMarkAsDone(evt: MatCheckboxChange) {
    this.form.patchValue({
      hasBeenNotified: evt.checked,
    });
  }

  eventValueChanged(evt) {
    this.form.patchValue({ customProperties: [...evt] });
  }

  editSubmit() {
    let isInvalid = false;
    this.editCustomProperties.EditableFieldComponent.toArray().forEach(
      (child, index) => {
        if (
          child.checkValidOnSubmit(
            this.editCustomProperties.isAvailable[index].isAvailable
          )
        ) {
          isInvalid = true;
        }
      }
    );

    if (!isInvalid) {
      this.submit();
    }
  }

  submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();

    if (isValid) {
      this.form.patchValue({
        dateTime: this.form.value?.dateTime
          ? this.form.value?.dateTime?.toString().replace('000', '00Z')
          : null,
        dueDate: this.form.value?.dueDate
          ? this.form.value?.dueDate?.toString().replace('000', '00Z')
          : null,
      });

      const value = this.form.value;
      const obs$ =
        this.type === 'ADD'
          ? this.expenseService.create(value)
          : this.expenseService.updateOne(
              this.data?.expense?._id,
              this.form.value
            );
      let eventData;
      if (this.type === 'ADD') {
        eventData = {
          event: 'expense_create',
        };
      } else {
        eventData = {
          event: 'expense_edit',
        };
      }
      this.isLoading = true;
      this.sub$.add(
        obs$.subscribe(
          (res) => {
            this.refreshModalOnClose = true;
            this.isLoading = false;
            this.parentFormSubmit = false;
            this.close(false);

            const message =
              this.type === 'ADD'
                ? 'expense.create.success'
                : 'expense.edit.success';

            this.toastService.show({
              text: this.i18n.instant(message),
              type: 'success',
              msDuration: 4000,
            });
          },
          () => {
            this.parentFormSubmit = false;
            this.isLoading = false;
            this.toastService.show({
              text: this.i18n.instant('expense.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
      this.amplitudeService.sendEvent(eventData);
      this.brazeService.sendEvent(eventData);
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  close(del: any) {
    if (del === undefined) {
      this.deleteImages.next(true);
    }

    const refresh = this.refreshModalOnClose ? true : null;
    this.dialogRef.close(refresh);
  }

  public formatUTFDatetime($evt: SelectRecurrenceOutput, type: string) {
    const { value } = this.form;
    if (type === 'init') {
      const dateTime = $evt.date ? $evt.date + 'Z' : null;
      this.form.patchValue({
        dateTime: dateTime,
        dueDate: value?.dueDate?.toString().replace('000', '00Z'),
      });
    } else {
      const dueDate = $evt.date ? $evt.date + 'Z' : null;
      this.form.patchValue({
        dateTime: value?.dateTime?.toString().replace('000', '00Z'),
        dueDate: dueDate,
      });
    }
  }

  private resolveDeals() {
    this.dealsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
        .subscribe(() => {
          this.sub$.add(
            this.dealService
              // .deals$
              .findAllDealsByCompany_SELECT()
              .subscribe((res) => {
                this.deals = res.map((res) => {
                  return {
                    title: res.name,
                    value: res._id,
                    id: res._id,
                  };
                });
                this.dealsLoading = false;
                this.dealsCharged = true;
                this.draw();
              })
          );
        })
    );
  }

  defaultDeal() {
    if (this.data?.deal) {
      const defaultDeal = this.data.deal;
      this.selectedDeal = [
        {
          title: defaultDeal.name,
          id: defaultDeal._id,
          value: defaultDeal._id,
        },
      ];
      this.changeDealEvent(this.selectedDeal);
      this.draw();
    }
  }

  private resolveEdit() {
    if (this.data?.expense) {
      this.type = 'EDIT';

      const { expense } = this.data;

      this.inputDateTime = new Date(
        expense?.dateTime?.toString().replace('Z', '')
      );
      this.inputDueDate = new Date(
        expense?.dueDate?.toString().replace('Z', '')
      );
      console.log({ expense });
      this.form.patchValue({
        ...expense,
        name: expense?.name,
        number: expense?.number,
        concept: expense?.concept,
        type: expense?.type,
        dateTime: expense?.dateTime?.toString().replace('Z', ''),
        dueDate: expense?.dueDate?.toString().replace('Z', ''),
        deal: expense?.deal ? expense?.deal : null,
        category: expense?.category?._id
          ? expense?.category?._id
          : expense?.categoryObj?._id,
        users: expense?.users,
        imgs: expense?.imgs,
        customer: expense?.customer?._id,
        vendor: expense?.vendor?._id
          ? expense?.vendor?._id
          : expense?.vendorObj?._id,
        createdBy: StorageService.UserId,
        idProject: expense?.idProject?._id
          ? expense?.idProject?._id
          : expense?.projectObj?._id,
        subtotal: expense?.subtotal,
        total: expense?.total,
        status: expense?.status,
      });

      if (expense) {
        if (this.deals.length === 0 && expense?.deal)
          this.selectedDeal = [
            {
              title: expense?.dealObj.name,
              id: expense?.dealObj._id,
              value: expense?.dealObj._id,
            },
          ];
        if (this.projectsList.length === 0 && expense?.idProject)
          this.selectedProject = [
            {
              title: expense?.projectObj.name,
              id: expense?.projectObj._id,
              value: expense?.projectObj._id,
            },
          ];
        if (this.allUsers.length == 0 && expense?.ownersObj)
          this.allUsers = expense.ownersObj;
        if (this.internalVendorList.length == 0 && expense?.vendorObj)
          this.selectedVendor = [
            {
              title: expense?.vendorObj.name,
              id: expense?.vendorObj._id,
              value: expense?.vendorObj._id,
            },
          ];
        if (this.categoryExpensesList.length === 0 && expense?.categoryObj)
          this.categoryExpensesList.push(expense.categoryObj);
      }

      //this.markAsDone = expense?.hasBeenNotified;

      this.setTypeCategoryExpense(expense?.type, false);
      this.setStatusExpense(expense?.status);

      this.draw();
    } else {
      if (this.data?.deal) {
        this.defaultDeal();
        // this.resolveDeals();
      }
    }
  }

  private getPersons() {
    const id = StorageService.CompanyId;
    this.allUsersLoading = true;
    this.sub$.add(
      this.userService
        // .findAllByCompany(id)
        .findAllUsersByCompany_SELECT()
        .subscribe((resp: IUser[]) => {
          this.allUsers = resp;
          this.allUsersLoading = false;
          this.draw();
        })
    );
  }

  findExpensesCategory(type?: number) {
    this.categoryExpensesLoading = true;
    this.sub$.add(
      this.categoryExpensesService
        // .findAll()
        .findAllCategoryExpensesByCompany_SELECT()
        .subscribe((res: { _id: string; name: string }[]) => {
          this.categoryExpensesList = res;
          this.categoryExpensesLoading = false;
          this.draw();
        })
    );
  }

  filterListCategory(typeExpense: number, reset?: boolean) {
    const filtered = this.categoryExpensesList.filter(function (cat) {
      return cat.typeExpense === typeExpense;
    });
    this.categoryExpensesFiltered = [...filtered];
    if (reset) {
      this.resetCategory();
    }

    this.draw();
  }

  resetCategory() {
    this.form.patchValue({
      category: null,
    });
  }

  changeCustomProperty() {
    this.refreshModalOnClose = true;
    this.reloadCustomProperties();
  }

  private reloadCustomProperties() {
    this.sub$.add(
      this.expenseSearchService
        .search({
          'ids[]': this.data.expense?._id,
        })
        .subscribe((res) => {
          if (res.docs) {
            const [activity] = res.docs;
            const { customProperties } = activity;

            if (customProperties) {
              this.propertiesRequired(false);
            }
            this.form.patchValue({
              customProperties,
            });
            this.draw();
          }
        })
    );
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  private findProjects() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          this.projectsLoading = true;
          this.draw();

          this.projectService
            .findAllProjectsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.projectsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.projectsList = res.map((res) => {
                return {
                  title: res.name,
                  value: res._id,
                  id: res._id,
                };
              });
              this.draw();
            });
        })
    );
  }

  changeProject(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        idProject: first,
      });
    } else {
      this.selectedProject = [];
      this.form.patchValue({
        idProject: null,
      });
    }
    this.draw();
  }

  addProject() {
    this.amplitudeService.sendEvent({
      event: 'project_start',
    });
    this.sub$.add(
      this.createProjectService.open().subscribe(() => {
        this.findProjects();
      })
    );
  }

  private findInternalVendors() {
    this.internalVandorLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.InternalVendors)))
        .subscribe(() => {
          this.draw();

          this.internalVendorService
            .findAllInternalVendorsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.draw();
              })
            )
            .subscribe((res) => {
              this.internalVendorList = res.map((res) => {
                return {
                  title: res.name,
                  value: res._id,
                  id: res._id,
                };
              });
              this.internalVandorLoading = false;
              this.draw();
            });
        })
    );
  }

  changeVendor(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        vendor: first,
      });
    } else {
      this.selectedVendor = [];
      this.form.patchValue({
        vendor: null,
      });
    }
    this.draw();
  }

  addVendor() {
    this.amplitudeService.sendEvent({
      event: 'vendor_start',
    });
    this.addInternalVendorService.open().subscribe(() => {
      this.findInternalVendors();
    });
  }

  info(type2: string) {
    this.infoService.open(type2);
  }

  openAddCategory() {
    this.addCategoryCommonService
      .open({ data: { type: 'ADD', typeCategory: 'EXPENSES' } })
      .pipe(filter((res) => !!res))
      .subscribe(() => {
        this.findExpensesCategory();
      });
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setcustomProperties();
  }

  setcustomProperties() {
    let i = 0;
    for (i = 0; i < this.customProperties.length; i++) {
      if (this.customProperties[i].isRequired) {
        this.form.addControl(
          this.customProperties[i]._id,
          new FormControl('', Validators.required)
        );
      }
    }
    this.initChildForm = true;
    this.parentFormSubmit = false;
  }

  fetchPropertyValue(propertyId) {
    this.form.controls[propertyId].setValue(propertyId);
  }

  propertiesRequired(value) {
    this.invalidEditCustomFields = value;
  }

  propertiesLoading(evt) {
    this.propertiesIsLoading = evt;
  }
}
