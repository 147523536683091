<div class="line"></div>
<div class="absence-summary-counter-container">
  <h2>{{ title }}</h2>
  <ul class="absence-summary-counter-list">
    <li
      *ngFor="let absence of absenceCounts"
      class="absence-summary-counter-item"
    >
      <div class="absence-summary-counter-type">
        <div
          class="absence-color"
          [style.backgroundColor]="absence.color"
        ></div>
        <div class="absence-details">
          <span class="absence-type">{{
            absence.displayName | translate
          }}</span>
          <ng-container *ngIf="!loadingTotal; else loadingSpinner">
            <span
              class="absence-summary-counter-count"
              aria-label="{{ absence.count }} {{ absence.type }}"
              >{{ absence.count }}</span
            >
          </ng-container>
          <ng-template #loadingSpinner>
            <mat-progress-spinner
              class="loading-spinner icon-option"
              mode="indeterminate"
              [diameter]="20"
            ></mat-progress-spinner>
          </ng-template>
        </div>
      </div>
    </li>
  </ul>
</div>
