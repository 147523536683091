import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ConvertToIdName, IDeal, IStatus, TypeRol } from '@tacliatech/types';

import { User } from '@web-frontend/shared/class';
import { SelectSearch } from '@web-frontend/shared/components/select-search';
import {
  DealService,
  GeolocationService,
  UserService,
} from '@web-frontend/shared/services';

import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { TrackingService } from '@web-frontend/shared/services/tracking';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SelectSearchService } from '@web-frontend/shared/services/select-search';
import { filter, map } from 'rxjs/operators';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalMapRequestLocationComponent } from '../modal-map-request-location';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

export enum IconColor {
  BLUE = 'blue',
  YELLOW = 'yellow',
  GREEN = 'green',
}

export const FIVE_MINUTES = 300000;
export const INITAL_TIME = 500;
export const TEN_SECONDS = 10000;

@Component({
  selector: 'roma-tracking-object',
  templateUrl: './tracking-objects.component.html',
  styleUrls: ['./tracking-objects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingObjectComponent implements OnInit {
  private sub$ = new Subscription();

  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  //GOOGLE MAPS Properties
  markers: Array<any> = [];
  markersDeals: Array<any> = [];
  center: any;
  zoom = 8;
  display?: google.maps.LatLngLiteral;
  infoContent = '';

  polylineOptions = {
    path: [],
    strokeColor: '#32a1d0',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  //Bussiness Logic Properties
  selectType = SelectSearch.DatabaseAdd;
  user?: User;
  teamsItems: Array<{ id: string; name: string }> = [];
  dealsItems: Array<any> = [];
  users: any = [];
  latitude = 0;
  longitude = 0;
  position = false;

  usersCache = new BehaviorSubject<any>([]);
  users$ = this.usersCache.asObservable();

  set updateUsersCache(data) {
    if (data) {
      this.usersCache.next(data);
    } else {
    }
  }

  isLoadingUsers = true;
  isLoadingDeals = true;
  enableReload = true;
  locationFail = false;

  isAdmin$ = this.authService.isAdmin$;

  @Input()
  markersOfUsers: Array<any> = [];

  @Input()
  markersOfDeals: Array<any> = [];

  @Input()
  dateStorage = '';

  @Input()
  usersSelected: Array<any> = [];

  @Input()
  linesMap: any;

  @Input()
  parameterized = false;

  enablePushNotificacion = false;

  get enableNotification() {
    return this.usersSelected.length > 0;
  }

  polylinesArray: any;

  typeRoles = TypeRol;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dealService: DealService,
    private trackingService: TrackingService,
    private i18n: TranslateService,
    private router: Router,
    private ngZone: NgZone,
    private modalService: MatDialog,
    private selectSearchService: SelectSearchService,
    private amplitudeService: AmplitudeService,
    private geolocationService: GeolocationService,
    private createDealService: CreateDealService,
    private brazeService: BrazeService,
    private analyticsService: AnalyticsService
  ) {
    this.centerMap();
  }

  ngOnInit(): void {
    this.draw();
    this.getCurrentPosition();
    this.getUser();
    this.automaticRefresh();
  }

  automaticRefresh() {
    const source = timer(INITAL_TIME, FIVE_MINUTES);
    const subscribe = source.subscribe((val) => {
      this.requestPositions();
    });
  }
  requestPositions() {
    this.trackingService.refreshAll();
    if (!this.parameterized) {
      this.markers = [...this.markers];
      this.markersDeals = [...this.markersDeals];
    } else {
      this.markersOfUsers = [...this.markersOfUsers];
      this.markersOfDeals = [...this.markersOfDeals];
    }

    this.draw();
  }

  reloadMap() {
    this.amplitudeService.sendEvent({
      event: 'location_refresh',
    });
    this.enableReload = false;
    const temp = timer(TEN_SECONDS);
    const subscribe = temp.subscribe((val) => {
      this.requestPositions();
      this.enableReload = true;
      this.draw();
    });
  }

  getMarkersUsers() {
    if (!this.parameterized) {
      return this.markers;
    } else {
      return this.markersOfUsers;
    }
  }

  getMarkersDeals() {
    if (!this.parameterized) {
      return this.markersDeals;
    } else {
      return this.markersOfDeals;
    }
  }

  centerMap() {
    this.center = {
      lat: 39.55509898294029,
      lng: -3.6115952141260927,
    };
  }

  getUser() {
    this.authService.user$
      .pipe(filter((res) => !!res))
      // @ts-ignore
      .subscribe((res: User) => {
        this.user = res;
        this.resolveTeams();
        this.resolveDeals();
        this.draw();
      });
  }

  async getCurrentPosition() {
    this.alertService.success(
      this.i18n.instant('geolocation.waitForGeolocation')
    );

    this.position = false;
    await this.geolocationService
      .getGeolocation()
      .then((position) => {
        if (position.lat !== 0 && position.lng !== 0) {
          this.center = {
            lat: position.lat,
            lng: position.lng,
          };
          this.latitude = position.lat;
          this.longitude = position.lng;
          this.addMarker(
            ' ',
            '',
            IconColor.BLUE,
            this.latitude,
            this.longitude
          );
          this.draw();
          this.position = true;
        }
      })
      .catch(() => {
        this.locationFail = true;
        this.alertService.error(this.i18n.instant('geolocation.noPermissions'));
      });
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  activateButtonPushNotification() {
    if (this.user?.role?.includes(TypeRol.ADMIN_ROLE)) {
    }
  }

  // @ts-ignore
  addUserMarker(user: { id: string; name: string } = null, color = null) {
    let url = 'https://maps.google.com/mapfiles/ms/icons/';
    url += color + '-dot.png';
    this.markers.push({
      position: {
        lat: this.center.lat + ((Math.random() - 0.5) * 3) / 10,
        lng: this.center.lng + ((Math.random() - 0.5) * 3) / 10,
      },
      label: {
        color: 'black',
        text: user.name
          ? user.name
          : 'Marker label ' + (this.markers.length + 1),
      },
      title: user.name
        ? user.name
        : 'Marker title ' + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
    });
  }
  getTrackingUser(user: { id: string; name: string }) {
    return null;
  }

  addMarker(
    title: string,
    label: string,
    color: string,
    latitude: number,
    longitude: number
  ) {
    let url = 'https://maps.google.com/mapfiles/ms/icons/';
    url += color + '-dot.png';
    this.markers.push({
      position: {
        lat: latitude,
        lng: longitude,
      },
      label: {
        color: color,
        text: label,
      },
      title: title,
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 90),
        },
      },
    });

    this.draw();
  }

  private resolveTeams() {
    this.isLoadingUsers = true;

    this.sub$.add(
      this.userService
        .findAllByCompany(this.user?.company?._id)
        .pipe(filter((res) => !!res))
        .subscribe((res) => {
          this.isLoadingUsers = false;
          //this.users = res;
          this.updateUsersCache = res;
          this.teamsItems = ConvertToIdName(res);
          //console.log('teamsItems', this.teamsItems);
          this.draw();
        })
    );
  }

  private resolveDeals() {
    this.isLoadingDeals = true;

    this.sub$.add(
      this.dealService.deals$
        .pipe(
          filter((res) => Boolean(res)),
          map((res) => res[1])
        )
        .subscribe((resp) => {
          this.isLoadingDeals = false;

          //JUST Assets with coordenates
          const assetsFiltered = resp.filter(
            (deal) =>
              deal.asset?.latitude !== undefined &&
              deal.asset?.latitude != null &&
              deal.asset?.longitude !== undefined &&
              deal.asset?.longitude != null
          );

          //console.log('assetsFiltered', assetsFiltered);
          // this.dealsItems = assetsFiltered;
          //console.log('dealsItems', this.dealsItems);
          this.draw();
        })
    );
  }

  itemSelected($event) {}

  changeUsers(users) {
    if (users?.length > 0) {
      const usrs = [];
      this.teamsItems.forEach((team) => {
        users.forEach((userSelected) => {
          if (userSelected == team.id) {
            // @ts-ignore
            usrs.push(team);
          }
        });
      });
      //console.log('changeUsers usrs=', usrs);

      this.addUserMarkers(usrs, IconColor.GREEN);
      this.draw();
    }
  }
  addUserMarkers(usrs: { id: string; name: string }[], color: IconColor) {
    const usersIDs = usrs.map((user) => {
      return user.id;
    });
    //console.log('usersIDs =', usersIDs);
    const url =
      'https://maps.google.com/mapfiles/ms/icons/' + color + '-dot.png';
    this.markers = [];

    this.trackingService
      .getTrackingByUsers(usersIDs, this.dateStorage)
      .subscribe((data) => {
        data.forEach((trackUser, index) => {
          //MATCH USER
          const user = usrs.filter((user) => user.id === trackUser.user_id)[0];
          if (user) {
            const finalUSer = { ...trackUser, ...user };
            if (finalUSer) {
              //BUILD AND PUSH MARKERS
              this.pushMarker(finalUSer, url);
              this.draw();
            }
          }
        });
        this.draw();
      });
  }

  pushMarker(user: any, url: string) {
    this.markers.push({
      position: {
        lat: +user.lat,
        lng: +user.long,
      },
      label: {
        color: 'black',
        text: user.name ? user.name : user.id,
      },
      title: user.name ? user.name : user.id,
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
      id: user.id,
    });
  }

  changeDeal(deals) {
    if (deals?.length > 0) {
      this.addDealMarkers(deals, IconColor.BLUE);
      this.draw();
    }
  }
  addDealMarkers(dls: any[], color: IconColor) {
    const url =
      'https://maps.google.com/mapfiles/ms/icons/' + color + '-dot.png';
    this.markersDeals = [];
    dls.forEach((dealSelected) => {
      this.pushMarkerDeals(dealSelected, url);
      this.draw();
    });
  }

  pushMarkerDeals(deal: any, url: string) {
    this.markersDeals.push({
      position: {
        lat: +deal.asset.latitude,
        lng: +deal.asset.longitude,
      },
      label: {
        color: 'black',
        text: deal.name ? deal.name : deal._id,
      },
      title: deal.name ? deal.name : deal._id,
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
      id: deal._id,
    });
  }

  openInfoUser(marker: any, point) {
    const user = this.users.find((user) => user._id === point.id);
    const info = { ...user, time: point.time };
    if (user) {
      this.setinfoContentUser(info);
    } else {
      this.infoContent = 'not user found!';
    }
    this.draw();
    this.info.open(marker);
  }

  openInfoDeal(marker: MapMarker, dealID: string) {
    const deal = this.dealsItems.find((deal) => deal._id === dealID);

    if (deal) {
      this.setinfoContentDeal(deal);
    } else {
      this.infoContent = 'not service found!';
    }

    this.info.open(marker);
  }

  setinfoContentDeal(deal: IDeal) {
    this.infoContent =
      '<strong>' +
      deal?.name +
      '</strong></br>' +
      deal?.asset?.address +
      '</br>' +
      this.i18n.instant(this.getStatus(deal?.status)) +
      '</br><strong>' +
      this.i18n.instant('deals.dealDetail.createdBy') +
      deal?.createdBy?.name +
      '</strong></br>' +
      '<strong>' +
      this.getFinal(deal) +
      `</strong><br><a href='${this.createLink(deal)}' id='marker_${
        deal._id
      }'>Ir al servicio</a>`;
  }

  createLink(deal: IDeal) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/admin/deals/` + deal._id])
    );
    return url;
  }

  goToService(deal: IDeal) {
    this.createDealService
      .open({
        data: {
          mode: 'EDIT',
          idDeal: deal._id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
    //this.router.navigateByUrl(`/admin/deals/${deal._id}`);
  }

  click1() {}

  toService(data: IDeal) {
    this.createDealService
      .open({
        data: {
          mode: 'EDIT',
          idDeal: data?._id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();

    /*this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(
      () => {
        this.router.navigate([`/admin/deals/${data?._id}`])
      }
    );*/
  }

  setinfoContentUser(user: any) {
    this.infoContent =
      '<strong>' +
      user?.name +
      '</strong></br>' +
      user?.email +
      '</br>' +
      this.i18n.instant('customer.phone') +
      ': ' +
      this.resolvePhone(user) +
      '</br><strong>' +
      this.i18n.instant('settings.company') +
      ': </strong>' +
      this.resolveCompany(user) +
      '</br><p>' +
      this.resolveTime(user) +
      '</p>';
    this.draw();
  }

  resolvePhone(user) {
    const phone = user?.phone
      ? user.phone
      : this.i18n.instant('person.notAvailable');
    return phone;
  }

  resolveCompany(user) {
    const c = user?.company
      ? user.company.name
      : this.i18n.instant('person.notAvailable');
    return c;
  }

  resolveTime(user) {
    const c = user?.time
      ? user?.time
      : this.i18n.instant('general.propertyNotAvailable');
    return c;
  }

  testItemSelect($event) {}

  removeDeal(deal) {
    this.markersDeals = this.markersDeals.filter(
      (marker) => marker.id !== deal.value._id
    );
  }

  removeUser(user) {
    this.markers = this.markers.filter((marker) => marker.id !== user.value);
  }

  clearUserMarkers($event) {
    this.markers = [];
  }

  clearDealMarkers($event) {
    this.markersDeals = [];
  }

  async sendNotification() {
    const res = await this.alertService
      // @ts-ignore
      .sure(this.i18n.instant('general.askPushLocation'), null)
      .toPromise();

    if (res?.value) {
      this.userService
        .submitRequestToEmitPosition(this.usersSelected)
        .subscribe((data) => {
          this.alertService.success(
            this.i18n.instant('general.successPushNotification')
          );
        });
    }

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.location_request,
    });
    this.brazeService.sendEvent({
      event: 'tracking_create',
    });
  }

  setMarkersDeals(markers) {
    this.markersDeals = [...markers];
  }

  setMarkersUsers(markers) {
    this.markers = [...markers];
  }

  setUsersSelected(users) {
    this.amplitudeService.sendEvent({
      event: 'location_filterUser',
    });
    this.usersSelected = [...users];
  }

  setUsersList(users) {
    this.users = [...users];
  }

  setDealsList(deals) {
    this.dealsItems = [...deals];
  }

  setPolyLines(lines) {
    this.linesMap = lines;
  }

  setDateTracking(mydate) {
    this.amplitudeService.sendEvent({
      event: 'location_filterDate',
    });
    this.dateStorage = mydate;
  }

  getFinal(deal: IDeal) {
    const name = deal?.final?.name === undefined ? '-' : deal?.final?.name;
    return name;
  }

  getStatus(status: IStatus) {
    const name =
      status?.translate === undefined ? status?.name : status?.translate;
    return name;
  }

  askCurrentLocation() {
    this.ngZone.run(() => {
      const config = new MatDialogConfig();
      config.minWidth = '50vw';
      config.minHeight = '75vh';
      const modalRef = this.modalService.open(
        ModalMapRequestLocationComponent,
        config
      );

      (modalRef.componentInstance as ModalMapRequestLocationComponent).title = this.i18n.instant(
        'geolocation.modalTitle'
      );
    });

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.location_send,
    });
  }
}
