import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../../../services';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class TrainingRequestService {
  constructor(private http: HttpClient) {}

  public async requestTraining(): Promise<{ success: boolean }> {
    const companyId = StorageService.CompanyId;
    if (!companyId) {
      return { success: false };
    }
    return await this.http
      .post<{ success: boolean }>(
        `:API_URL/company/${companyId}/training-request`,
        {}
      )
      .pipe(
        map((res) => ({ success: true })),
        catchError(() => of({ success: false }))
      )
      .toPromise();
  }
}
