import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConvertToIdName, IDeal, IStatus, TypeRol } from '@tacliatech/types';

import { User } from '@web-frontend/shared/class';
import { SelectSearch } from '@web-frontend/shared/components/select-search';
import { DealService, UserService } from '@web-frontend/shared/services';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { TrackingService } from '@web-frontend/shared/services/tracking';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SelectSearchService } from '@web-frontend/shared/services/select-search';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';

export enum IconColor {
  BLUE = 'blue',
  YELLOW = 'yellow',
  GREEN = 'green',
  ORGANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  RED = 'red',
}

export const PRECISION_POSITION = 5;

export const COLOR_ICON_LIST = [
  IconColor.BLUE,
  IconColor.YELLOW,
  IconColor.GREEN,
  IconColor.ORGANGE,
  IconColor.PINK,
  IconColor.PURPLE,
  IconColor.RED,
];

@Component({
  selector: 'roma-filter-tracking',
  templateUrl: './filter-tracking.component.html',
  styleUrls: ['./filter-tracking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTrackingComponent implements OnInit, OnDestroy {
  private sub$ = new Subscription();

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  //GOOGLE MAPS Properties
  markers: Array<any> = [];
  markersDeals: Array<any> = [];
  center: any;
  zoom = 8;
  display?: google.maps.LatLngLiteral;
  infoContent = '';
  idUsers = [];

  polylineOptions = {
    path: [],
    strokeColor: IconColor.GREEN,
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  //Bussiness Logic Properties
  selectType = SelectSearch.DatabaseAdd;
  user: User;
  teamsItems: Array<{ id: string; name: string }> = [];
  dealsItems: Array<any> = [];
  users: any = [];
  usersSelected: string[] = [];
  dealsSelected: any = [];
  isLoadingUsers = true;
  isLoadingDeals = true;
  typeRolRef = TypeRol;
  isAdmin: boolean;

  completeDate: any;
  localCompleteDate: string;

  //EVENTS
  @Output()
  markersDealsChanged = new EventEmitter<unknown>();

  @Output()
  markersUsersChanged = new EventEmitter<unknown>();

  @Output()
  selectedUsers = new EventEmitter<unknown>();

  @Output()
  usersListLoaded = new EventEmitter<unknown>();

  @Output()
  dealsListLoaded = new EventEmitter<unknown>();

  @Output()
  dateChanged = new EventEmitter<unknown>();

  @Output()
  polyLinesChanged = new EventEmitter<unknown>();

  path = [];

  paths = new Map<string, any>();

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dealService: DealService,
    private trackingService: TrackingService,
    private i18n: TranslateService,
    private selectSearchService: SelectSearchService
  ) {
    this.completeDate = new Date().toISOString().slice(0, 10);
    //  this.localCompleteDate = this.completeDate.toISOString();
    //this.localCompleteDate = this.localCompleteDate.substring(0, 9);
    this.centerMap();
  }

  ngOnInit(): void {
    // Center position in map
    this.centerPosition();

    //get users, deals
    this.getMainData();

    this.refreshPositions();
    this.draw();

    this.sub$.add(
      this.authService.user$.subscribe((res: User) => {
        this.user = res;
        if (this.user.role.includes(this.typeRolRef.ADMIN_ROLE)) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  watchEvents() {
    this.selectSearchService.clearEvent$.subscribe((data) => {});

    this.selectSearchService.removeEvent$.subscribe((data) => {});
  }

  private setUsersDefault(usrs: { id: string; name: string }[]) {
    const users = [];
    usrs.forEach((user, index) => {
      // just by default select 10 (ten) users for roma-select-search component
      if (index < 10) {
        //users.push(user.id);
      }
      users.push(user.id);
    });

    this.changeUsers(users, true);
  }

  setDealsDefault(dls: IDeal[]) {
    const deals = [];
    dls.forEach((deal, index) => {
      // just by default select 10 (ten) users for roma-select-search component
      if (index < 10) {
        deals.push(deal);
      }
    });
    this.changeDeal(deals, true);
  }

  centerMap() {
    this.center = {
      lat: 39.55509898294029,
      lng: -3.6115952141260927,
    };
  }

  getMainData() {
    this.sub$.add(
      this.authService.user$
        .pipe(filter((res) => !!res))
        .subscribe((res: User) => {
          this.user = res;
          this.resolveTeams();
          this.resolveDeals();
          this.draw();
        })
    );
  }

  centerPosition() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      // this.addMarker('Me', 'Estoy aca', IconColor.BLUE, position);
    });
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  addRandomMarker(name = null, color = null) {
    let url = 'https://maps.google.com/mapfiles/ms/icons/';
    url += color + '-dot.png';
    this.markers.push({
      position: {
        lat: this.center.lat + ((Math.random() - 0.5) * 3) / 10,
        lng: this.center.lng + ((Math.random() - 0.5) * 3) / 10,
      },
      label: {
        color: 'black',
        text: name ? name : 'Marker label ' + (this.markers.length + 1),
      },
      title: name ? name : 'Marker title ' + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
    });
  }

  addUserMarker(user: { id: string; name: string } = null, color = null) {
    let url = 'https://maps.google.com/mapfiles/ms/icons/';
    url += color + '-dot.png';
    this.markers.push({
      position: {
        lat: this.center.lat + ((Math.random() - 0.5) * 3) / 10,
        lng: this.center.lng + ((Math.random() - 0.5) * 3) / 10,
      },
      label: {
        color: 'black',
        text: user.name
          ? user.name
          : 'Marker label ' + (this.markers.length + 1),
      },
      title: user.name
        ? user.name
        : 'Marker title ' + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
    });
  }
  getTrackingUser(user: { id: string; name: string }) {
    return null;
  }

  addMarker(
    title: string,
    label: string,
    color: string,
    position: GeolocationPosition
  ) {
    let url = 'https://maps.google.com/mapfiles/ms/icons/';
    url += color + '-dot.png';
    this.markers.push({
      position: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
      label: {
        color: color,
        text: label,
      },
      title: title,
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
    });

    this.draw();
  }

  private resolveTeams() {
    this.isLoadingUsers = true;
    this.sub$.add(
      this.userService
        .findAllByCompany(this.user?.company?._id)
        .subscribe((res) => {
          this.isLoadingUsers = false;
          this.users = res;
          this.usersListLoaded.emit(res);
          this.teamsItems = ConvertToIdName(res);
          this.setUsersDefault(this.teamsItems);
          //console.log('teamsItems', this.teamsItems);
          this.draw();
        })
    );
  }

  private resolveDeals() {
    this.isLoadingDeals = true;

    this.sub$.add(
      this.dealService.deals$.subscribe((resp) => {
        this.isLoadingDeals = false;
        //Filter deals
        if (resp) {
          this.dealsItems = this.applyFilterDeals(resp);
          this.dealsListLoaded.emit(this.dealsItems);
        }

        this.changeDeal(this.dealsItems, true);
        // this.setDealsDefault(this.dealsItems);
        this.draw();
      })
    );
  }

  filterDealsWithCoordinates(deals: any) {
    const assetsFiltered = deals.filter(
      (deal) =>
        deal.asset?.latitude !== undefined &&
        deal.asset?.latitude != null &&
        deal.asset?.longitude !== undefined &&
        deal.asset?.longitude != null
    );

    return assetsFiltered;
  }

  filterDealsbyDate(deals: any) {
    const dealsByDate = [];
    deals.forEach((d) => {
      if (
        d?.initDate !== undefined &&
        d?.initDate?.slice(0, 10) === this.completeDate
      ) {
        dealsByDate.push(d);
      }
    });
    return dealsByDate;
  }

  applyFilterDeals(deals) {
    //Filter Assets with coordenates
    let assetsFiltered = null;
    if (deals) assetsFiltered = this.filterDealsWithCoordinates(deals);

    return assetsFiltered;
  }

  itemSelected($event) {}

  changeUsers(users, first = null) {
    this.resetPolylines();

    if (users?.length > 0) {
      const usrs = [];

      if (!first) {
        this.idUsers = users;
        this.selectedUsers.emit(this.idUsers);
      }

      this.usersSelected = users;
      this.teamsItems.forEach((team) => {
        users.forEach((userSelected) => {
          if (userSelected == team.id) {
            usrs.push(team);
          }
        });
      });

      this.addUserMarkers(usrs, IconColor.GREEN, first);
      this.draw();
    } else {
      this.selectedUsers.emit([]);
    }
  }
  addUserMarkers(
    usrs: { id: string; name: string }[],
    color: IconColor,
    first = null
  ) {
    const usersIDs = usrs.map((user) => {
      return user.id;
    });

    //const point = 'https://maps.google.com/mapfiles/ms/micons/man.png';
    let point = 'https://maps.google.com/mapfiles/kml/pal4/icon24.png';

    this.markers = [];

    // get tracking users by ID
    this.trackingService
      .groupByUsers(usersIDs, this.completeDate, first)
      .subscribe(
        (data) => {
          // group my response
          const groupedbyId = _.groupBy(data, (positions) => positions.user_id);
          const usersGrouped = Object.values(groupedbyId).reverse();

          let currentUSer;

          //Positions by Grouped by user
          usersGrouped.forEach((users, indexUser) => {
            // Quantity of positions
            const lasPosition = usersGrouped[indexUser].length;

            let lastLatPosition = 0;
            let lastLongPosition = 0;
            let labelHours = '';
            let samePosition = false;

            //Get a random color
            const markerColor = this.getRandomColor();

            users.forEach((trackUser, indexPosition) => {
              // if lat and long is the same create just one marker

              if (
                this.normalizePosition(lastLatPosition) ===
                  this.normalizePosition(trackUser.lat) &&
                this.normalizePosition(lastLongPosition) ===
                  this.normalizePosition(trackUser.long)
              ) {
                //console.log('es la misma posicion , acumulo horarios');
                labelHours += this.convertTimestamp(trackUser.instant) + ' - ';
                samePosition = true;
              } else {
                lastLatPosition = trackUser.lat;
                lastLongPosition = trackUser.long;
                samePosition = false;
                labelHours = this.convertTimestamp(trackUser.instant) + ' - ';
              }

              // create polylines , update and emit changes
              this.updatePolyLines(trackUser, indexPosition, markerColor);

              //Find user
              const user = usrs.filter(
                (user) => user.id === trackUser.user_id
              )[0];

              // User Finded
              if (user) {
                // build user with all information (user info , tracking info (firebase))
                const finalUSer = { ...trackUser, ...user };

                if (finalUSer) {
                  const relativePosition = indexPosition + 1;
                  const urlLastIcon =
                    'https://maps.google.com/mapfiles/ms/icons/' +
                    markerColor +
                    '-dot.png';

                  point =
                    'https://maps.google.com/mapfiles/ms/icons/' +
                    markerColor +
                    '.png';

                  //console.log('urlLastIcon,', urlLastIcon);
                  //console.log('point,', point);

                  // let url = 'https://maps.google.com/mapfiles/ms/micons/man.png';
                  // set last icon
                  const icon =
                    lasPosition === relativePosition ? urlLastIcon : point;

                  // set last Label marker
                  const labelMarker =
                    lasPosition === relativePosition ? true : false;

                  //BUILD AND PUSH MARKERS
                  //labelHours = labelHours.slice(0, labelHours.length - 2);
                  //console.log('labelHoursAccumulated = ', labelHours);
                  if (samePosition) {
                    this.markers = this.markers.slice(
                      0,
                      this.markers.length - 1
                    );
                  }
                  this.pushMarker(finalUSer, icon, labelMarker, labelHours);

                  this.draw();
                }
              }
            });
          });

          this.markersUsersChanged.emit(this.markers);
          this.draw();
        },
        (error) => {},
        () => {}
      );
    /*     this.trackingService
      .getTrackingByUsers(usersIDs, this.completeDate)
      .subscribe(

      ); */
    this.markersUsersChanged.emit(this.markers);
  }

  normalizePosition(position: number) {
    return position.toPrecision(5);
  }

  getRandomColor(): IconColor {
    const colorIndex = Math.floor(Math.random() * (6 + 1));
    return COLOR_ICON_LIST[colorIndex];
  }

  updatePolyLines(trackUser, index, color) {
    //init path for first position
    if (index == 0) {
      this.path = [];
    }

    //Create position
    const userPosition = {
      lat: +trackUser.lat,
      lng: +trackUser.long,
    };

    // set polylines
    this.path.push(new google.maps.LatLng(userPosition));
    const path = this.path;
    this.polylineOptions.strokeColor = color;
    this.polylineOptions = { ...this.polylineOptions, path };

    //update line by user
    this.paths.set(trackUser.user_id, this.polylineOptions);

    // convert to array of polylines for manipulate in component
    const array = [];
    this.paths.forEach((lines) => {
      array.push(lines);
    });
    this.polyLinesChanged.emit(array);
  }

  convertTimestamp(unix_timestamp) {
    const date = new Date(unix_timestamp * 1000);
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const seconds = '0' + date.getSeconds();

    // Will display time in 10:30:23 format
    const formattedTime =
      hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    //console.log(formattedTime);

    return formattedTime;
  }

  pushMarker(user: any, url: string, isLastOne: boolean, timeLabel: string) {
    const userPosition = {
      lat: +user.lat,
      lng: +user.long,
    };

    // this.path.push(new google.maps.LatLng(userPosition));

    this.markers.push({
      position: userPosition,
      label: {
        color: 'black',
        text: isLastOne
          ? user.name
            ? user.name
            : user.id
          : this.convertTimestamp(user.instant),
      },
      title: isLastOne
        ? user.name
          ? user.name
          : user.id
        : this.convertTimestamp(user.instant),
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
      id: user.id,
      time: timeLabel,
    });
    const path = this.path;
    // this.polylineOptions = { ...this.polylineOptions, path };
    //this.polyLinesChanged.emit(this.polylineOptions);
  }

  changeDeal(deals, first = null) {
    if (deals?.length > 0) {
      const myDeals = [];

      if (!first) {
        // this.markers = deals;
      }

      //Filter by Date selected
      const result = this.filterDealsbyDate(deals);
      this.dealsSelected = result;
      this.addDealMarkers(this.dealsSelected, IconColor.BLUE);
      this.draw();
    }

    this.markersDealsChanged.emit(this.markersDeals);
  }
  addDealMarkers(dls: any[], color: IconColor) {
    const url = 'https://maps.google.com/mapfiles/ms/icons/rangerstation.png';
    this.markersDeals = [];
    dls.forEach((dealSelected) => {
      this.pushMarkerDeals(dealSelected, url);
      this.draw();
    });
  }

  pushMarkerDeals(deal: any, url: string) {
    this.markersDeals.push({
      position: {
        lat: +deal.asset.latitude,
        lng: +deal.asset.longitude,
      },
      label: {
        color: 'black',
        text: deal.name ? deal.name : deal._id,
      },
      title: deal.name ? deal.name : deal._id,
      options: {
        animation: google.maps.Animation.DROP,
        icon: {
          url: url ? url : null,
          labelOrigin: new google.maps.Point(20, 40),
        },
      },
      id: deal._id,
    });
  }

  openInfoUser(marker: MapMarker, userID) {
    const user = this.users.find((user) => user._id === userID);

    if (user) {
      this.setinfoContentUser(user);
    } else {
      this.infoContent = 'not user found!';
    }
    this.info.open(marker);
  }

  openInfoDeal(marker: MapMarker, dealID: string) {
    const deal = this.dealsItems.find((deal) => deal._id === dealID);

    if (deal) {
      this.setinfoContentDeal(deal);
    } else {
      this.infoContent = 'not service found!';
    }

    this.info.open(marker);
  }

  setinfoContentDeal(deal: any) {
    this.infoContent =
      '<strong>' +
      deal?.name +
      '</strong></br>' +
      deal?.asset?.address +
      '</br>' +
      this.i18n.instant(this.getStatus(deal?.status)) +
      '</br><strong>' +
      this.i18n.instant('deals.dealDetail.createdBy') +
      deal?.createdBy?.name +
      '</strong></br>' +
      '<strong>' +
      this.getFinal(deal) +
      '</strong>';
  }

  setinfoContentUser(user: any) {
    this.infoContent =
      '<strong>' +
      user?.name +
      '</strong></br>' +
      user?.email +
      '</br>' +
      this.i18n.instant('customer.phone') +
      ': ' +
      this.resolvePhone(user) +
      '</br><strong>' +
      this.i18n.instant('settings.company') +
      ': </strong>' +
      this.resolveCompany(user);
  }

  resolvePhone(user) {
    const phone = user?.phone
      ? user.phone
      : this.i18n.instant('person.notAvailable');
    return phone;
  }

  resolveCompany(user) {
    const c = user?.company
      ? user.company.name
      : this.i18n.instant('person.notAvailable');
    return c;
  }
  testItemSelect($event) {}

  removeDeal(deal) {
    this.markersDeals = this.markersDeals.filter(
      (marker) => marker.id !== deal.value._id
    );
    this.markersDealsChanged.emit(this.markersDeals);
  }

  removeUser(user: { value: string }) {
    this.path = [];
    const path = this.path;
    this.polylineOptions = { ...this.polylineOptions, path };
    this.paths.set(user.value, this.polylineOptions);

    const array = [];
    this.paths.forEach((lines) => {
      array.push(lines);
    });

    this.polyLinesChanged.emit(array);

    this.idUsers = this.idUsers.filter((id) => id !== user.value);
    this.markers = this.markers.filter((marker) => marker.id !== user.value);
    this.usersSelected = this.usersSelected.filter((id) => id !== user.value);
    this.markersUsersChanged.emit(this.markers);
  }

  clearUserMarkers($event) {
    this.markers = [];
    this.idUsers = [];
    this.usersSelected = [];
    this.resetPolylines();
    this.polyLinesChanged.emit([]);
    this.markersUsersChanged.emit(this.markers);
  }

  clearDealMarkers($event) {
    this.markersDeals = [];
    this.markersDealsChanged.emit(this.markersDeals);
  }

  resetPolylines() {
    this.path = [];
    const path = this.path;
    this.paths.clear();
    this.polylineOptions = { ...this.polylineOptions, path };
    this.polyLinesChanged.emit([]);
  }

  emitChanges() {
    this.dateChanged.emit(this.completeDate);
    this.resetPolylines();
    this.changeUsers(this.usersSelected);
    this.changeDeal(this.dealsItems);
  }

  getFinal(deal: IDeal) {
    const name = deal?.final?.name === undefined ? '-' : deal?.final?.name;
    return name;
  }

  getStatus(status: IStatus) {
    const name =
      status?.translate === undefined ? status?.name : status?.translate;
    return name;
  }

  refreshPositions() {
    this.trackingService.refreshList$.subscribe((data) => {
      this.getMainData();
    });
  }
}
