import { Injectable } from '@angular/core';
import { Tracking } from '@tacliatech/types';

import { v4 as uuidv4 } from 'uuid';

import { Observable, Subject } from 'rxjs';
import { StorageService } from '../storage';

import { FirebaseService } from '@web-frontend/shared/services/firebase/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private refreshListSource = new Subject<boolean>();
  refreshList$ = this.refreshListSource.asObservable();

  constructor(private firebaseService: FirebaseService) {
    this.signInAnonymously();
  }

  private get DB() {
    return this.firebaseService.firestore;
  }

  private get auth() {
    return this.firebaseService.auth;
  }

  private async signInAnonymously() {
    if (!this.auth.currentUser) {
      await this.auth.signInAnonymously();
    }
  }

  refreshAll() {
    this.refreshListSource.next(true);
  }

  getTracking(userID: string) {
    return new Observable<Tracking[]>((observer) => {
      this.DB.collection('tracking')
        .where('user_id', '==', userID)
        .onSnapshot((snapshot) => {
          const changes = snapshot.docs.map(
            (doc) =>
              ({
                $key: doc.id,
                ...doc.data(),
              } as Tracking)
          );
          observer.next(changes);
        });
    });
  }

  getTrackingByUsers(users: string[], mydate: any) {
    const parts = mydate.split('-');
    const dateFilter = new Date(parts[0], parts[1] - 1, parts[2], 23, 59, 59);
    const dateFilterBefore = new Date(
      parts[0],
      parts[1] - 1,
      parts[2] - 1,
      23,
      59,
      59
    );

    return new Observable<Tracking[]>((observer) => {
      this.DB.collection('tracking')
        .where('user_id', 'array-contains-any', users)
        .onSnapshot((snapshot) => {
          const changes = snapshot.docs.map(
            (doc) =>
              ({
                $key: doc.id,
                ...doc.data(),
              } as Tracking)
          );
          observer.next(changes);
        });
    });
  }

  async addOrUpdate(tracking: Tracking) {
    await this.signInAnonymously();

    const docRef = this.DB.collection('tracking').doc(uuidv4());
    return docRef.set(tracking);
  }

  groupByUsers(userIds, mydate: any, first = null) {
    const parts = mydate.split('-');
    const dateFilterAfter = new Date(
      parts[0],
      parts[1] - 1,
      parts[2],
      23,
      59,
      59
    );
    const dateFilterBefore = new Date(
      parts[0],
      parts[1] - 1,
      parts[2] - 1,
      23,
      59,
      59
    );

    if (first) {
      return this.getAllUserPositionByCompany(
        dateFilterBefore,
        dateFilterAfter
      );
    } else {
      return this.getUsersSelectedPosition(
        userIds,
        dateFilterBefore,
        dateFilterAfter
      );
    }
  }

  getUsersSelectedPosition(
    userIds: any,
    dateFilterBefore: Date,
    dateFilterAfter: Date
  ) {
    return new Observable<Tracking[]>((observer) => {
      this.DB.collectionGroup('tracking')
        .where('user_id', 'in', userIds)
        .where('instant', '>=', dateFilterBefore)
        .where('instant', '<=', dateFilterAfter)
        .onSnapshot((snapshot) => {
          const changes = snapshot.docs.map(
            (doc) =>
              ({
                $key: doc.id,
                ...doc.data(),
              } as Tracking)
          );
          observer.next(changes);
        });
    });
  }

  getAllUserPositionByCompany(dateFilterBefore: Date, dateFilterAfter: Date) {
    return new Observable<Tracking[]>((observer) => {
      this.DB.collectionGroup('tracking')
        .where('company_id', '==', StorageService.CompanyId)
        .where('instant', '>=', dateFilterBefore)
        .where('instant', '<=', dateFilterAfter)
        .onSnapshot((snapshot) => {
          const changes = snapshot.docs.map(
            (doc) =>
              ({
                $key: doc.id,
                ...doc.data(),
              } as Tracking)
          );
          observer.next(changes);
        });
    });
  }
}
