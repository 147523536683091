import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from '@web-frontend/shared/helpers/alert';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalTemplateModule } from '../modal-template';
import { PhoneManagerModule } from '../phone-manager';
import { FieldErrorDisplayModule } from '../field-error-display';
import { CreateEquipmentModule } from '../create-equipment';
import { ModalInfoModule } from '../modal-info';

import { CreateProjectComponent } from './create-project.component';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { ImageSelectorModule } from '../image-selector';
import { InfoModule } from '@web-frontend/shared/components/info/info.module';
import { PermissionsModule } from '../permissions';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';
@NgModule({
  declarations: [CreateProjectComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    AppendCustomPropertiesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FieldErrorDisplayModule,
    PhoneManagerModule,

    CreateEquipmentModule,
    AlertModule,
    ModalInfoModule,
    NgSelectModule,
    ImageSelectorModule,
    InfoModule,
    PermissionsModule,
    ObligatoryFieldSignModule,
  ],
  exports: [CreateProjectComponent],
})
export class CreateProjectModule {}
