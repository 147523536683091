import { NgModule } from '@angular/core';
import { NoLoginRegisterBannerComponent } from './no-login-register-banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BaseButtonModule } from '../button/baseButton.module';
import { NoLoginRegisterModule } from '../../../shared/directives/no-login-register/no-login-register.module';

@NgModule({
  declarations: [NoLoginRegisterBannerComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    BaseButtonModule,
    NoLoginRegisterModule,
  ],
  providers: [],
  exports: [NoLoginRegisterBannerComponent],
})
export class NoLoginRegisterBannerModule {}
