import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth/auth.service';
import AmplitudeEvents from '../../../../types/amplitude.enum';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'no-login-register-banner',
  templateUrl: './no-login-register-banner.component.html',
  styleUrls: ['./no-login-register-banner.component.scss'],
})
export class NoLoginRegisterBannerComponent implements OnInit, OnDestroy {
  isNoLoginUser = false;
  sub$ = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.sub$.add(
      this.authService.isNoLoginUser$.subscribe((isNoLogin) => {
        this.isNoLoginUser = isNoLogin;
      })
    );
  }

  openWeb(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.no_login_banner_discover_taclia,
    });
    window.open('https://www.taclia.com/', '_blank');
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
