<div class="line"></div>
<div class="absence-summary-container">
  <h2>{{ title }}</h2>
  <ng-container *ngIf="displayItems.length === 0; else absenceList">
    <div class="empty-state">
      <img src="assets/icons/calendar-plus-alt.svg" />
      <p>{{ 'clockAbsence.emptyPendingAbsences' | translate }}</p>
    </div>
  </ng-container>
  <ng-template #absenceList>
    <div *ngFor="let item of itemsToDisplay" class="absence-item">
      <div class="absence-color" [style.backgroundColor]="item.color"></div>
      <div class="absence-details">
        <span class="absence-type">{{ item.type | translate }}</span>
        <div class="absence-date-days">
          <span class="absence-date"
            >{{ item.startDate
            }}{{ !item.isSameDay ? ' - ' + item.endDate : '' }}</span
          >
          <span *ngIf="!loadingTotal" class="absence-days">
            {{
              item.days +
                ' ' +
                (item.days === 1
                  ? ('clockAbsence.day' | translate)
                  : ('clockAbsence.days' | translate))
            }}
          </span>
          <span *ngIf="loadingTotal">
            <mat-progress-spinner
              class="loading-spinner icon-option"
              mode="indeterminate"
              [diameter]="20"
            ></mat-progress-spinner>
          </span>
        </div>
      </div>
    </div>
    <button
      *ngIf="displayItems.length > 2 && isMobile"
      (click)="toggleShowAll()"
      class="toggle-button"
    >
      {{
        showAllItems
          ? ('clockAbsence.seeLess' | translate)
          : ('clockAbsence.seeMore' | translate)
      }}
      <img
        [src]="
          showAllItems
            ? 'assets/icons/chevron-up.svg'
            : 'assets/icons/chevron-down.svg'
        "
        [alt]="showAllItems ? 'chevron-up' : 'chevron-down'"
      />
    </button>
  </ng-template>
</div>
