<roma-modal-template
  size="lg"
  [showHeader]="false"
  [disableSuccess]="false"
  [showDefaultButtons]="false"
  [showCancelBtn]="false"
  [showSuccessBtn]="false"
  [showCloseBtn]="false"
>
<div class="training-container">
  <div class="left-section">
    <div class="profile-images">
      <img src="assets/images/trainers/group-trainers.png" alt="trainers" class="profile-image">
    </div>
  </div>

  <div class="right-section">
    <img class="logo" src="/assets/img/logo-taclia.svg" alt="taclia" />
    <div class="text-center close-btn-wrapper">
      <button (click)="onClose()">
        <img src="/assets/icons/gl_close.svg"> <span>{{ 'forms.close' | translate }}</span>
      </button>
    </div>

    <div class="content">
      <h2>{{ translationKey + '.title' | translate }}</h2>

      <p class="subtitle">
        {{ translationKey + '.subtitle' | translate }}
      </p>

      <ul class="features">
        <li *ngFor="let item of [0, 1, 2]; let i = index">
          <mat-icon class="check-icon text-green">check</mat-icon>
          <div [innerHtml]="translationKey + '.item' + i | translate" ></div>
        </li>
      </ul>

      <div class="stats-box" *ngIf="showStats" [innerHtml]="'pro.training.stats' | translate"></div>

      <div class="pricing-section">
        <div class="price-box">
          <div class="price-label">
            {{ 'pro.training.product' | translate }}
          </div>
          <div class="price">
            {{price}} €
          </div>
          <span class="check-circle">
            <mat-icon class="check-icon text-white">check</mat-icon>
          </span>
        </div>

        <p class="footer-note" *ngIf="showDisclaimerMobile">
          {{ translationKey + '.disclaimer' | translate }}
        </p>

        <base-button size="medium" (click)="onPurchase()" [style]="{ 'width': '100%', 'min-width': '176px' }">
        {{ 'pro.training.cta' | translate }}
        </base-button>
      </div>

      <p class="footer-note" *ngIf="showDisclaimerDesktop; else skipStepButton">
        {{ translationKey + '.disclaimer' | translate }}
      </p>
      <ng-template #skipStepButton>
        <button class="skip-button" (click)="onClose()">
          {{ 'pro.training.skip_step' | translate }}
          <mat-icon class="arrow-right-icon">keyboard_arrow_right</mat-icon>
        </button>
      </ng-template>
    </div>
  </div>
</div>
</roma-modal-template>
