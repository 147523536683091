<div class="plan-selection-container">
  <!-- Header -->
  <div
    class="header"
    [ngClass]="{
      'header-collapsed': isCollapsed,
      'header-expanded': !isCollapsed
    }"
    [ngStyle]="{ 'background-color': getStyles().background }"
    (click)="toggleCollapse()"
  >
    <div class="module-header">
      <div class="module-summary">
        <div class="module-info">
          <img
            class="module-image"
            src="assets/icons/{{ moduleIcon }}.svg"
            alt="module icon"
          />
          <h2 class="module-name" [ngStyle]="{ color: getStyles().color }">
            {{ moduleName | translate }}
          </h2>
        </div>
        <p class="description">{{ description | translate }}</p>
      </div>
      <button class="collapsed-button">
        <img
          class="collapsed-image"
          [src]="
            isCollapsed
              ? 'assets/icons/chevron-down-gray.svg'
              : 'assets/icons/chevron-up-gray.svg'
          "
          alt="expandable"
        />
      </button>
    </div>
    <div *ngIf="isCollapsed" class="price-info">
      <span class="price"
        >{{ 'pro.planSelection.starting' | translate }} {{ startingPrice
        }}{{ currency }}/{{
          'pro.planSelection.periodMonthly' | translate
        }}</span
      >
      <button
        class="price-button"
        [ngStyle]="{
          'background-color': getStyles().priceBackground,
          color: getStyles().color
        }"
      >
        {{ 'pro.planSelection.seePricing' | translate }}
      </button>
    </div>
  </div>

  <!-- Content -->
  <div *ngIf="!isCollapsed" class="expanded-content">
    <div *ngIf="!isFullPlan">
      <div class="plans-container">
        <div *ngFor="let plan of plans" class="plan-card">
          <h3>{{ getPlanTranslationKey(plan.name) | translate }}</h3>
          <div>
            <p class="price">
              <ng-container *ngIf="currency === '$'; else euroCurrency"
                >{{ currency }}{{ plan.price }}</ng-container
              >
              <ng-template #euroCurrency
                >{{ plan.price }} {{ currency }}</ng-template
              >
              <span class="price-month">
                /{{ 'pro.planSelection.periodMonthly' | translate }}
              </span>
            </p>
            <p class="price-year" *ngIf="isPeriodYearly">
              {{ 'pro.planSelection.pay' | translate }}
              <ng-container *ngIf="currency === '$'; else euroCurrencyYear">
                {{ currency }}{{ plan.pricePerYear }}
              </ng-container>
              <ng-template #euroCurrencyYear
                >{{ plan.pricePerYear }}{{ currency }}</ng-template
              >
              /{{ 'pro.planSelection.periodAnnual' | translate }}
            </p>
          </div>
          <button
            class="subscribe-button"
            [ngClass]="{
              'subscribe-button-white': plan.name === 'basic',
              'subscribe-button-blue': plan.name === 'pro'
            }"
            (registerComplete)="onSelectPlan(plan)"
            clickNoLoginRegister
          >
            {{ 'pro.planSelection.subscribeButton' | translate }}
          </button>
        </div>
      </div>
      <ul class="features-list">
        <div class="feature-header feature-row">
          <div class="column left">
            {{ 'pro.planSelection.included' | translate }}
          </div>
          <div class="column center">
            {{ 'pro.planSelection.basic' | translate }}
          </div>
          <div class="column center">
            {{ 'pro.planSelection.pro' | translate }}
          </div>
        </div>
        <!-- Features -->
        <li *ngFor="let feature of featuresFiltered" class="feature-row">
          <span class="feature-name column left">{{
            feature.name | translate
          }}</span>
          <!-- Basic Feature -->
          <div class="column center">
            <ng-container
              *ngIf="isBoolean(feature?.basic); else basicDescription"
            >
              <mat-icon *ngIf="feature.basic" class="check-icon"
                >check</mat-icon
              >
              <mat-icon *ngIf="!feature.basic" class="close-icon"
                >close</mat-icon
              >
            </ng-container>
            <ng-template #basicDescription>
              <mat-icon
                *ngIf="isUnlimited(feature.basic)"
                class="check-icon text-green"
                >check</mat-icon
              >
              <span
                class="feature-description"
                [ngClass]="{ 'text-green': isUnlimited(feature.basic) }"
              >
                {{ feature.basic | translate }}</span
              >
            </ng-template>
          </div>
          <!-- Pro Feature -->
          <div class="column center">
            <ng-container *ngIf="isBoolean(feature?.pro); else proDescription">
              <mat-icon *ngIf="feature.pro" class="check-icon">check</mat-icon>
              <mat-icon *ngIf="!feature.pro" class="close-icon">close</mat-icon>
            </ng-container>
            <ng-template #proDescription>
              <mat-icon
                *ngIf="isUnlimited(feature.pro)"
                class="check-icon text-green"
                >check</mat-icon
              >
              <span
                class="feature-description"
                [ngClass]="{ 'text-green': isUnlimited(feature.pro) }"
                >{{ feature.pro | translate }}</span
              >
            </ng-template>
          </div>
        </li>
        <button class="more-features-button" (click)="toggleFeatures()">
          <span>{{ 'pro.planSelection.moreFeatures' | translate }}</span>
          <mat-icon *ngIf="!showMoreFeatures">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="showMoreFeatures">keyboard_arrow_up</mat-icon>
        </button>
      </ul>
    </div>
    <!-- Full Plan -->
    <div *ngIf="isFullPlan">
      <div class="plans-container">
        <div class="plan-card">
          <h3>{{ 'pro.planSelection.included' | translate }}</h3>
          <div *ngFor="let feature of features" class="card-info">
            <mat-icon class="check-icon">check</mat-icon>
            <span class="feature-name">{{ feature.name | translate }}</span>
          </div>
        </div>
        <div *ngFor="let plan of plans" class="plan-card">
          <h3>{{ 'pro.planSelection.full' | translate }}</h3>
          <p class="price">
            <ng-container *ngIf="currency === '$'; else euroCurrency"
              >{{ currency }}{{ plan.price }}</ng-container
            >
            <ng-template #euroCurrency
              >{{ plan.price }} {{ currency }}</ng-template
            >
            <span class="price-month">
              /{{ 'pro.planSelection.periodMonthly' | translate }}
            </span>
          </p>
          <p class="price-year" *ngIf="isPeriodYearly">
            {{ 'pro.planSelection.pay' | translate }}
            <ng-container *ngIf="currency === '$'; else euroCurrencyYear">
              {{ currency }}{{ plan.pricePerYear }}
            </ng-container>
            <ng-template #euroCurrencyYear
              >{{ plan.pricePerYear }}{{ currency }}</ng-template
            >
            /{{ 'pro.planSelection.periodAnnual' | translate }}
          </p>
          <button
            class="subscribe-button subscribe-button-blue"
            clickNoLoginRegister
            (registerComplete)="onSelectPlan(plan)"
          >
            {{ 'pro.planSelection.subscribeButton' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
