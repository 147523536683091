import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CalendarEventType } from '../../../../microfrontends/mf-calendar/mf-calendar.component';
import { AddClockAbsence } from '../../../../shared/components/clock-absences';
import { ClockAbsence } from '../../../../../types-legacy';
import { SandboxService, StorageService } from '../../../../shared/services';
import { Subscription } from 'rxjs';

type AbsenceItem = CalendarEventType & {
  type: keyof typeof ClockAbsence.AbsenceType;
};

interface DisplayItem {
  type: string;
  startDate: string;
  endDate: string;
  days: number;
  color: string;
  isSameDay: boolean;
}

@Component({
  selector: 'roma-absence-summary',
  templateUrl: './absence-summary.component.html',
  styleUrls: ['./absence-summary.component.scss'],
})
export class AbsenceSummaryComponent implements OnInit {
  @Input() title = 'Ausencias por validar';
  @Input() set absenceItems(items: AbsenceItem[]) {
    this._absenceItems = items;
    this.updateDisplayItems();
  }
  get absenceItems(): AbsenceItem[] {
    return this._absenceItems;
  }

  @Input() loadingTotal = false;

  displayItems: DisplayItem[] = [];
  visibleItems: DisplayItem[] = [];
  showAllItems = false;
  isMobile = window.screen.width < 768;
  resize$ = this.sandBoxService.screenBusChannel$;

  private _absenceItems: AbsenceItem[] = [];
  private readonly colorMap: Record<ClockAbsence.AbsenceType, string> = {
    [ClockAbsence.AbsenceType.Vacation]: '#8861FA',
    [ClockAbsence.AbsenceType.Sickness]: '#FDB53A',
    [ClockAbsence.AbsenceType.MaternityOrPaternity]: '#E2A7ED',
    [ClockAbsence.AbsenceType.SicknessOfAFamilyMember]: '#FC742B',
    [ClockAbsence.AbsenceType.Other]: '#34B8FA',
  } as const;

  private sub$ = new Subscription();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly sandBoxService: SandboxService
  ) {}

  ngOnInit(): void {
    this.updateDisplayItems();
    this.sub$.add(
      this.resize$.subscribe((res) => {
        this.isMobile = res.payload.mobile || res.payload.tablet;
        this.updateVisibleItems();
      })
    );
  }

  private updateDisplayItems(): void {
    this.displayItems = this._absenceItems.map(this.createDisplayItem);
    this.updateVisibleItems();
    this.cdr.detectChanges();
  }

  private updateVisibleItems(): void {
    this.visibleItems = this.showAllItems
      ? this.displayItems
      : this.displayItems.slice(0, 2);
    this.cdr.detectChanges();
  }

  toggleShowAll(): void {
    this.showAllItems = !this.showAllItems;
    this.updateVisibleItems();
  }

  private createDisplayItem = (item: AbsenceItem): DisplayItem => {
    const startDate = new Date(item.start);
    const endDate = new Date(item.end);
    const days = item.totalDays
      ? item.totalDays
      : this.calculateDays(startDate, endDate, item.isHalfDay);
    const isSameDay = startDate.toDateString() === endDate.toDateString();

    return {
      type: this.mapTypeName(item.type),
      startDate: this.formatDate(startDate),
      endDate: this.formatDate(endDate),
      days,
      color: this.getColor(item.type),
      isSameDay,
    };
  };

  private mapTypeName(type: keyof typeof ClockAbsence.AbsenceType): string {
    const absenceType = AddClockAbsence.ClockAbsenceTypes.find(
      (item) => item.value === ClockAbsence.AbsenceType[type]
    );
    return absenceType?.title ?? 'unknown';
  }

  private getColor(type: keyof typeof ClockAbsence.AbsenceType): string {
    return this.colorMap[ClockAbsence.AbsenceType[type]] ?? '#3B82F6';
  }

  private formatDate(date: Date): string {
    const lang = StorageService.GetItem('USER_LANG');
    const locale = lang === 'en' ? 'en-US' : 'es-ES';

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
    };

    return date.toLocaleDateString(locale, options);
  }

  private calculateDays(start: Date, end: Date, isHalfDay: boolean): number {
    if (isHalfDay) return 0.5;
    if (start.toDateString() === end.toDateString()) return 1;
    return (
      Math.ceil(
        Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
      ) + 1
    );
  }

  get itemsToDisplay(): DisplayItem[] {
    return this.isMobile ? this.visibleItems : this.displayItems;
  }
}
