import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  DynamicPropertyRef,
  DynamicPropertyType,
  Feature,
  ICustomProperty,
  TypeRol,
} from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { User } from '@web-frontend/shared/class';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  AuthService,
  DynamicPropertyService,
  StorageService,
} from '@web-frontend/shared/services';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { MatInput } from '@angular/material/input';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

class customProperty {
  _id: string;
  name: string;
  value: any;
  type: string;
}
@Component({
  selector: 'roma-append-custom-properties',
  templateUrl: './append-custom-properties.component.html',
  styleUrls: ['./append-custom-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppendCustomPropertiesComponent implements OnInit {
  @Input()
  parentForm: FormGroup;

  @Input()
  initForm = false;

  @Input()
  parentFormSubmit = false;

  @Input()
  rowLayout = false;

  @Input()
  size = 'md';

  @Input()
  typeRef: DynamicPropertyRef;

  @Input()
  control: AbstractControl;

  @Input()
  showPropertiesMessage = true;

  @Input()
  showCustomProperties = true;

  @Input()
  feature: Feature.Purchasable = Feature.SystemPermission.DefaultAllow;

  @Output()
  changeValues = new EventEmitter();

  @Output()
  initialCharge = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  @Output()
  customPropertiesForm = new EventEmitter();

  @Output()
  customPropertiesValue = new EventEmitter();

  @Output()
  propertiesLoading = new EventEmitter();

  @Input()
  showIsLoading = true;

  isLoading = false;
  showStringInput = false;
  showNumberInput = false;
  showBooleanInput = false;
  showDateInput = false;
  showSelectInput = false;
  customProperties: ICustomProperty[] = [];
  dynamicPropertyTypeRef = DynamicPropertyType;
  storageCustomProperties: customProperty[] = [];
  user: User;
  userActive$ = this.authService.user$;
  roles = TypeRol;
  featureRef = Feature;
  private sub$ = new Subscription();
  formSubmit = false;
  isFocus = false;

  @ViewChild('dateInput', {
    read: MatInput,
  })
  dateInput: MatInput;

  constructor(
    private dynamicPropertyService: DynamicPropertyService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private alertService: AlertService,
    private authService: AuthService,
    private i18n: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    this.setActiveUser();
  }

  ngOnInit() {
    this.requestDynamicProperties();
  }

  setActiveUser() {
    this.authService.user$.subscribe((res: User) => {
      this.user = res;
    });
  }

  async isAdmin() {
    return this.userActive$.subscribe((u) => {
      return this.user.role.includes(TypeRol.ADMIN_ROLE);
    });
  }

  changeElement(property, value: any) {
    const { name, type, _id } = property;
    const propertyIndex = this.storageCustomProperties.findIndex(
      (element) => element._id === property._id
    );

    type === 'NUMBER' ? (value = Number(value)) : null;

    if (type === 'BOOLEAN') {
      value ? (value = 'true') : (value = 'false');
    }

    if (type === 'DATE') {
      if (value === 'Invalid Date' || value === null) {
        value = '';
      }
    }

    const propertyValues = {
      _id,
      name,
      value,
      type,
    };

    if (propertyIndex < 0) {
      this.storageCustomProperties = [
        ...this.storageCustomProperties,
        propertyValues,
      ];
    } else {
      type === 'SELECT' ? (value.length === 0 ? (value = '') : null) : null;

      if (value) {
        this.storageCustomProperties[propertyIndex] = propertyValues;
      } else {
        this.storageCustomProperties.splice(propertyIndex, 1);
      }
    }

    this.emitChanges();
  }

  private emitChanges() {
    if (this.control) {
      this.control.setValue(this.storageCustomProperties);
    }

    this.changeValues.emit(this.storageCustomProperties);
  }

  private requestDynamicProperties() {
    this.isLoading = true;
    this.draw();
    this.propertiesLoading.next(true);

    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(StorageService.CompanyId, this.typeRef)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
            this.propertiesLoading.next(false);
          })
        )
        .subscribe((res) => {
          this.customProperties = res?.filter((it) => it != null) ?? [];
          this.customPropertiesForm.next(this.customProperties);
          this.resolveBooleanDefaultValues();
          this.draw();
        })
    );
  }

  private resolveBooleanDefaultValues() {
    for (let i = 0; i < this.customProperties.length; i++) {
      if (
        this.customProperties[i].type === 'BOOLEAN' &&
        this.customProperties[i].default !== null
      ) {
        this.changeElement(
          this.customProperties[i],
          this.i18n.instant('general.yes') === this.customProperties[i].default
        );
        this.setCustomPropertyValue(
          this.customProperties[i].isRequired,
          this.customProperties[i]._id,
          this.i18n.instant('general.yes') === this.customProperties[i].default
        );
      }
    }

    this.initialCharge.emit();
  }

  goTo(url: string, params: any) {
    if (this.typeRef === 'FINAL') {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.final_card_CustomFields,
      });
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.setting_customFields_start,
    });

    if (this.user.role.includes(this.roles.ADMIN_ROLE)) {
      localStorage.setItem('customView', 'CUSTOM_FIELDS');
      this.router.navigate([url], {
        queryParams: params,
      });
      this.closeModal.next();
    } else {
      this.alertService.error(this.i18n.instant('general.adminRole'));
    }
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  setCustomPropertyValue(isRequired: boolean, id: string, value) {
    if (isRequired) {
      this.parentForm?.controls[id]?.setValue(value);
      this.draw();
    }
  }

  isAvailableFeature(isAvailable, id) {
    if (!isAvailable) {
      this.parentForm?.get(id)?.clearValidators();
      this.parentForm?.get(id)?.updateValueAndValidity();
    }
  }

  isBooleanDefault(property: ICustomProperty, value) {
    return (this.i18n.instant('general.yes') == property.default) == value;
  }

  showPropertyInput(propertyId: string): void {
    const btnProperty = document.getElementById('btn-' + propertyId);
    if (btnProperty) {
      btnProperty.style.display = 'none';
    }
    const propertyIdElement = document.getElementById(propertyId);
    if (propertyIdElement) {
      propertyIdElement.style.display = 'block';
    }
    const errorProperty = document.getElementById('error-' + propertyId);
    if (errorProperty) {
      errorProperty.style.display = 'block';
    }
  }

  formatDateValue(value) {
    const dateString = value;
    const [day, month, year] = dateString.split('/');
    const dateObj = new Date(+year, +month - 1, +day);

    return dateObj;
  }

  dateInputChange(property, value) {
    this.changeElement(
      property,
      moment(this.formatDateValue(value)).format('YYYY-MM-DD')
    );
    this.setCustomPropertyValue(property.isRequired, property._id, value);
  }

  inputFocus() {
    this.isFocus = true;
    this.draw();
  }

  clearDate() {
    this.dateInput.value = '';
  }
}
