import { Company } from './../../../../types-legacy/lib/packages/companies/companies.types';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as braze from '@braze/web-sdk';
import { StorageService } from '@web-frontend/shared/services';
import { CompanyService } from '@web-frontend/shared/services/company';
import { environment } from '@web-frontend/environments';
import { BrazeEvent } from '@web-frontend/shared/services/braze/braze-event.model';
import { CheckTrialResponse } from '@web-frontend/shared/services/permissions';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { HttpClient } from '@angular/common/http';
import {
  BrazeUserAttributes,
  Prices,
  SubscriptionStatus,
} from '@tacliatech/types';
import { IosPermissionService } from '../ios-permission/ios-permission.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class BrazeService {
  private readonly enabled = environment.brazeConfig.enabled;

  private userData: any;
  private companyData: Company.CompanyData;
  private userSubscription: CheckTrialResponse;
  private brazeUser: any;

  constructor(
    private companyService: CompanyService,
    private readonly permissionService: PermissionService,
    private readonly http: HttpClient,
    private iosPermissions: IosPermissionService
  ) {}

  public init(): void {
    braze.initialize(environment.brazeConfig.key, {
      baseUrl: environment.brazeConfig.url,
      enableLogging: environment.brazeConfig.debug,
      allowUserSuppliedJavascript: true,
    });

    braze.automaticallyShowInAppMessages();
  }

  private isValidAccount(email: string): boolean {
    const regex = /^no-login\+.*@taclia\.com$/;
    return !regex.test(email);
  }

  private brazeEnabled(): boolean {
    const userData = StorageService.GetItem('userData');
    const userDataParsed = JSON.parse(userData || '{}');

    return (
      this.enabled &&
      this.iosPermissions.canTrackEvents() &&
      userDataParsed &&
      this.isValidAccount(userDataParsed?.email)
    );
  }
  public identifyUser(): void {
    if (!this.brazeEnabled()) return;

    this.userData = JSON.parse(StorageService.GetItem('userData'));
    this.companyData = JSON.parse(StorageService.GetItem('companyData'));

    const companyPrices = StorageService.GetItem('companyPrices');

    const prices: Array<Prices> =
      typeof companyPrices === 'string' && companyPrices !== 'undefined'
        ? JSON.parse(companyPrices)
        : [];
    if (this.userData && this.companyData) {
      Promise.all([
        this.http
          .get(
            `:API_URL/braze/user/?userId=${this.userData.id}&email=${this.userData.email}`
          )
          .toPromise(),
        this.permissionService.checkTrialSubscription(
          StorageService.CompanyId,
          'taclia',
          true
        ),
        this.companyService.findOne(StorageService.CompanyId).toPromise(),
      ])
        .then(([brazeUser, userSubscription]) => {
          this.brazeUser = brazeUser;
          this.userSubscription = userSubscription;

          const userDataToIdentify = {
            userId: this.userData.id,
            firstName: this.userData.name,
            email: this.userData.email,
            phone: this.userData.phone,
            userRole: this.userData.role,
            emailSubscribe: 'opted_in',
            pushSubscribe: 'opted_in',
            companyCreatedAt: this.companyData.created_at,
            companyName: this.companyData.name,
            featuresActive: this.companyData.modules,
            subscriptionPlan: this.companyData.plans.shift(),
            subscriptionStatus: this.getSubscriptionStatus(),
            startPayingSubscriptionAt: this.userSubscription.pro?.startAt,
            finishPayingSubscriptionAt: this.userSubscription.pro?.endAt,
            startTrialSubscriptionAt: this.userSubscription.trial?.startAt,
            finishTrialSubscriptionAt: this.userSubscription.trial?.endAt,
            companyUtmCampaign: this.companyData.company_utm_campaign,
            stChoice: this.companyData.stChoice,
            language: StorageService.GetItem('USER_LANG'),
            companyCountry: this.companyData.locationCountry,
            billing_period:
              prices.find(
                (price) => price.itemPriceId === this.companyData.plans[0]
              )?.periodUnit || 'month',
            monthlyPrice:
              prices.find((price) => price.periodUnit === 'month')?.price ||
              1400,
            yearlyPrice:
              prices.find((price) => price.periodUnit === 'year')?.price ||
              16262,
          };
          this.handleUser(userDataToIdentify);
        })
        .catch((err) => {
          Sentry.captureException(err, { level: 'fatal' });
        });
    }
  }

  public logout(): void {
    braze.destroy();
  }
  private getSubscriptionStatus(): string {
    if (this.userSubscription.remainingDays > 0) {
      return SubscriptionStatus.Starter;
    } else if (this.userSubscription.proPlanActivated) {
      return SubscriptionStatus.Active;
    }
    return SubscriptionStatus.Cancelled;
  }

  private handleUser(data: BrazeUserAttributes): void {
    braze.changeUser(data.userId);
    braze.openSession();

    if (!this.brazeUser) {
      this.createUser(data);
    } else {
      this.applyDeltaUpdates(data);
    }
  }
  private createUser(data: BrazeUserAttributes): void {
    if (!this.brazeEnabled()) return;
    try {
      const user = braze.getUser();
      if (!user) throw new Error('No user found');

      user.addAlias(data.userId, 'UserId');
      user.addAlias(data.email, 'Email');
      user.setLanguage(data.language);
      user.setFirstName(data.firstName);
      user.setEmail(data.email);
      user.setPhoneNumber(data.phone);

      user.setCustomUserAttribute('company_created_at', data.companyCreatedAt);
      user.setCustomUserAttribute('company_name', data.companyName);
      user.setCustomUserAttribute('email_subscribe', data.emailSubscribe);
      user.setCustomUserAttribute('push_subscribe', data.pushSubscribe);
      user.setCustomUserAttribute('user_role', data.userRole);
      user.setCustomUserAttribute('subscription_plan', data.subscriptionPlan);
      user.setCustomUserAttribute(
        'subscription_status',
        data.subscriptionStatus
      );
      user.setCustomUserAttribute('first_choice', this.companyData.stChoice);
      user.setCustomUserAttribute('features_active', data.featuresActive);
      user.setCustomUserAttribute(
        'startPayingSubscriptionAt',
        data.startPayingSubscriptionAt
          ? new Date(data.startPayingSubscriptionAt)
          : undefined
      );
      user.setCustomUserAttribute(
        'finishPayingSubscriptionAt',
        data.finishPayingSubscriptionAt
          ? new Date(data.finishPayingSubscriptionAt)
          : undefined
      );
      user.setCustomUserAttribute(
        'startTrialSubscriptionAt',
        data.startTrialSubscriptionAt
          ? new Date(data.startTrialSubscriptionAt)
          : undefined
      );
      user.setCustomUserAttribute(
        'finishTrialSubscriptionAt',
        data.finishTrialSubscriptionAt
          ? new Date(data.finishTrialSubscriptionAt)
          : undefined
      );
      user.setCustomUserAttribute(
        'device_type',
        window.innerWidth > 800 ? 'desktop' : 'mobile'
      );
      user.setCustomUserAttribute(
        'company_utm_campaign',
        this.companyData?.company_utm_campaign
      );
      user.setCustomUserAttribute('language', data.language);
      user.setCustomUserAttribute('company_country', data.companyCountry);
      user.setCustomUserAttribute('billing_period', data.billing_period);
      user.setCustomUserAttribute('monthly_price', data.monthlyPrice);
      user.setCustomUserAttribute('yearly_price', data.yearlyPrice);
    } catch (error) {
      Sentry.captureException(error, { level: 'fatal' });
    }
  }
  private applyDeltaUpdates(data: BrazeUserAttributes): void {
    if (!this.brazeEnabled()) return;
    try {
      const user = braze.getUser();
      if (!user) throw new Error('No user found');

      user.addAlias(data.userId, 'UserId');
      user.addAlias(data.email, 'Email');

      if (data.firstName !== this.brazeUser.firstName) {
        user.setFirstName(data.firstName);
      }

      if (data.email !== this.brazeUser.email) {
        user.setEmail(data.email);
      }

      if (data.phone !== this.brazeUser.phone) {
        user.setPhoneNumber(data.phone);
      }

      if (data.companyCreatedAt !== this.brazeUser.companyCreatedAt) {
        user.setCustomUserAttribute(
          'company_created_at',
          data.companyCreatedAt
        );
      }

      if (data.companyName !== this.brazeUser.companyName) {
        user.setCustomUserAttribute('company_name', data.companyName);
      }
      if (data.stChoice !== this.brazeUser.stChoice && data.stChoice !== null) {
        user.setCustomUserAttribute('first_choice', data.stChoice);
      }
      if (data.emailSubscribe !== this.brazeUser.emailSubscribe) {
        user.setCustomUserAttribute('email_subscribe', data.emailSubscribe);
      }

      if (data.pushSubscribe !== this.brazeUser.pushSubscribe) {
        user.setCustomUserAttribute('push_subscribe', data.pushSubscribe);
      }

      if (data.userRole !== this.brazeUser.userRole) {
        user.setCustomUserAttribute('user_role', data.userRole);
      }

      if (data.subscriptionPlan !== this.brazeUser.subscriptionPlan) {
        user.setCustomUserAttribute('subscription_plan', data.subscriptionPlan);
      }
      if (data.subscriptionStatus !== this.brazeUser.subscriptionStatus) {
        user.setCustomUserAttribute(
          'subscription_status',
          data.subscriptionStatus
        );
      }

      if (data.featuresActive !== this.brazeUser.featuresActive) {
        user.setCustomUserAttribute('features_active', data.featuresActive);
      }

      if (
        data.startPayingSubscriptionAt !==
        this.brazeUser.startPayingSubscriptionAt
      ) {
        user.setCustomUserAttribute(
          'date_start_paying_subscription',
          data.startPayingSubscriptionAt
            ? new Date(data.startPayingSubscriptionAt)
            : undefined
        );
      }

      if (
        data.finishPayingSubscriptionAt !==
        this.brazeUser.finishPayingSubscriptionAt
      ) {
        user.setCustomUserAttribute(
          'date_finish_paying_subscription',
          data.finishPayingSubscriptionAt
            ? new Date(data.finishPayingSubscriptionAt)
            : undefined
        );
      }

      if (
        data.startTrialSubscriptionAt !==
        this.brazeUser.startTrialSubscriptionAt
      ) {
        user.setCustomUserAttribute(
          'date_start_trial_subscription',
          data.startTrialSubscriptionAt
            ? new Date(data.startTrialSubscriptionAt)
            : undefined
        );
      }

      if (
        data.finishTrialSubscriptionAt !==
        this.brazeUser.finishTrialSubscriptionAt
      ) {
        user.setCustomUserAttribute(
          'date_finish_trial_subscription',
          data.finishTrialSubscriptionAt
            ? new Date(data.finishTrialSubscriptionAt)
            : undefined
        );
      }
      if (data.companyUtmCampaign !== this.brazeUser.companyUtmCampaign) {
        user.setCustomUserAttribute(
          'company_utm_campaign',
          this.companyData.company_utm_campaign
        );
      }

      if (data.language !== this.brazeUser.language) {
        user.setLanguage(data.language);
        user.setCustomUserAttribute('language', data.language);
      }

      if (data.companyCountry !== this.brazeUser.companyCountry) {
        user.setCustomUserAttribute('company_country', data.companyCountry);
      }

      if (data.billing_period !== this.brazeUser.billing_period) {
        user.setCustomUserAttribute('billing_period', data.billing_period);
      }

      if (data.monthlyPrice !== this.brazeUser.monthly_price) {
        user.setCustomUserAttribute('monthly_price', data.monthlyPrice);
      }
      if (data.yearlyPrice !== this.brazeUser.yearly_price) {
        user.setCustomUserAttribute('yearly_price', data.yearlyPrice);
      }
    } catch (error) {
      Sentry.captureException(error, { level: 'fatal' });
    }
  }

  public setCustomAttributes(data: { name: string; value: any }): void {
    if (!this.brazeEnabled()) return;
    const user = braze.getUser();
    if (!user) return; // no-login users
    user.setCustomUserAttribute(data.name, data.value);
  }

  public trackEvent(
    eventName: string,
    eventProperties: Record<string, unknown>
  ): void {
    if (!this.brazeEnabled()) {
      return;
    }
    const date = new Date();
    if (braze.getUser()) {
      braze.logCustomEvent(eventName, {
        date_create: date.toISOString(),
        ...eventProperties,
      });
    } else if (this.userData) {
      braze.changeUser(this.userData.id);
      braze.logCustomEvent(eventName, {
        date_create: date.toISOString(),
        ...eventProperties,
      });
    }
  }

  public sendEvent(data: BrazeEvent): void {
    if (!this.brazeEnabled()) {
      return;
    }
    const date = new Date();
    if (braze.getUser()) {
      braze.logCustomEvent(data.event, {
        date_create: date.toISOString(),
      });
    } else {
      if (this.userData) {
        braze.changeUser(this.userData.id);
        braze.logCustomEvent(data.event, {
          date_create: date.toISOString(),
        });
      }
    }
  }
}
