import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateBudgetComponent } from './create-budget.component';
import { BudgetHeaderComponent } from './budget-header/budget-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RmSelectModule } from '../globals/rm-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BudgetFooterComponent } from './budget-footer/budget-footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BudgetUtilisService } from './budget-util.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RmSelectRegisterModule } from '../globals/rm-select-register';
import { BudgetTotalComponent } from './budget-footer/budget-total/budget-total.component';
import { BudgetCategoryComponent } from './budget-category/budget-category.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CreateSidenavService } from './budget-sidenav/create-budget-sidenav.service';
import { BudgetSidenavComponent } from './budget-sidenav/budget-sidenav.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BudgetCalculationsService } from './budget-calculations.service';
import { RmSelectV2Module } from '../globals/rm-select-v2';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SelectItemModule } from '../create-deal/wrapper-part-of-service/select-item/select-item.module';
import { BudgetCategoryHeaderComponent } from './budget-category/budget-category-header/budget-category-header.component';
import { ImageSelectorModule } from '@web-frontend/shared/components/image-selector-v2';
import { PermissionsModule } from '../permissions';
import { BasicSnackbarSharedModule } from '../snackbar/basic-snackbar/basic-snackbar.module';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { SnackbarModule } from '@web-frontend/shared/services/snackbar/snackbar.module';
import { TooltipModule } from '@web-frontend/shared/directives/tooltip/tooltip.module';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';
import { OnlyNumberModule } from '@web-frontend/shared/directives/onlyNumber';
import { OpenSettingsModule } from '../../directives/open-settings/open-settings.module';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';
import { NoLoginRegisterBannerModule } from '../../../common/components/no-login-register-banner/no-login-register-banner.module';
import { NoLoginRegisterModule } from '../../directives/no-login-register/no-login-register.module';

@NgModule({
  declarations: [
    CreateBudgetComponent,
    BudgetHeaderComponent,
    BudgetFooterComponent,
    BudgetTotalComponent,
    BudgetCategoryComponent,
    BudgetSidenavComponent,
    BudgetCategoryHeaderComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RmSelectV2Module,
    RmSelectRegisterModule,
    RmSelectModule,
    TranslateModule.forChild(),
    NgSelectModule,
    MatMenuModule,
    MatCheckboxModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatRadioModule,
    FormsModule,
    MatProgressSpinnerModule,
    ClickDeleteModule,
    ImageSelectorModule,
    SelectItemModule,
    PermissionsModule,
    BasicSnackbarSharedModule,
    CurrencyFormatModule,
    SnackbarModule,
    TooltipModule,
    ObligatoryFieldSignModule,
    OnlyNumberModule,
    OpenSettingsModule,
    BaseButtonModule,
    NoLoginRegisterBannerModule,
    NoLoginRegisterModule,
  ],
  providers: [
    BudgetUtilisService,
    CreateSidenavService,
    BudgetCalculationsService,
  ],
  exports: [CreateBudgetComponent],
})
export class CreateBudgetModule {}
