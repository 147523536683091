import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  CatchmentChannels,
  Company,
  ConvertToKeyValue,
  CustomPropertyFromFormDto,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  ICustomProperty,
  IEquipmentNote,
  IFinal,
  TypeFinalsClients,
  ValidateAllFormFields,
} from '@tacliatech/types';
import {
  LocationClass,
  MapperPlaceResult,
} from '@web-frontend/shared/class/location';
import {
  FinalService,
  SandboxService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { from, Observable, Subscription } from 'rxjs';
import { EditCustomPropertiesComponent } from '../../edit-custom-properties';
import { fileData, ImageSelectorComponent } from '../../image-selector-v2';
import { CreateFinalV2Component } from '../create-final-v2.component';
import PlaceResult = google.maps.places.PlaceResult;
import { InvoiceService } from '@web-frontend/invoice/invoice.service';
import { FormatEmailValidatorAllowNull } from '@web-frontend/shared/validators/email';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { uniq } from 'lodash';

interface phone {
  pre: string;
  number: string;
}
@Component({
  selector: 'roma-final-wrapper-detail',
  templateUrl: './wrapper-detail-component.html',
  styleUrls: ['./wrapper-detail-component.scss'],
})
export class WrapperDetailComponent implements OnInit {
  resize$ = this.sandBoxService.screenBusChannel$;

  @ViewChild('inputGoogleEditable')
  inputGoogleEditable: ElementRef<HTMLInputElement>;

  @Output()
  updateFormStatus = new EventEmitter<boolean>();

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  set customerData(value: IFinal) {
    this._customerData = value;
  }

  @ViewChild(EditCustomPropertiesComponent)
  editCustomProperties: EditCustomPropertiesComponent;

  @ViewChild(ImageSelectorComponent)
  imageSelector: ImageSelectorComponent;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get customerData() {
    return this._customerData;
  }

  _customerData: IFinal;
  selectedLocation: LocationClass;

  checkboxItems = {
    showOwnerInput: false,
    showChannelInput: false,
  };
  private localStorageCheckBoxName = `createFinals.${StorageService.UserId}.checkboxs`;
  usersIsLoading = false;
  isLoadingDetailOfCustomer = true;
  private isLoadedDetailOfCustomer = false;
  showDivComments = true;
  isLoading = false;
  showDivImg = true;
  featureRef = Feature;
  featureRefUser = FeatureUser;
  idModuleRef = Company.IdModule;
  hasFeatureHandleFinalCustomProperty = false;
  propertiesIsLoading = false;
  invalidEditCustomFields = false;
  parentFormSubmit = false;
  refreshModalOnClose = false;
  emailErrors = false;
  nameError = false;
  showCustomProperties = true;
  customPropertiesLoaded = false;
  initChildForm = false;
  customProperties: ICustomProperty[] = [];

  imagesParsed: fileData[] = [];
  private imagesParsedCopy: fileData[] = [];
  customerNotes = [];
  phonesArr: phone[] = [{ pre: null, number: null }];
  totalPhoneInputs = 3;
  activeEmailInput = 1;
  totalEmailInputs = 3;
  typeClientFinals = [];
  selectedtypeClientFinals = [];
  users = [];
  selectedUsers = [];
  selectedOwner = [];
  catchmentChannels = [];
  selectedCatchmentChannels = [];

  typePropertyRef = DynamicPropertyRef;

  get featureUserScreen(): FeatureUser.Final {
    if (this.type == 'ADD') {
      return FeatureUser.Final.create;
    }
    return FeatureUser.Final.update;
  }

  form: FormGroup = this.fb.group({
    name: new FormControl(null, [Validators.required]),
    fiscalName: new FormControl(null),
    nif: new FormControl(null),
    email0: new FormControl(null, [FormatEmailValidatorAllowNull()]),
    email1: new FormControl(null, [FormatEmailValidatorAllowNull()]),
    email2: new FormControl(null, [FormatEmailValidatorAllowNull()]),
    phonesArr: [],
    notes: [[]],
    customProperties: [{}],
    files: [],
    type: new FormControl(null),
    idUsers: [],
    idOwner: new FormControl(null),
    catchmentChannel: new FormControl(null),
    address: new FormControl(null),
    addressAdditional: new FormControl(null),
    idCreatedBy: [StorageService.UserId],
    rfc: new FormControl(null),
    fiscal_regime: new FormControl(null),
  });

  customPropertiesToSave: CustomPropertyFromFormDto[] = [];
  private GET_INITIAL_FORM = () => {
    return StorageService.GetItem('INITIAL_FORM_CREATE_FINAL');
  };
  private sub$ = new Subscription();

  mexicanCompany = false;
  fiscalRegimes: any[] = [];
  fiscalRegimeSelected: any[] = [];
  loadingFiscalRegimes = false;

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private toastService: ToastService,
    private finalService: FinalService,
    private dialogRef: MatDialogRef<CreateFinalV2Component>,
    private userService: UserService,
    private permissionService: PermissionService,
    private sandBoxService: SandboxService,
    private invoiceService: InvoiceService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.resolveTypeFinalsClients();
    this.resolveUsers();
    this.resolveCatchmentChannels();
    this.resolveIsLoadingDetailOfCustomer();
    this.resolveLocalStorageViewMore();
    this.watchPermissions();
    this.checkCountry();

    if (this.type === 'EDIT' && this.customerData) {
      this.patchCustomerData();
    }
  }

  get isMexicanCompany(): boolean {
    return this.userService.validateCountryMX();
  }

  checkCountry(): void {
    if (this.userService.validateCountryMX()) {
      this.loadingFiscalRegimes = true;
      this.mexicanCompany = true;

      from(this.invoiceService.getTaxRegimeList()).subscribe((res) => {
        this.fiscalRegimes = res.map((item) => ({
          title: item.description,
          value: item.id,
        }));

        if (this.type === 'EDIT' && this.customerData?.fiscalRegime) {
          this.fiscalRegimeSelected = [
            this.fiscalRegimes.find(
              (item) => item.value === this.customerData?.fiscalRegime
            ),
          ];
        }
        this.loadingFiscalRegimes = false;
      });
    }
    if (!this.mexicanCompany) {
      this.form.removeControl('fiscal_regime');
      this.form.removeControl('rfc');
    }
  }

  changeFiscalRegimeSelected(event) {
    this.fiscalRegimeSelected = event;

    const value = event[0]?.value || null;
    this.form.patchValue({
      fiscal_regime: value,
    });
    this.draw();
  }

  toggleDivComments() {
    this.showDivComments = !this.showDivComments;
  }

  toggleDivImg() {
    this.showDivImg = !this.showDivImg;
  }

  addingNotes(evt: IEquipmentNote[]) {
    let isEditing = false;
    let event: string;
    const notes = evt.map((note) => {
      return {
        text: note.text,
        lastName: note.name,
        createdAt: note.createdAt,
        idAuthor: note.idAuthor,
      };
    });

    if (notes) {
      if (evt.length < this.form.value.notes?.length) {
        event = AmplitudeEvents.final_card_deleteComments;
      } else {
        notes.map((note, index) => {
          if (this.form.value.notes && this.form.value.notes[index]) {
            if (note.text !== this.form.value.notes[index].note) {
              isEditing = true;
            }
          }
        });
        event = isEditing
          ? AmplitudeEvents.final_card_editComments
          : AmplitudeEvents.final_card_addComments;
      }
    }

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: event,
    });

    this.form.patchValue({
      notes: notes,
    });
  }

  changeFileArray(evt: fileData[]) {
    this.imagesParsed = evt?.length ? evt.filter((f) => !f.markForDelete) : [];
    let event = '';

    if (this.imagesParsedCopy && this.imagesParsedCopy.length > evt?.length) {
      event = AmplitudeEvents.final_card_removeAttachments;
    } else if (evt?.length > 0) {
      event = AmplitudeEvents.final_card_addAttachments;
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: event,
    });

    this.imagesParsedCopy = this.imagesParsed;
    this.form.patchValue({
      files: this.imagesParsed,
    });
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private parseEmails() {
    const { emailArr, email } = this.customerData;
    const allEmails = emailArr || [];
    if (email && !allEmails.includes(email)) {
      allEmails.unshift(email);
    }
    return allEmails.filter(Boolean);
  }

  private fillForm() {
    const emails = this.parseEmails();
    this.activeEmailInput = emails.length;

    this.form.patchValue({
      name: `${this.customerData?.name || ''} ${
        this.customerData?.lastName || ''
      }`.trim(),
      fiscalName: this.customerData?.fiscalName,
      nif: this.customerData?.nif,
      email0: emails?.[0],
      email1: emails?.[1],
      email2: emails?.[2],
      phones: [],
      phonesArr: this.customerData?.phonesArr || [{ pre: null, number: null }],
      customProperties: this.customerData?.customProperties,
      notes: this.customerData?.notes,
      files: this.customerData?.files?.length ? this.customerData.files : [],
      type: this.customerData?.type,
      idUsers: this.customerData?.idUsers,
      idOwner: this.customerData?.idOwner,
      catchmentChannel: this.customerData?.catchmentChannel,
      address: this.customerData?.address,
      addressAdditional: this.customerData?.addressAdditional,
      rfc: this.customerData?.rfc,
      fiscal_regime: this.customerData?.fiscalRegime,
    });
  }

  private patchCustomerData() {
    this.fillForm();

    if (this.customerData?.address) {
      setTimeout(() => {
        document
          .getElementById('inputGoogleEditable')
          .setAttribute('value', this.customerData?.address);
      }, 10);
    }

    this.customPropertiesLoaded = this.customerData?.customProperties?.length;

    if (this.customerData?.files?.length) {
      const images = this.customerData?.files;

      this.imagesParsed = images?.map((file: fileData | string) => {
        if (typeof file === 'string') {
          return {
            extension: file
              .substring(0, file.indexOf('?'))
              .slice(file.indexOf('.'))
              .replaceAll('.', ''),
            fileFirebaseUrl: file,
            fileName: file.substring(0, file.indexOf('.')),
          };
        }
        return {
          extension: file.extension,
          fileFirebaseUrl: file.fileFirebaseUrl,
          fileName: file.fileName,
        };
      });

      this.draw();
    }

    if (this.customerData?.notes) {
      this.customerNotes = this.customerData?.notes?.map((note) => {
        return {
          text: note.text,
          lastName: note.name,
          idAuthor: note.idAuthor,
          createdAt: note.createdAt,
        };
      });
    }

    if (this.customerData?.phones?.length) {
      this.phonesArr = this.customerData?.phones.map((res) => {
        return {
          pre: null,
          number: res,
        };
      });
    }

    if (this.customerData?.phonesArr?.length) {
      this.phonesArr = this.customerData?.phonesArr;
    }

    if (this.customerData?.type) {
      this.selectedtypeClientFinals = [
        this.typeClientFinals.find(
          (item) => item.value === this.customerData?.type
        ),
      ];
    }

    if (this.customerData?.idUsers?.length) {
      this.selectedUsers = this.customerData?.usersObj
        ?.map((res) => {
          return {
            title: res.name.toString(),
            value: res._id,
            id: res._id,
          };
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    }

    if (this.customerData?.ownerObj) {
      this.selectedOwner = [
        {
          title: this.customerData?.ownerObj.name.toString(),
          value: this.customerData?.ownerObj._id,
          id: this.customerData?.ownerObj._id,
        },
      ];
    }

    if (this.customerData?.catchmentChannel) {
      this.selectedCatchmentChannels = [
        this.catchmentChannels.find(
          (item) => item.value === this.customerData?.catchmentChannel
        ),
      ];
    }

    StorageService.SetItem(
      'INITIAL_FORM_CREATE_FINAL',
      JSON.stringify(this.form.value)
    );

    this.isLoadingDetailOfCustomer = false;
    this.formChangeDetector();
    this.draw();
  }

  private resolveIsLoadingDetailOfCustomer() {
    if (this.type === 'ADD') {
      this.isLoadingDetailOfCustomer = false;
      this.isLoadedDetailOfCustomer = true;
      this.formChangeDetector();
      this.draw();
    }
  }

  private watchPermissions() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Final.HandleCustomProperties)
        .subscribe((hasFeatureHandleFinalCustomProperty) => {
          this.hasFeatureHandleFinalCustomProperty = hasFeatureHandleFinalCustomProperty;
        })
    );
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setCustomProperties();
  }

  private setCustomProperties() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Final.HandleCustomProperties)
        .subscribe((hasFeatureHandleFinalCustomProperty) => {
          this.hasFeatureHandleFinalCustomProperty = hasFeatureHandleFinalCustomProperty;
          const isFieldRequired = (index: number) => {
            return !hasFeatureHandleFinalCustomProperty ? index < 2 : true;
          };
          for (let i = 0; i < this.customProperties?.length; i++) {
            if (this.customProperties[i].isRequired) {
              this.form.addControl(
                this.customProperties[i]._id,
                new FormControl(
                  null,
                  isFieldRequired(i) ? [Validators.required] : []
                )
              );
            }
            this.attachToCustomProperties(
              this.customProperties[i],
              this.customProperties[i].value
            );
          }

          this.initChildForm = true;
          this.parentFormSubmit = false;
          this.customPropertiesLoaded = true;
        })
    );
  }

  attachToCustomProperties(property, value: any) {
    const { name, type, _id } = property;
    const propertyIndex = this.customPropertiesToSave.findIndex(
      (element) => element._id === property._id
    );
    const propertyValues = {
      _id,
      name,
      value,
      type,
    };

    if (propertyIndex < 0) {
      this.customPropertiesToSave = [
        ...this.customPropertiesToSave,
        propertyValues,
      ];
    } else {
      type === 'SELECT' ? (value?.length === 0 ? (value = '') : null) : null;

      if (value) {
        this.customPropertiesToSave[propertyIndex] = propertyValues;
      } else {
        this.customPropertiesToSave.splice(propertyIndex, 1);
      }
    }

    if (this.type === 'EDIT') {
      //Update Form (TYPE = 'EDIT')
      const propertyFormIndex = this.form.value.customProperties.findIndex(
        (element) => element._id === property._id
      );

      if (propertyFormIndex > -1) {
        this.form.value.customProperties[propertyFormIndex].value = value;
        this.form.value.customProperties[propertyFormIndex].type =
          property.type;
      } else {
        this.form.patchValue({
          customProperties: [
            ...this.form.value.customProperties,
            propertyValues,
          ],
        });
      }
    }
  }

  propertiesLoading(evt: boolean) {
    this.propertiesIsLoading = evt;
  }

  propertiesRequired(value: boolean) {
    this.invalidEditCustomFields = value;
  }

  updateLoading(evt) {
    this.isLoading = evt;
    this.draw();
  }

  eventValueChanged(evt) {
    this.form.patchValue({ customProperties: [...evt] });
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.deal_card_editCustomField,
    });
  }

  private resolveCatchmentChannels() {
    const items = ConvertToKeyValue(
      CatchmentChannels,
      StorageService.GetItem('USER_LANG'),
      'id'
    );

    this.catchmentChannels = items.map((res) => {
      return {
        title: res.key.toString(),
        value: res.value,
        id: res.value,
      };
    });

    this.draw();
  }

  changeCatchmentChannels(event) {
    this.selectedCatchmentChannels = event;
    const value = event[0]?.value || null;

    this.form.patchValue({
      catchmentChannel: value,
    });
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_editCatchment,
    });
  }

  private resolveUsers() {
    this.usersIsLoading = true;
    this.sub$.add(
      this.userService.findAllUsersByCompany_SELECT().subscribe((res) => {
        const items = ConvertToKeyValue(res, 'name', '_id');
        this.users = items
          .map((res) => {
            return {
              title: res.key.toString(),
              value: res.value,
              id: res.value,
            };
          })
          .sort((a, b) => (a.title > b.title ? 1 : -1));
        this.usersIsLoading = false;
        this.draw();
      })
    );
  }

  changeUsers(event) {
    this.selectedUsers = event;
    this.form.patchValue({
      idUsers: event.map((res) => {
        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName: AmplitudeEvents.final_user_assigned,
        });
        return res.value;
      }),
    });

    if (event.length === 0) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: AmplitudeEvents.final_user_unassigned,
      });
    }
  }

  changeOwners(event) {
    this.selectedOwner = event;
    const value = event[0]?.value || null;
    this.form.patchValue({
      idOwner: value,
    });
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze'],
      eventName: value
        ? AmplitudeEvents.final_owner_assigned
        : AmplitudeEvents.final_owner_unassigned,
    });
  }

  private resolveTypeFinalsClients() {
    const items = ConvertToKeyValue(
      TypeFinalsClients,
      StorageService.GetItem('USER_LANG'),
      'id'
    );

    this.typeClientFinals = items.map((res) => {
      return {
        title: res.key.toString(),
        value: res.value,
        id: res.value,
      };
    });

    if (this.type === 'EDIT' && !this.selectedtypeClientFinals[0]) {
      if (this.customerData?.type) {
        this.selectedtypeClientFinals = [
          this.typeClientFinals.find(
            (item) => item.value === this.customerData?.type
          ),
        ];
      }
    }

    this.draw();
  }

  changeTypeClientFinals(event) {
    this.selectedtypeClientFinals = event;
    const value = event[0]?.value || null;
    this.form.patchValue({
      type: value,
    });

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_editFinalType,
    });
  }

  public haveChanges() {
    let detector = false;
    try {
      const EMPTY_VALUES = ['[]', 'null', 'undefined', '{}'];
      const initialForm = JSON.parse(this.GET_INITIAL_FORM());

      for (let i = 0; i < Object.keys(this.form.value)?.length; i++) {
        const key = Object.keys(this.form.value)[i];
        const convertForValueInString = JSON.stringify(this.form.value[key]);
        const convertInitialFormInString = JSON.stringify(initialForm[key]);

        if (this.type === 'ADD') {
          if (
            !EMPTY_VALUES.includes(convertForValueInString) &&
            !EMPTY_VALUES.includes(convertInitialFormInString)
          ) {
            if (convertForValueInString !== convertInitialFormInString) {
              detector = true;
            }
          }
        } else {
          if (convertForValueInString !== convertInitialFormInString) {
            detector = true;
          }
        }
      }

      if (this.imagesParsedCopy.length) {
        detector = true;
      }

      this.updateFormStatus.emit(detector);
    } catch (err) {}

    return detector;
  }

  private formChangeDetector() {
    this.sub$.add(
      this.form.valueChanges.subscribe((x) => {
        this.haveChanges();
      })
    );
  }

  phoneChanges(event, index) {
    this.phonesArr[index] = event;
    this.form.patchValue({
      phonesArr: this.phonesArr,
    });
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    if (!this.form.valid) {
      if (
        this.form.controls.email0.errors ||
        this.form.controls.email1.errors ||
        this.form.controls.email2.errors
      ) {
        this.emailErrors = true;
      }

      if (this.form.controls.name.errors) {
        this.nameError = true;
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.final_card_noEditName_create,
        });
      }

      this.toastService.show({
        text: this.i18n.instant('general.formErrors'),
        type: 'error',
        msDuration: 4000,
      });
    }

    return this.form.valid;
  }

  parsePhoneToSubmit(data): void {
    data.phonesArr = [data.phonesArr].flat()?.filter((phone) => phone?.number);
    if (data.phonesArr?.length === 0) {
      delete data.phonesArr;
    }
  }

  async submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();
    this.haveChanges();

    if (isValid) {
      this.isLoading = true;
      this.draw();

      const images = await this.imageSelector?.uploadFiles2();

      if (images?.length) {
        this.form.patchValue({
          files: images,
        });
      }

      const customPropertiesLength = Object.keys(
        this.form.value.customProperties
      ).length;

      if (this.customPropertiesToSave.length > 0 && customPropertiesLength) {
        const ids = new Set(this.form.value.customProperties.map((d) => d._id));
        const customProperties = [
          ...this.form.value.customProperties,
          ...this.customPropertiesToSave.filter((d) => !ids.has(d._id)),
        ];

        this.form.patchValue({
          customProperties: [...customProperties],
        });
      } else if (
        this.customPropertiesToSave.length > 0 &&
        customPropertiesLength <= 0
      ) {
        this.form.patchValue({
          customProperties: [...this.customPropertiesToSave],
        });
      } else {
        this.form.patchValue({
          customProperties: [],
        });
      }

      // Remove empty phones from the array if there are any
      this.cleanEmptyPhones();

      const data = this.form.value;

      const { email0, email1, email2 } = data;
      Object.assign(data, {
        fiscalRegime: data.fiscal_regime,
        emailArr: uniq([email0, email1, email2].filter(Boolean)),
      });

      this.parsePhoneToSubmit(data);

      const obs$: Observable<any> =
        this.type === 'ADD'
          ? this.finalService.create(data)
          : this.finalService.updateOne(this.customerData?._id, data);

      const EVENT =
        this.type === 'ADD'
          ? BrazeEventType.final_create
          : BrazeEventType.final_edit;

      this.sub$.add(
        obs$.subscribe(
          (res) => {
            const message =
              this.type === 'ADD'
                ? 'finals.create.success'
                : 'finals.update.success';
            this.isLoading = false;
            this.parentFormSubmit = false;
            this.toastService.show({
              text: this.i18n.instant(message),
              type: 'success',
              msDuration: 4000,
            });
            this.analyticsService.trackEvent({
              sources: ['amplitude', 'braze'],
              eventName: EVENT,
            });
            this.refreshModalOnClose = true;
            this.dialogRef.close(res._id);
            this.draw();
          },
          () => {
            this.parentFormSubmit = false;
            this.isLoading = false;
            this.draw();

            this.toastService.show({
              text: this.i18n.instant('finals.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
    }
  }

  public async editSubmit(
    options: { close: boolean } = { close: true }
  ): Promise<void> {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();
    const isValidCustomProperties = this.isValidCustomProperties();

    if (!isValid || !isValidCustomProperties) return;

    this.isLoading = true;
    this.draw();

    const images = await this.imageSelector?.uploadFiles2();

    if (images?.length) {
      this.form.patchValue({
        files: images,
      });
    }

    if (this.customPropertiesToSave.length > 0) {
      this.form.patchValue({
        customProperties: [
          ...this.form.value.customProperties,
          ...this.customPropertiesToSave,
        ],
      });
    }

    // Remove empty phones from the array if there are any
    this.cleanEmptyPhones();

    const data = { ...this.form.value };

    const { email0, email1, email2 } = data;
    Object.assign(data, {
      fiscalRegime: data.fiscal_regime,
      emailArr: uniq([email0, email1, email2].filter(Boolean)),
    });

    try {
      await this.finalService
        .updateOne(this.customerData?._id, data)
        .toPromise();
      this.isLoading = false;
      this.parentFormSubmit = false;
      this.refreshModalOnClose = true;

      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.final_edit,
      });
      this.toastService.show({
        text: this.i18n.instant('finals.update.success'),
        type: 'success',
        msDuration: 4000,
      });

      this.draw();

      if (options.close) {
        this.dialogRef.close(this.customerData?._id);
      }
    } catch (err) {
      this.parentFormSubmit = false;
      this.isLoading = false;
      this.draw();
      this.toastService.show({
        text: this.i18n.instant(this.i18n.instant('finals.error')),
        type: 'error',
        msDuration: 4000,
      });
    }
  }

  private isValidCustomProperties(): boolean {
    let isValid = true;
    const fields = this.editCustomProperties.EditableFieldComponent.toArray();
    let index = 0;
    for (const field of fields) {
      try {
        if (field.type === 'array-string-multiple') {
          if (field.submitNgSelect(field.value || [])) {
            isValid = false;
          }
        } else {
          let fieldInputElement: HTMLInputElement;

          if (field.type === 'text')
            fieldInputElement = field.inputEditable.nativeElement;

          if (field.type === 'date')
            fieldInputElement = field.inputDateEditable.nativeElement;

          if (field.type === 'number')
            fieldInputElement = field.inputNumberEditable.nativeElement;

          if (field.type === 'boolean')
            fieldInputElement = field.selectBoolean.nativeElement;

          if (field.submit(fieldInputElement)) {
            isValid = false;
          }
        }
      } catch (err) {}
      index++;
    }

    return isValid;
  }

  changeInputsCheckBox(
    evt: MatCheckboxChange,
    type: 'showOwnerInput' | 'showChannelInput'
  ): void {
    this.checkboxItems[type] = !this.checkboxItems?.[type];
    this.saveInputsCheckBox();
  }

  sendSeeMoreEvent() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_seeMore,
    });
  }

  addPhoneEvent() {
    this.phonesArr.push({ pre: null, number: null });
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_addPhone,
    });
  }

  private saveInputsCheckBox() {
    localStorage.setItem(
      this.localStorageCheckBoxName,
      JSON.stringify(this.checkboxItems)
    );
  }

  private resolveLocalStorageViewMore() {
    const elements = JSON.parse(
      StorageService.GetItem(this.localStorageCheckBoxName)
    );

    if (elements) {
      this.checkboxItems = elements;
    }
  }

  isValidMxAdrress(): boolean {
    return (
      !this.selectedLocation ||
      (this.selectedLocation?.streetName != null &&
        this.selectedLocation?.postCode != null &&
        this.selectedLocation?.city != null)
    );
  }

  autocompleteSelected(result: PlaceResult) {
    this.selectedLocation = MapperPlaceResult(result);

    this.form.patchValue({
      address: this.selectedLocation.formattedAddress,
    });

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_seeMap,
    });

    this.draw();
  }

  close() {
    const refresh = this.refreshModalOnClose ? true : null;
    this.dialogRef.close(refresh);
  }

  onBlurMethod(
    input:
      | 'nameInput'
      | 'fiscalNameInput'
      | 'nifInput'
      | 'emailInput'
      | 'inputGoogleEditable'
      | 'inputDetailLocation'
      | 'rfcInput',
    value: string
  ): void {
    const inputEventMap = {
      nameInput: AmplitudeEvents.final_card_editName,
      fiscalNameInput: AmplitudeEvents.final_card_editTaxName,
      nifInput: AmplitudeEvents.final_card_editNif,
      emailInput: AmplitudeEvents.final_card_editEmail,
      inputGoogleEditable: AmplitudeEvents.final_card_editLocation,
      inputDetailLocation: AmplitudeEvents.final_card_editDetailLocation,
    };

    if (value !== '' && inputEventMap[input]) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: inputEventMap[input],
      });
    }
  }

  addEmailEvent(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_addEmail,
    });
  }

  eventDelete(): void {
    if (this.type !== 'EDIT') return;
    this.isLoading = true;
    this.draw();

    this.sub$.add(
      this.finalService.deleteOne(this.customerData._id).subscribe(() => {
        this.toastService.show({
          text: 'general.deleteManyCorrect',
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
        this.refreshModalOnClose = true;
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.deal_card_delete_delete,
        });
        this.close();
      })
    );
  }

  cleanEmptyPhones(): void {
    if (this.form.get('phonesArr').value?.length) {
      this.form.patchValue({
        phonesArr: this.form
          .get('phonesArr')
          .value.filter(
            (phone: { pre: string; number: string }) => phone.number?.length
          ),
      });
    }
  }
}
