import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfAuthComponent } from './mf-auth.component';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { NativeAuthService } from './native-auth.service';

const microfrontendKey = 'mf-auth';

@NgModule({
  imports: [CommonModule],
  declarations: [MfAuthComponent],
  exports: [MfAuthComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppName,
      useValue: microfrontendKey,
    },
    {
      provide: AppUrl,
      useFactory: () => {
        return localStorage.getItem(microfrontendKey);
      },
    },
    NativeAuthService,
  ],
})
export class MfAuthNoRoutingModule {}
