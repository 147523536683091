import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BudgetCommonData,
  BudgetsSetting,
  Company,
  Feature,
  FeatureUser,
  IBudgetCommonData,
  IBudgetsSetting,
  IFinal,
  IHeaderBudget,
  Tax,
  ToKeyValueStatus,
  TypeBudget,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  DealService,
  FinalService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { RmSelect } from '../globals/rm-select/rm-select.types';

import { Subscription } from 'rxjs';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { FilterItems } from '../filter';
import { filter, finalize } from 'rxjs/operators';
import { CompanyModuleService } from '@web-frontend/shared/services/company/company-module.service';
import { getDateStrResum } from '@web-frontend/shared/utils';
import { APP_DATE_FORMATS, AppDateAdapter } from '../filter/filter.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateFinalV2Service } from '../create-final-v2';

@Component({
  selector: 'roma-budget-header',
  templateUrl: './budget-header.component.html',
  styleUrls: ['./budget-header.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetHeaderComponent implements OnInit, OnDestroy {
  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  type: string;

  @Input()
  idDeal: string;

  @Input() set customerId(value: string) {
    this._customerId = value;
    if (this._customerId != '') {
      this.budget.header.contact = this._customerId;
      this.budget.header.contact_id = this._customerId;
    }
  }

  _customerId: string;

  @Input()
  header: IHeaderBudget;

  @Input()
  budget: IBudgetCommonData;

  @Input()
  taxes: Tax[] = [];

  @Input()
  featureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;
  @Output()
  changedHeader = new EventEmitter<unknown>();

  @Output()
  isValid = new EventEmitter<boolean>();

  private sub$ = new Subscription();

  canEditBasic = true;

  contacts: RmSelect.Items = [];
  selectedContact: RmSelect.Items = [];
  isLoadingContacts = false;

  deals: RmSelect.Items = [];
  selectedDeal: RmSelect.Items = [];
  isLoadingDeals = false;

  statusBudgetList: FilterItems;
  statusSelected: number;
  finalSelected: any;
  statusBudgetsPlaceholder = 'filter.status-budgets';
  contactPlaceholder = 'budgets.settings.create.form.contactsPlaceholder';
  numberDocPlaceholder = 'budgets.settings.create.form.numberDocPlaceholder';
  isLoading = false;
  budgetConfig: BudgetsSetting = new BudgetsSetting();
  form: FormGroup;
  date: string;
  dueDate: string;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;
  currentLang = 'en-US';

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetUtilisService: BudgetUtilisService,
    private budgetService: BudgetService,
    private alertService: AlertService,
    private finalService: FinalService,
    private i18n: TranslateService,
    private createFinalV2Service: CreateFinalV2Service,
    private dealService: DealService,
    private companyModuleService: CompanyModuleService,
    private amplitudeService: AmplitudeService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private userService: UserService
  ) {
    this.getSettings();
    i18n.onLangChange.subscribe((lang) => {
      this.statusBudgetList = [...this.translate(this.statusBudgetList)];
      this.setCalendarsLang(lang.lang);
    });
  }

  disabledSelect = false;

  ngOnInit(): void {
    this.setCalendarsLang(this.i18n.currentLang);
    this.initForm();
    ValidateAllFormFields(this.form);
    this.watchRefreshList();
    this.requestFinals();
    this.requestDeals();

    if (this.header !== undefined) {
      if (this.mode === 'EDIT') {
        this.setStatus();
        let dateObj;
        if (this.header.date) {
          dateObj = getDateStrResum(new Date(this.header.date));
          this.date = this.header.date;
        }
        let dueDateObj;
        if (this.header.dueDate) {
          dueDateObj = getDateStrResum(new Date(this.header.dueDate));
          this.dueDate = this.header.dueDate;
        }

        this.form.patchValue({
          idDeal: this.header.idDeal,
          contact: this.header.contact,
          contact_id: this.header.contact_id,
          date: dateObj?.parseToISOStringLocal,
          dueDate: dueDateObj?.parseToISOStringLocal,
          numberDoc: this.header.numberDoc,
          prefix: this.header.prefix,
        });
      } else {
        this.budget = new BudgetCommonData();
      }
    }
    this.form.markAsTouched();
    this.form.updateValueAndValidity();

    this.isValid.emit(this.form.valid);
    this.draw();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  initForm() {
    this.form = this.fb.group({
      idDeal: new FormControl(null),
      contact: new FormControl(null),
      contact_id: new FormControl(null),
      date: new FormControl(null),
      dueDate: new FormControl(null),
      numberDoc: new FormControl(this.header.numberDoc, [Validators.required]),
      prefix: new FormControl(this.header.prefix),
    });
  }

  translate(status: FilterItems) {
    return (
      status?.map((item) => {
        return {
          ...item,
          name: item?.key ? this.i18n.instant(item?.key) : item.key,
        };
      }) ?? []
    );
  }

  isDisabled(control: any, belongForm = true) {
    const result = this.form.valid === false ? true : false;
    if (belongForm) {
      if (result) {
        this.form.controls[control].disable();
      } else {
        this.form.controls[control].enable();
      }
    }
    return result;
  }

  private watchRefreshList() {
    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe((data: any) => {
        this.budget = data;
        this.header = data.header;
        this.form.markAsTouched();
        this.patchHeader();
        this.draw();
      })
    );

    /*this.sub$.add(
      this.budgetUtilisService.refreshItems.subscribe((items: ItemBudget[]) => {
        this.budget.items = items;
      })
    );*/
  }

  patchHeader() {
    // ValidateAllFormFields(this.form);
    let dateObj;
    if (this.header.date) {
      dateObj = getDateStrResum(new Date(this.header.date));
      this.date = this.header.date;
    }
    let dueDateObj;
    if (this.header.dueDate) {
      dueDateObj = getDateStrResum(new Date(this.header.dueDate));
      this.dueDate = this.header.dueDate;
    }

    this.form.patchValue({
      idDeal: this.header.idDeal,
      contact: this.header.contact,
      contact_id: this.header.contact_id,
      date: dateObj?.parseToISOStringLocal,
      dueDate: dueDateObj?.parseToISOStringLocal,
      numberDoc:
        this.header.numberDoc === undefined ? null : this.header.numberDoc,
      prefix: this.header.prefix
        ? this.header.prefix
        : this.budgetConfig?.invoiceSettings?.prefixNumber,
    });

    //this.form.updateValueAndValidity();
    this.isValid.emit(this.form.valid);

    this.draw();
  }
  setStatus() {
    const status = this.statusBudgetList?.find(
      (s) => s.value === this.budget?.status
    )?.value;
    this.statusSelected = status;
  }

  private refreshContacts(idAdded: string) {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.isLoadingContacts = true;
          this.draw();

          this.finalService
            // .search({ applyPaginate: false, limit: 1 })
            .findAllFinalsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.isLoadingContacts = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.contacts = res.map((res) => {
                return {
                  title: `${res.name} ${res.lastName ? res.lastName : ''}`,
                  value: res._id,
                  id: res._id,
                };
              });

              this.updateSelectedContact(idAdded);
            });
        })
    );
  }

  private requestFinals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.isLoadingContacts = true;
          this.draw();

          this.finalService
            // .search({ applyPaginate: false, limit: 1 })
            .findAllFinalsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.isLoadingContacts = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.contacts = res.map((res) => {
                return {
                  title: `${res.name} ${res.lastName ? res.lastName : ''}`,
                  value: res._id,
                  id: res._id,
                };
              });

              if (this._customerId) {
                this.form.patchValue({
                  contact: this._customerId,
                  contact_id: this._customerId,
                });
                this.disabledSelect = true;
              }

              if (this.mode === 'EDIT') {
                if (
                  this.contacts.find(
                    (c) => c.id === this.budget?.header?.contact
                  )
                ) {
                  this.selectedContact = [
                    this.contacts.find(
                      (c) => c.id === this.budget?.header?.contact
                    ),
                  ];
                  if (this.selectedContact.length > 0) {
                    this.form.patchValue({
                      contact: this.selectedContact[0].value,
                      contact_id: this.selectedContact[0].value,
                    });
                  }

                  this.budget.header.contact_id = this.budget?.header?.contact;
                  this.draw();
                } else {
                  this.selectedContact = [];
                }

                this.draw();
              }
            });
        })
    );
  }

  changeContact(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        contact: first,
        contact_id: first,
      });
    } else {
      this.selectedContact = [];
      this.form.patchValue({
        contact: null,
        contact_id: null,
      });
    }

    this.setStatus();
    const h = this.form.value;
    this.budget.header = h;
    this.changedHeader.emit(this.budget);
    if (this.mode === 'EDIT') {
      this.updateBudget(this.budget);
      this.draw();
    }
  }

  private requestDeals() {
    this.sub$.add(
      this.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
        .subscribe(() => {
          this.isLoadingDeals = true;
          this.draw();

          this.sub$.add(
            // this.dealSearchService
            //   .search({ applyPaginate: false, optimize: true })
            this.dealService
              .findAllDealsByCompany_SELECT()
              .pipe(
                finalize(() => {
                  this.isLoadingDeals = false;
                  this.draw();
                })
              )
              .subscribe((res) => {
                this.deals = res.map((res) => {
                  return {
                    title: res.name || this.i18n.instant('general.untitle'),
                    value: res._id,
                    id: res._id,
                  };
                });

                if (this.idDeal) {
                  this.form.patchValue({
                    idDeal: this.idDeal,
                  });
                  this.disabledSelect = true;
                }

                if (this.mode === 'EDIT') {
                  if (this.deals.find((c) => c.id === this.header.idDeal)) {
                    this.selectedDeal = [
                      this.deals.find((c) => c.id === this.header.idDeal),
                    ];
                  } else {
                    this.selectedDeal = [];
                  }
                }
                this.draw();
              })
          );
        })
    );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  onChangeDeals(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        idDeal: first,
      });
    } else {
      this.selectedDeal = [];
      this.form.patchValue({
        idDeal: null,
      });
    }

    this.budget.header = this.form.value;
    this.changedHeader.emit(this.budget);
    this.isValid.next(this.form.valid);

    if (this.mode === 'EDIT') {
      this.updateBudget(this.budget);
      this.draw();
    }
  }

  resetContacts() {
    this.form.patchValue({
      contact: null,
      contact_id: null,
    });

    this.setStatus();
    const h = this.form.value;
    this.budget.header = h;
    this.changedHeader.emit(this.budget);
    if (this.mode === 'EDIT') {
      this.updateBudget(this.budget);
      this.draw();
    }
  }
  onChangeContacts(contact: IFinal) {
    this.form.patchValue({
      contact: contact._id,
      contact_id: contact._id,
    });
    this.setStatus();
    const h = this.form.value;
    this.budget.header = h;
    this.changedHeader.emit(this.budget);
    if (this.mode === 'EDIT') {
      this.updateBudget(this.budget);
      this.draw();
    }
  }

  onChangeStatusbudget(event) {
    const h = this.form.value;
    this.budget.header = h;
    this.budget.status = event.value;
    this.changedHeader.emit(this.budget);

    if (this.mode === 'EDIT') {
      this.updateBudget(this.budget);
      this.draw();
    } else {
    }
  }

  updateBudget(data: any) {
    if (this.type == TypeBudget.BUDGET) {
      if (this.budget?._id) {
        if (data?.header?.date == '') {
          data.header.date = null;
        }
        if (data?.header?.dueDate == '') {
          data.header.dueDate = null;
        }
        this.sub$.add(
          this.budgetService
            .updateOne(this.budget?._id, data)
            .subscribe((data) => {
              let dateObj;
              if (data.header.date) {
                dateObj = getDateStrResum(new Date(data.header.date));
                this.date = data.header.date;
                data.header.date = data.header.date;
              }
              let dueDateObj;
              if (data.header.dueDate) {
                dueDateObj = getDateStrResum(new Date(data.header.dueDate));
                this.dueDate = data.header.dueDate;
                data.header.dueDate = data.header.dueDate;
              }

              this.budget = data;
              this.setStatus();

              this.budgetUtilisService.refreshBudget.emit(this.budget);
              /*     this.alertService.success(
                this.i18n.instant('budgets.settings.edit.successUpdated')
              ); */
              this.draw();
            })
        );
      }
    }

    if (this.type == TypeBudget.PROFORM) {
      if (this.budget._id) {
        this.sub$.add(
          this.budgetService
            .updateOneProform(this.budget?._id, data)
            .subscribe((data) => {
              this.budget = data;
              this.budgetUtilisService.refreshBudget.emit(this.budget);
              this.draw();
            })
        );
      }
    }

    if (this.type == TypeBudget.BILL) {
      if (this.budget?._id) {
        this.sub$.add(
          this.budgetService
            .updateOneBill(this.budget?._id, data)
            .subscribe((data) => {
              this.budget = data;
              this.budgetUtilisService.refreshBudget.emit(this.budget);
              this.draw();
            })
        );
      }
    }
  }

  numDocChanged() {
    this.budget.header.numberDoc = this.form.value?.numberDoc;
    const h = this.form.value;
    this.budget.header = h;
    this.form.updateValueAndValidity();
    this.changedHeader.emit(this.budget);
    this.isValid.emit(this.form.valid);

    if (this.form.valid) {
      if (this.mode === 'EDIT') {
        this.updateBudget(this.budget);
        this.draw();
      }
    }
  }

  areDatesCorrect() {
    let correctDates = true;
    if (this.form.value?.dueDate && this.form.value?.date) {
      const date = new Date(this.form.value?.date);
      const dueDate = new Date(this.form.value?.dueDate);

      const dateObj = getDateStrResum(date);
      const dueDateObj = getDateStrResum(dueDate);

      if (
        new Date(dueDateObj.formattedDate) < new Date(dateObj.formattedDate)
      ) {
        correctDates = false;
      }
    }

    if (!correctDates) {
      this.alertService.error(
        this.i18n.instant('budgets.settings.inconsistentDates')
      );
    }
    return correctDates;
  }

  initDates() {
    let dateObj;
    if (this.date) {
      dateObj = getDateStrResum(new Date(this.date));
    }
    let dueDateObj;
    if (this.dueDate) {
      dueDateObj = getDateStrResum(new Date(this.dueDate));
    }

    this.form.patchValue({
      idDeal: this.header.idDeal,
      contact: this.header.contact,
      contact_id: this.header.contact_id,
      date: dateObj?.parseToISOStringLocal,
      dueDate: dueDateObj?.parseToISOStringLocal,
      numberDoc: this.header.numberDoc,
      prefix: this.header.prefix,
    });
  }

  dateChanged() {
    if (!this.form.value?.date) {
      this.budget.header.date = null;
      this.changedHeader.emit(this.budget);
      this.isValid.emit(this.form.valid);
      if (this.mode === 'EDIT') {
        this.updateBudget(this.budget);
        this.draw();
      }
      return;
    }

    if (this.areDatesCorrect()) {
      const date = new Date(this.form.value?.date);
      this.header.dueDate = this.dueDate;
      const dateObj = getDateStrResum(date);

      this.form.updateValueAndValidity();
      this.isValid.emit(this.form.valid);
      //let h = this.form.value;
      //this.budget.header = h;
      this.date = dateObj.formattedDate;
      this.budget.header.date = dateObj.formattedDate;
      this.changedHeader.emit(this.budget);

      if (this.mode === 'EDIT') {
        this.updateBudget(this.budget);
        this.draw();
      } else {
        //  this.budgetUtilisService.refreshBudget.emit(this.budget);
      }
    } else {
      this.initDates();
    }
  }

  dueDateChanged() {
    if (!this.form.value?.dueDate) {
      this.budget.header.dueDate = null;
      this.changedHeader.emit(this.budget);
      this.isValid.emit(this.form.valid);
      if (this.mode === 'EDIT') {
        this.updateBudget(this.budget);
        this.draw();
      }
      return;
    }

    if (this.areDatesCorrect()) {
      const date = new Date(this.form.value?.dueDate);
      this.header.date = this.date;
      const dateObj = getDateStrResum(date);

      this.dueDate = dateObj.formattedDate;
      this.budget.header.dueDate = dateObj.formattedDate;
      this.changedHeader.emit(this.budget);

      this.isValid.emit(this.form.valid);

      if (this.mode === 'EDIT') {
        this.updateBudget(this.budget);
        this.draw();
      }
    } else {
      this.initDates();
    }
  }

  headerChanged(event) {
    const h = this.form.value;

    this.budget.header = h;
    this.changedHeader.emit(this.budget);
    this.isValid.emit(this.form.valid);
    this.budgetUtilisService.refreshBudget.emit(this.budget);
  }

  getSettings() {
    this.isLoading = true;
    const id = StorageService.CompanyId;

    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (resp: IBudgetsSetting) => {
            this.budgetConfig = resp;
            this.isLoading = false;
            this.draw();
          },
          (error) => {
            this.isLoading = false;
          }
        )
    );
  }

  setCalendarsLang(lang: string) {
    if (lang === 'en') {
      this.currentLang = 'en-US';
    } else {
      this.currentLang = 'es-ES';
    }
    localStorage.setItem('currentLang', this.currentLang);
    this._adapter.setLocale(this.currentLang);
  }

  addFinals() {
    this.amplitudeService.sendEvent({
      event: 'testing_final_create',
    });
    this.sub$.add(
      this.createFinalV2Service.open().subscribe((data) => {
        this.refreshContacts(data);
      })
    );
  }
  updateSelectedContact(idContactAdded: string) {
    if (this.mode === 'EDIT') {
      // search selectedContact
      this.selectedContact = [
        this.contacts.find((c) => c.id === idContactAdded),
      ];

      if (this.selectedContact.length > 0) {
        // update Form
        this.form.patchValue({
          contact: this.selectedContact[0].value,
          contact_id: this.selectedContact[0].value,
        });

        // update header
        this.budget.header.contact_id = idContactAdded;
        this.budget.header.contact = idContactAdded;
        this.changedHeader.emit(this.budget);

        // update in DATABASE
        this.updateBudget(this.budget);
        this.draw();
      }
    }
  }
}
