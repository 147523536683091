import { Component } from '@angular/core';
import { PlatformService } from '../../platform-disable-content/platform.service';
import { TrainingRequestService } from './training-request.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import AmplitudeEvents from '../../../../../types/amplitude.enum';

@Component({
  selector: 'roma-training-request',
  templateUrl: './training-request.component.html',
  styleUrls: ['./training-request.component.scss'],
})
export class TrainingRequestComponent {
  price = 49.99;

  private readonly translationMobile = 'pro.training.mobile';
  private readonly translationDesktop = 'pro.training.desktop';

  constructor(
    private TrainingRequestService: TrainingRequestService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService
  ) {}

  get translationKey(): string {
    return this.platformService.isMobile()
      ? this.translationMobile
      : this.translationDesktop;
  }

  get showStats(): boolean {
    return !this.platformService.isMobile();
  }

  get showDisclaimerMobile(): boolean {
    return this.platformService.isMobile();
  }

  get showDisclaimerDesktop(): boolean {
    return !this.platformService.isMobile();
  }

  private async reloadLocation(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 100));
    window.location.reload();
  }

  public onClose(): void {
    this.analyticsService.trackEvent({
      eventName: AmplitudeEvents.training_close_button,
    });
    this.reloadLocation();
  }

  public async onPurchase(): Promise<void> {
    this.analyticsService.trackEvent({
      eventName: AmplitudeEvents.training_accepted,
    });
    const result = await this.TrainingRequestService.requestTraining();
    if (result?.success) {
      this.reloadLocation();
    }
  }
}
