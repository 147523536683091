import moment from 'moment-timezone';
import { ClockHour } from 'src/types-legacy/lib/packages/clock-hour/clock-hour.types';

export const formatClockDate = (date: ClockHour.ClockDate | string): string => {
  if (typeof date === 'string') {
    return moment(date).format('YYYY-MM-DD');
  } else {
    const { year, month, day } =
      typeof date === 'string' ? JSON.parse(date) : date;
    return `${year}-${(month + 1)
      .toString()
      .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }
};
