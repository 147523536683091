import { Injectable } from '@angular/core';

import { Tax, TypeTax } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  taxes: Tax[] = [
    {
      _id: '32',
      name: 'IMPUESTO 1%',
      translate: 'taxes.tax',
      value: 1,
      type: TypeTax.IVA,
    },
    {
      _id: '42',
      name: 'IMPUESTO 1.25%',
      translate: 'taxes.tax',
      value: 1.25,
      type: TypeTax.IVA,
    },
    {
      _id: '44',
      name: 'IMPUESTO 1.5%',
      translate: 'taxes.tax',
      value: 1.5,
      type: TypeTax.IVA,
    },
    {
      _id: '22',
      name: 'IMPUESTO 2%',
      translate: 'taxes.tax',
      value: 2,
      type: TypeTax.IVA,
    },
    {
      _id: '2',
      name: 'IMPUESTO 3%',
      translate: 'taxes.tax',
      value: 3,
      type: TypeTax.IVA,
    },
    {
      _id: '3',
      name: 'IMPUESTO 4%',
      translate: 'taxes.tax',
      value: 4,
      type: TypeTax.IVA,
    },
    {
      _id: '33',
      name: 'IMPUESTO 4.5%',
      translate: 'taxes.tax',
      value: 4.5,
      type: TypeTax.IVA,
    },
    {
      _id: '27',
      name: 'IMPUESTO 5%',
      translate: 'taxes.tax',
      value: 5,
      type: TypeTax.IVA,
    },
    {
      _id: '43',
      name: 'IMPUESTO 5.5%',
      translate: 'taxes.tax',
      value: 5.5,
      type: TypeTax.IVA,
    },
    {
      _id: '45',
      name: 'IMPUESTO 6%',
      translate: 'taxes.tax',
      value: 6,
      type: TypeTax.IVA,
    },
    {
      _id: '23',
      name: 'IMPUESTO 6.5%',
      translate: 'taxes.tax',
      value: 6.5,
      type: TypeTax.IVA,
    },
    {
      _id: '13',
      name: 'IMPUESTO 7%',
      translate: 'taxes.tax',
      value: 7,
      type: TypeTax.IVA,
    },
    {
      _id: '46',
      name: 'IMPUESTO 7.25%',
      translate: 'taxes.tax',
      value: 7.25,
      type: TypeTax.IVA,
    },
    {
      _id: '34',
      name: 'IMPUESTO 8%',
      translate: 'taxes.tax',
      value: 8,
      type: TypeTax.IVA,
    },
    {
      _id: '4',
      name: 'IMPUESTO 10%',
      translate: 'taxes.tax',
      value: 10,
      type: TypeTax.IVA,
    },
    {
      _id: '47',
      name: 'IMPUESTO 10.75%',
      translate: 'taxes.tax',
      value: 10.75,
      type: TypeTax.IVA,
    },
    {
      _id: '14',
      name: 'IMPUESTO 12%',
      translate: 'taxes.tax',
      value: 12,
      type: TypeTax.IVA,
    },
    {
      _id: '15',
      name: 'IMPUESTO 13%',
      translate: 'taxes.tax',
      value: 13,
      type: TypeTax.IVA,
    },
    {
      _id: '16',
      name: 'IMPUESTO 15%',
      translate: 'taxes.tax',
      value: 15,
      type: TypeTax.IVA,
    },
    {
      _id: '17',
      name: 'IMPUESTO 16%',
      translate: 'taxes.tax',
      value: 16,
      type: TypeTax.IVA,
    },
    {
      _id: '18',
      name: 'IMPUESTO 17%',
      translate: 'taxes.tax',
      value: 17,
      type: TypeTax.IVA,
    },
    {
      _id: '19',
      name: 'IMPUESTO 18%',
      translate: 'taxes.tax',
      value: 18,
      type: TypeTax.IVA,
    },
    {
      _id: '20',
      name: 'IMPUESTO 19%',
      translate: 'taxes.tax',
      value: 19,
      type: TypeTax.IVA,
    },
    {
      _id: '25',
      name: 'IMPUESTO 20%',
      translate: 'taxes.tax',
      value: 20,
      type: TypeTax.IVA,
    },
    {
      _id: '1',
      name: 'IMPUESTO 21%',
      translate: 'taxes.tax',
      value: 21,
      type: TypeTax.IVA,
    },
    {
      _id: '21',
      name: 'IMPUESTO 22%',
      translate: 'taxes.tax',
      value: 22,
      type: TypeTax.IVA,
    },
    {
      _id: '30',
      name: 'IMPUESTO 23%',
      translate: 'taxes.tax',
      value: 23,
      type: TypeTax.IVA,
    },
    {
      _id: '5',
      name: 'IMPUESTO 30%',
      translate: 'taxes.tax',
      value: 30,
      type: TypeTax.IVA,
    },
    {
      _id: '48',
      name: 'IMPUESTO 35%',
      translate: 'taxes.tax',
      value: 35,
      type: TypeTax.IVA,
    },
    {
      _id: '24',
      name: 'RET 1%',
      translate: 'taxes.retention',
      value: 1,
      type: TypeTax.RET,
    },
    {
      _id: '31',
      name: 'RET 2%',
      translate: 'taxes.retention',
      value: 2,
      type: TypeTax.RET,
    },
    {
      _id: '26',
      name: 'RET 3%',
      translate: 'taxes.retention',
      value: 3,
      type: TypeTax.RET,
    },
    {
      _id: '29',
      name: 'RET 5%',
      translate: 'taxes.retention',
      value: 5,
      type: TypeTax.RET,
    },
    {
      _id: '9',
      name: 'RET 7%',
      translate: 'taxes.retention',
      value: 7,
      type: TypeTax.RET,
    },
    {
      _id: '8',
      name: 'RET 15%',
      translate: 'taxes.retention',
      value: 15,
      type: TypeTax.RET,
    },
    {
      _id: '7',
      name: 'RET 19%',
      translate: 'taxes.retention',
      value: 19,
      type: TypeTax.RET,
    },

    {
      _id: '11',
      name: 'REC 0,5%',
      translate: 'taxes.rec',
      value: 0.5,
      type: TypeTax.REC,
    },
    {
      _id: '10',
      name: 'REC 1,4%',
      translate: 'taxes.rec',
      value: 1.4,
      type: TypeTax.REC,
    },

    {
      _id: '12',
      name: 'REC 1,75%',
      translate: 'taxes.rec',
      value: 1.75,
      type: TypeTax.REC,
    },
    {
      _id: '28',
      name: 'REC 5,2%',
      translate: 'taxes.rec',
      value: 5.2,
      type: TypeTax.REC,
    },
    {
      _id: '6',
      name: 'EXEN',
      translate: 'taxes.exen',
      value: 0,
      type: TypeTax.IVA,
    },
    {
      _id: '35',
      name: 'IEPS 8%',
      translate: 'taxes.ieps',
      value: 8,
      type: TypeTax.IVA,
    },
    {
      _id: '36',
      name: 'IEPS 26.5%',
      translate: 'taxes.ieps',
      value: 26.5,
      type: TypeTax.IVA,
    },
    {
      _id: '37',
      name: 'IEPS 30%',
      translate: 'taxes.ieps',
      value: 30,
      type: TypeTax.IVA,
    },
    {
      _id: '38',
      name: 'IEPS 30.40%',
      translate: 'taxes.ieps',
      value: 30.4,
      type: TypeTax.IVA,
    },
    {
      _id: '39',
      name: 'IEPS 50%',
      translate: 'taxes.ieps',
      value: 50,
      type: TypeTax.IVA,
    },
    {
      _id: '40',
      name: 'IEPS 53%',
      translate: 'taxes.ieps',
      value: 53,
      type: TypeTax.IVA,
    },
    {
      _id: '41',
      name: 'IEPS 160%',
      translate: 'taxes.ieps',
      value: 160,
      type: TypeTax.IVA,
    },
  ];

  constructor() {}

  findAll(): Tax[] {
    return this.sort();
  }

  sort(taxes = this.taxes): Tax[] {
    // Group by type
    const groups = taxes.reduce((groups, item) => {
      const group = groups[item.translate] || [];
      group.push(item);
      groups[item.translate] = group;
      return groups;
    }, {});

    // Sort each group
    for (const type in groups) {
      groups[type].sort((a: Tax, b: Tax) => a.value - b.value);
    }

    return Object.values(groups).flat();
  }
}
