import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSendComponent } from './modal-send.component';
import { ModalTemplateModule } from '../modal-template/modal-template.module';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ModalInfoModule } from '../modal-info';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingModule } from '../loading';
import { FieldErrorDisplayModule } from '../field-error-display';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';
import { RecaptchaModule } from '../../../core/recaptcha/recaptcha.module';

@NgModule({
  declarations: [ModalSendComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatChipsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    ModalInfoModule,
    ImageModule,
    FieldErrorDisplayModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    LoadingModule,
    BaseButtonModule,
    RecaptchaModule,
  ],
  exports: [ModalSendComponent],
})
export class ModalSendModule {}
