<div (focusout)="focusOut('users')">
  <div
    class="fake-select-overlay"
    *ngIf="(!showPanelElement && !disabled) || isLoading"
    [ngStyle]="{ height: inputHeight }"
    (click)="focusOutA = false; clickPanel($event, null)"
  ></div>
  <div
    class="fake-clean-overlay"
    *ngIf="
      (!isLoading && activeItems?.length && mode === 'MULTIPLE') ||
      (!isLoading && activeItems?.length && isClearable)
    "
    (click)="focusOutA = false; clearInput('in')"
  ></div>
  <div
    class="fake-select"
    *ngIf="!showPanelElement || isLoading"
    [ngStyle]="{ height: inputHeight }"
    [ngClass]="{
      active: showPanelElement,
      invalid: hasError,
      'border-left': borderLeft
    }"
  >
    <div class="fake-select-content">
      <span *ngIf="!activeItems?.length">
        {{ placeholderText | translate }}
      </span>
      <ng-container *ngIf="activeItems?.length">
        <ng-container *ngIf="mode === 'SINGLE'">
          <span class="single-label">
            {{ activeItems[0]?.title | translate }}
          </span>
        </ng-container>
        <ng-container
          *ngIf="displayMultipleActive && mode === 'MULTIPLE'; else multiple"
        >
          <div class="active-item-container">
            <span *ngFor="let activeItem of activeItems" class="active-item">
              {{ sliceTitle(activeItem.title, 3) }}
            </span>
          </div>
        </ng-container>
        <ng-template #multiple>
          <ng-container *ngIf="mode === 'MULTIPLE'">
            {{ placeholderText }}
            <span class="fake-item">({{ activeItems?.length }})</span>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
    <div class="fake-select-options">
      <img
        *ngIf="!isLoading && !disabled && showPanelElement"
        [src]="'assets/icons/active-up-arrow.svg'"
        width="20px"
      />
      <img
        *ngIf="!isLoading && !disabled && !showPanelElement"
        [src]="'assets/icons/gl_arrow_down.svg'"
        width="20px"
      />
      <img
        *ngIf="disabled"
        width="20px"
        [src]="'assets/icons/disable-down-arrow.svg'"
      />
      <img
        class="clean-input"
        *ngIf="
          (!isLoading && activeItems?.length && mode === 'MULTIPLE') ||
          (!isLoading && activeItems?.length && isClearable)
        "
        src="assets/icons/gl_close-input.svg"
      />
      <mat-progress-spinner
        *ngIf="isLoading && !disabled"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
<div>
  <!--(focusout)="focusOut('status')"-->
  <div
    *ngIf="!isLoading && showPanelElement && mode === 'SINGLE'"
    romaRomaToolTip
    [showToolTip]="showPanelElement"
    (clicked)="showPanelElement = $event"
    class="users-list"
    [ngStyle]="listWidth !== '' && { width: listWidth }"
    [ngClass]="{ 'is-absolute': isAbsolute, 'border-left': borderLeft }"
    (focusin)="focusInternal = true"
  >
    <div class="search-input">
      <input
        #inputFilterSearch
        class="filter-input"
        [ngStyle]="{ height: inputHeight }"
        type="text"
        [placeholder]="'general.search' | translate"
      />
      <img
        [src]="'assets/icons/gl_arrow_down.svg'"
        (click)="focusOutA = false; clickPanel($event, null)"
      />
    </div>
    <div class="list-wrapper" [ngClass]="{ 'solo-list': type !== 'users' }">
      <div
        *ngFor="let item of items | searchFilter: filterArgs; let i = index"
        class="user-item single-check"
        [ngClass]="{
          active:
            (activeItems?.length > 0 && activeItems[0]?.value) === item?.value
        }"
        (click)="changeSelect(item)"
      >
        <img
          *ngIf="
            (activeItems?.length > 0 && activeItems[0]?.value) === item?.value
          "
          class="active-check"
          src="assets/icons/gl_check.svg"
        />
        <roma-user-avatar
          *ngIf="item.hasOwnProperty('img')"
          [user]="item"
          width="16"
          height="16"
        ></roma-user-avatar>
        {{ item.title | translate }}
        <span *ngIf="item.price" class="__price">
          {{ item.price || 0 | currencyFormat }}
        </span>
      </div>
    </div>
    <div
      *ngIf="type === 'users'"
      class="add-user"
      romaProFeature
      [featureUser]="addButtonFeatureUser"
      (clicked)="addElementHandle($event)"
    >
      <img src="/assets/icons/gl_plus.svg" />
      {{ addButtonText | translate }}
    </div>
  </div>

  <div
    *ngIf="!isLoading && showPanelElement && mode === 'MULTIPLE'"
    romaRomaToolTip
    [showToolTip]="showPanelElement"
    (clicked)="showPanelElement = $event"
    class="users-list"
    style="padding-top: 0"
    [ngStyle]="listWidth !== '' && { width: listWidth }"
    [ngClass]="{ 'is-absolute': isAbsolute }"
    (focusin)="focusInternal = true"
  >
    <div class="search-input">
      <input
        #inputFilterSearch
        [ngStyle]="{ height: inputHeight }"
        class="filter-input"
        type="text"
        [placeholder]="'general.search' | translate"
      />
      <img
        [src]="'assets/icons/gl_arrow_down.svg'"
        (click)="focusOutA = false; clickPanel($event, null)"
      />
    </div>
    <div class="list-wrapper">
      <div
        *ngFor="let item of items | searchFilter: filterArgs; let i = index"
        class="user-item"
      >
        <div *ngIf="type !== 'taxes'">
          <mat-checkbox
            (click)="
              changeCheckBoxByClick($event, item); $event.stopPropagation()
            "
            (change)="anuleFocusOutTimeout(); changeCheckBox($event, item)"
            [checked]="searchCheckFilters(item) ? true : false"
          >
            <div *ngIf="type === 'taxes'">
              {{ item?.name }}
            </div>

            <div class="flex-row">
              <roma-user-avatar
                *ngIf="item.hasOwnProperty('img')"
                [user]="item"
                width="20"
                height="20"
              ></roma-user-avatar>
              <div class="ml-2">
                {{ item.title | translate }}
              </div>
            </div>
            <span *ngIf="item.price" class="__price">
              {{ item.price || 0 | currencyFormat }}
            </span>
          </mat-checkbox>
        </div>

        <div *ngIf="type === 'taxes'">
          <mat-checkbox
            (change)="anuleFocusOutTimeout(); changeCheckBox($event, item)"
            (click)="
              changeCheckBoxByClick($event, item); $event.stopPropagation()
            "
            [checked]="searchCheckFilters(item) ? true : false"
          >
            {{ item.title | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div
      *ngIf="type === 'users' && showAddButton"
      class="add-user"
      romaProFeature
      [featureUser]="addButtonFeatureUser"
      (clicked)="addElementHandle($event)"
    >
      <img src="/assets/icons/gl_plus.svg" />
      {{ addButtonText | translate }}
    </div>
  </div>
</div>
