import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RomaNgSelectModule } from '@web-frontend/shared/components/ng-select/roma-ng-select.module';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { TranslateModule } from '@ngx-translate/core';

import { SelectSearchComponent } from './select-search.component';
import { GooglePlacesModule } from '../../directives/google-places/google-places.module';

@NgModule({
  declarations: [SelectSearchComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    GooglePlacesModule,
    NgxMatSelectSearchModule,
    RomaNgSelectModule,
  ],
  exports: [SelectSearchComponent],
})
export class SelectSearchModule {}
