import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule } from '@web-frontend/shared/helpers/alert';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalTemplateModule } from '../modal-template';
import { PhoneManagerModule } from '../phone-manager';
import { FieldErrorDisplayModule } from '../field-error-display';
import { CreateEquipmentModule } from '../create-equipment';
import { ModalInfoModule } from '../modal-info';
import { CreateBudgetMethodPaymentComponent } from './create-budget-payment-method.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CreateBudgetMethodPaymentComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FieldErrorDisplayModule,
    PhoneManagerModule,

    CreateEquipmentModule,
    AlertModule,
    ModalInfoModule,
    MatSelectModule,
    NgSelectModule,
  ],
  exports: [CreateBudgetMethodPaymentComponent],
})
export class CreateProjectModule {}
