/* eslint-disable @typescript-eslint/no-namespace */
import { dateObjectToString } from '../../../../app/shared/utils/dateAndTime';
import { IUser } from '../../interfaces';
import { differenceInCalendarDays, addDays, getDay } from 'date-fns';

const dayNameToNumber: { [key: string]: number } = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export namespace ClockAbsence {
  export interface Schema {
    dateCreated: Date;
    idCreatedBy: string;
    idOwner: string;
    idCompany: string;
    type: ClockAbsence.AbsenceType;
    typeDetail: ClockAbsence.TypeDetail;
    dateDetail: ClockAbsence.dateDetail;
    status: ClockAbsence.Status;
    note: string;
    deleted: boolean;
    reasonRejected: {
      description: string;
      idCreatedBy: string;
    };
  }

  export enum Status {
    Pending,
    Rejected,
    Approved,
    Other,
  }

  export interface dateDetail {
    startDate: string;
    startDateUTC: Date;
    endDate: string;
    endDateUTC: Date;
  }

  export interface TypeDetail {
    type: ClockAbsence.Type;
    halfDayType?: ClockAbsence.HalfDayType;
    files?: string[];
  }

  export enum Type {
    HalfDay,
    OneDay,
    SeveralDays,
  }

  export enum HalfDayType {
    FirstHalfOfTheDay,
    SecondHalfOfTheDay,
  }

  export enum AbsenceType {
    Vacation,
    Sickness,
    MaternityOrPaternity,
    SicknessOfAFamilyMember,
    Other,
  }

  export interface Output extends ClockAbsence.Schema {
    _id: string;
    owner: IUser;
    totalDays?: number;
  }

  export interface ClockDate {
    year: number;
    month: number;
    day: number;
  }

  export interface Query {
    idCompanies: string[];
    idOwners?: string[];
    keyword?: string;
    status?: ClockAbsence.Status[];
    types?: ClockAbsence.AbsenceType[];
    to?: string;
    from?: string;
    page?: number;
    limit?: number;
    ids: string[];
    applyPaginate?: boolean;
  }

  export const GetTotalDays = ({
    startDate,
    endDate,
    type,
  }: {
    startDate: string;
    endDate: string;
    type: ClockAbsence.Type;
  }): number => {
    if (type === ClockAbsence.Type.HalfDay) {
      return 0.5;
    }

    if (type === ClockAbsence.Type.OneDay) {
      return 1;
    }

    if (!endDate) {
      return 1;
    }

    const totalDays =
      differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1;
    return totalDays < 0 ? 0 : totalDays;
  };

  const isWorkday = (
    date: Date,
    workdays: string[],
    holidays: string[]
  ): boolean => {
    if (workdays.length === 0) {
      return !holidays.includes(date.toISOString().split('T')[0]);
    }

    const dayNumber = getDay(date);
    const dayName = Object.keys(dayNameToNumber).find(
      (key) => dayNameToNumber[key] === dayNumber
    );
    const dateWithoutTimezone = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0];
    const isHoliday = holidays.includes(dateWithoutTimezone);
    return dayName ? workdays.includes(dayName) && !isHoliday : false;
  };

  const calculateTotalWorkdays = (
    start: Date,
    end: Date,
    workdays: string[],
    holidays: string[]
  ): number => {
    let totalWorkdays = 0;
    const totalDays = differenceInCalendarDays(end, start) + 1;

    for (let i = 0; i < totalDays; i++) {
      const currentDay = addDays(start, i);
      if (isWorkday(currentDay, workdays, holidays)) {
        totalWorkdays++;
      }
    }

    return totalWorkdays;
  };

  export const GetTotalWorkdays = ({
    startDate,
    endDate,
    type,
    workdays = [],
    holidays = [],
  }: {
    startDate: string;
    endDate: string;
    type: ClockAbsence.Type;
    workdays?: string[];
    holidays?: string[];
  }): number => {
    const start = new Date(startDate);
    const isStartWorkday = isWorkday(start, workdays, holidays);

    if (!endDate) {
      return isStartWorkday ? 1 : 0;
    }

    if (type === ClockAbsence.Type.HalfDay) {
      return isStartWorkday ? 0.5 : 0;
    }

    if (type === ClockAbsence.Type.OneDay) {
      return isStartWorkday ? 1 : 0;
    }

    const end = new Date(endDate);
    return calculateTotalWorkdays(start, end, workdays, holidays);
  };

  export const hasAbsences = (
    absences: ClockAbsence.Output[],
    dateToCheck: { year: number; month: number; day: number }
  ): boolean => {
    const date = dateObjectToString(dateToCheck);
    return absences?.some((absence: ClockAbsence.Output) => {
      const startDate = absence.dateDetail.startDate;
      const endDate = absence.dateDetail.endDate;
      return date >= startDate && date <= endDate;
    });
  };
}
