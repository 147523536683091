import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast.component';
import { MatIconModule } from '@angular/material/icon';
import { TOAST_CONFIG_TOKEN } from './utils/toast-config';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [OverlayModule, MatIconModule, CommonModule, TranslateModule],
    declarations: [ToastComponent]
})
export class ToastModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: {},
        },
      ],
    };
  }
}
