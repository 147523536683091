import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { Injectable } from '@angular/core';

import {
  BudgetsSetting,
  COMPANY_DATA,
  COMPANY_ID_KEY,
  DATE_FORMAT,
  dates_format,
  decimals,
  FIRST_DAY_CALENDAR,
  IBIllBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  IBudgetT,
  IMailSend,
  IProform,
  lang,
  numbers_format,
  PaginateResponse,
  PaymentInfoBudget,
  zones,
  PaginateSort,
  BDG_SETTINGS,
  currencies,
  TypeBudget,
} from '@tacliatech/types';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';

import {
  BehaviorSubject,
  from,
  Observable,
  Subscription,
  throwError,
} from 'rxjs';

import { catchError, concatMap, tap } from 'rxjs/operators';

import { FileDownloadService } from '@web-frontend/shared/helpers/file-download';
import { environment } from '@web-frontend/environments';

import { StorageService } from '../storage/storage.service';
import { replaceNonEnglishCharacters } from '@web-frontend/shared/utils/replace-non-english-characters';
import Stripe from 'stripe';
import { checkIfCompanyIsValidForBilling } from '@web-frontend/shared/utils/invoice-validator';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  private sub$ = new Subscription();

  // @ts-ignore
  private settingsCache = new BehaviorSubject<BudgetsSetting>(null);

  settings$ = this.settingsCache.asObservable();

  constructor(
    private http: HttpClient,
    private fileWriteService: FileWriteService,
    private fileDownloadService: FileDownloadService
  ) {}

  zones = zones;

  numbers_format = numbers_format;

  dates_format = dates_format;

  decimals = decimals;

  lang = lang;

  init() {
    const companyId = StorageService.GetItem(COMPANY_ID_KEY) || null;
    if (companyId) {
      this.sub$.add(
        this.findSettingsByCompany(companyId).subscribe((res) => {
          this.updateSettingsCache = res;
          const format = this.dates_format.find(
            (format) =>
              format.id.toString() ===
              res?.budgetPreferences?.date_format?.toString()
          );
          const firstDay = res.budgetPreferences?.day || 0;
          StorageService.SetItem(FIRST_DAY_CALENDAR, firstDay);

          StorageService.SetItem(
            DATE_FORMAT,
            format ? format.text : 'dd/MM/yyyy'
          );

          // LEGACY WAY
          // The _id is excluded because it is overriding
          // the _id property of company in local storage
          const { _id, ...rest } = res;
          const companyData = JSON.parse(StorageService.companyData);
          if (companyData) {
            companyData.data_account = res?.data_account;
          }

          StorageService.SetItem(COMPANY_DATA, {
            ...rest,
            ...companyData,
            _id: companyId,
          });

          // New way to save settings
          const budgetSettingsData = JSON.parse(StorageService.budgetSettings);
          StorageService.SetItem(BDG_SETTINGS, {
            ...budgetSettingsData,
            ...res,
          });

          return StorageService.GetItem(DATE_FORMAT);
        })
      );
    }
  }

  public get date_format() {
    if (!StorageService.GetItem(DATE_FORMAT)) {
      return this.init();
    }
    return StorageService.GetItem(DATE_FORMAT);
  }

  /*   BUDGETS   */
  // ----------------------------------------------------------

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<IBudgetCommonData[]>>(
      `:API_URL/budgets/search`,
      { params }
    );
  }

  findAllBudget() {
    return this.http.get<IBudgetT[]>(`:API_URL/budgets/budget`);
  }

  findOneBudget(id: string) {
    return this.http.get<IBudgetT>(`:API_URL/budgets/budget/${id}`);
  }

  findBudgetDetail(id: string, type: string) {
    return this.http.get<IBudgetT>(
      `:API_URL/budgets/budget-detail/${type}/${id}`
    );
  }

  findBudgetAdd(idFinal: string, type: string) {
    return this.http.get<IBudgetT>(
      `:API_URL/budgets/budget-detail-add/${type}/${idFinal}`
    );
  }

  createOne(data: IBudgetCommonData) {
    return this.http.post<IBudgetCommonData>(
      `:API_URL/budgets/budget-new/`,
      data
    );
  }

  updateOne(id: string, data: any) {
    return this.http.put<IBudgetCommonData>(
      `:API_URL/budgets/budget-new/${id}`,
      data
    );
  }

  /**
   * This function updates the status of a budget with a given ID using an HTTP PUT request.
   * @param {string} id - A string representing the ID of the budget whose status needs to be updated.
   * @param data - The `data` parameter is an object that contains a single property `status` which is a
   * number. This parameter is used to update the status of a budget with the given `id`. The `put`
   * method of the `http` service is used to send an HTTP PUT request to the API
   * @returns The `updateStatus` method is returning an HTTP PUT request to update the status of a budget
   * with the specified `id` using the data provided in the `data` object. The response is expected to be
   * an `IBudgetCommonData` object.
   */
  updateStatus(id: string, data: { status: number }) {
    return this.http.put<IBudgetCommonData>(
      `:API_URL/budgets/budget-status/${id}`,
      data
    );
  }

  deleteOne(id: string, type: string) {
    return this.http.delete<IBudgetCommonData>(
      `:API_URL/budgets/${type}/${id}`
    );
  }

  findMax(type) {
    return this.http.get<any>(`:API_URL/budgets/lastid/${type}`);
  }

  /*   PROFORM   */
  // ----------------------------------------------------------

  findAllProforms() {
    return this.http.get<IProform[]>(`:API_URL/budgets/proforms`);
  }

  findOneProform(id: string) {
    return this.http.get<IProform>(`:API_URL/budgets/proforms/${id}`);
  }

  createOneProform(data: IBudgetCommonData) {
    return this.http.post<IBudgetCommonData>(`:API_URL/proforms/new/`, data);
  }

  updateOneProform(id: string, data: any) {
    return this.http.put<IBudgetCommonData>(
      `:API_URL/proforms/new/${id}`,
      data
    );
  }

  /**
   * The function `updateStatusProform` sends a PUT request to update the status of a budget proform
   * with the specified ID.
   * @param {string} id - A string representing the ID of the proform.
   * @param data - The `data` parameter is an object that contains the `status` property. The `status`
   * property is a number that represents the updated status value for the proform with the specified
   * `id`.
   * @returns an HTTP PUT request to update the status of a proform with the given ID. The request is
   * sent to the specified API URL with the provided data. The response is expected to be of type
   * IBudgetCommonData.
   */
  updateStatusProform(id: string, data: { status: number }) {
    return this.http.put<IBudgetCommonData>(
      `:API_URL/proforms/budget-status/${id}`,
      data
    );
  }

  /*   BILLS   */
  // ----------------------------------------------------------

  findAllBills() {
    return this.http.get<IBIllBudget[]>(`:API_URL/budgets/bills`);
  }

  findOneBill(id: string) {
    return this.http.get<IBIllBudget>(`:API_URL/budgets/bills/${id}`);
  }

  findOneWaybill(id: string) {
    return this.http.get<IBIllBudget>(`:API_URL/budgets/waybills/${id}`);
  }

  createOneBill(data: IBudgetCommonData) {
    return this.http.post<IBudgetCommonData>(`:API_URL/billsb/new/`, data);
  }

  createOneWaybill(data: IBudgetCommonData) {
    return this.http.post<IBudgetCommonData>(`:API_URL/waybillsb/new/`, data);
  }

  updateOneBill(id: string, data: any) {
    // console.log('data:', data);
    return this.http.put<IBudgetCommonData>(`:API_URL/billsb/new/${id}`, data);
  }

  updateOneWaybill(id: string, data: any) {
    // console.log('data:', data);
    return this.http.put<IBudgetCommonData>(
      `:API_URL/waybillsb/new/${id}`,
      data
    );
  }

  updateManyWaybills(data: any) {
    return this.http.put<IBudgetCommonData>(`:API_URL/waybillsb/update`, data);
  }

  /*   SETTINGS   */
  // ----------------------------------------------------------

  findAllZones() {
    return this.zones;
  }

  findAllCurrencys() {
    return currencies;
  }

  findAllNumberFormats() {
    return this.numbers_format;
  }

  findAllDecimals() {
    return this.decimals;
  }

  findAllDateFormats() {
    return this.dates_format;
  }

  findAllDays() {
    return [
      { id: 1, text: 'daysOfTheWeek.Monday' },
      { id: 2, text: 'daysOfTheWeek.Tuesday' },
      { id: 3, text: 'daysOfTheWeek.Wednesday' },
      { id: 4, text: 'daysOfTheWeek.Thursday' },
      { id: 5, text: 'daysOfTheWeek.Friday' },
      { id: 6, text: 'daysOfTheWeek.Saturday' },
      { id: 7, text: 'daysOfTheWeek.Sunday' },
    ];
  }

  findAllLangs() {
    return this.lang;
  }

  createOneSetting(data: IBudgetsSetting) {
    this.updateSettingsCache = data;
    return this.http.post<any>(`:API_URL/budgets-settings/company/`, data);
  }

  updateOneSettings(id: string, data: any) {
    this.updateSettingsCache = data;
    return this.http.put<IBudgetCommonData>(
      `:API_URL/budgets-settings/${id}`,
      data
    );
  }

  findSettingsByCompany(id: string) {
    return this.http
      .get<IBudgetsSetting>(`:API_URL/budgets-settings/company/${id}`)
      .pipe(
        tap((data) => (this.updateSettingsCache = data)),
        catchError(this.errorHandler)
      );
  }

  set updateSettingsCache(data: IBudgetsSetting) {
    this.settingsCache.next(data);
  }

  /*   PAYMENTS METHODS   */
  // ----------------------------------------------------------
  findAllPaymentsMethods(id: string) {
    return this.http
      .get<PaymentInfoBudget[]>(
        `:API_URL/budgets-payments-methods/company/${id}`
      )
      .pipe(catchError(this.errorHandler));
  }

  deletaPaymentMethod(_id: string) {
    return this.http
      .delete<PaymentInfoBudget[]>(`:API_URL/budgets-payments-methods/${_id}`)
      .pipe(catchError(this.errorHandler));
  }

  createPaymentMethod(data: any) {
    return this.http.post<any>(`:API_URL/budgets-payments-methods`, data);
  }

  updateOnePaymentMethod(id: string, data: any) {
    return this.http.put<any>(`:API_URL/budgets-payments-methods/${id}`, data);
  }

  /* updateOne(id: string, data: IOwnTeam) {
    return this.http.put<IOwnTeam>(`:API_URL/own-team/${id}`, data);
  }

  updateImg(id: string, data: any) {
    return this.http.put<IOwnTeam>(`:API_URL/own-team/${id}`, data);
  } */

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || 'server error.');
  }

  sendDocument(data: IMailSend): Observable<void> {
    return this.http.post<void>(`:API_URL/budgets/mails/`, data);
  }

  downloadDocument(data: any) {
    const { id, finalName, fileName, ...rest } = data;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${StorageService.Token}`,
      }),
      responseType: 'blob' as const,
    };

    return this.http
      .post(
        `${environment.cloudFunctionCreateSalesPDF}?id=${id}`,
        rest,
        httpOptions
      )
      .pipe(
        concatMap((res) => {
          const obs$ = from(
            this.fileWriteService.writeFile({
              path: fileName
                ? replaceNonEnglishCharacters(fileName) + '.pdf'
                : `${data.type}${data.document}${
                    data?.user?.company?.name
                      ? '_' +
                        replaceNonEnglishCharacters(data.user.company?.name)
                      : ''
                  }${
                    finalName
                      ? '_' + replaceNonEnglishCharacters(finalName)
                      : ''
                  }.pdf`,
              blob: res,
            })
          );

          return obs$;
        })
      );
  }

  seeDocument(data: any): Observable<Blob> {
    const { id, finalName, ...rest } = data;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${StorageService.Token}`,
      }),
      responseType: 'blob' as const,
    };
    return this.http.post(
      `${environment.cloudFunctionCreateSalesPDF}?id=${id}`,
      rest,
      httpOptions
    );
  }

  //
  downloadFile(url: string, fileName: string) {
    this.http.get(url, { responseType: 'blob' }).subscribe((file: Blob) => {
      this.fileWriteService
        .writeFile({
          path: `${fileName}.pdf`,
          blob: file,
        })
        .then()
        .catch((err) => {});
    });
  }

  //
  findAllPaymentMethodsByCompany_SELECT() {
    return this.http.get<PaymentInfoBudget[]>(
      `:API_URL/budgets-payments-methods/company/select`
    );
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/budgets/delete-many`, data);
  }

  deleteManyV2(data: { ids: string[]; type: string }) {
    const typeEndpoints = {
      [TypeBudget.BUDGET]: `:API_URL/v2/budgets/`,
      [TypeBudget.PROFORM]: `:API_URL/v2/proforms/`,
      [TypeBudget.BILL]: `:API_URL/v2/bills/`,
      [TypeBudget.WAL]: `:API_URL/v2/waybills/`,
    };
    const endpoint = typeEndpoints[data.type];
    return this.http.delete(endpoint, { body: data });
  }

  downloadMultiplePdfs(payload: IMailSend) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${StorageService.Token}`,
      }),
      responseType: 'blob' as const,
    };

    return this.http
      .post(
        environment.cloudFunctionCreateMultipleSalesPDF,
        {
          type: payload.type,
          lang: payload.lang,
          user: payload.user,
          ids: payload.ids,
          template: payload.template,
        },
        httpOptions
      )
      .pipe(
        concatMap((res) => {
          const obs$ = from(
            this.fileWriteService.writeFile({
              path: replaceNonEnglishCharacters(`${payload.fileName}.zip`),
              blob: res,
            })
          );

          return obs$;
        })
      );
  }

  columnToProp(e: { direction: PaginateSort; active: string }) {
    // To match table column with DB
    const event = { ...e };

    const active = event.active ? event.active : 'createdAt';
    switch (active) {
      case 'category':
        event.active = 'addicionalInfo.category.name';
        break;
      case 'customerName':
        event.active = 'header.final.name';
        break;
      case 'dealName':
        event.active = 'header.deal.name';
        break;
      case 'date':
        event.active = 'header.date';
        break;
      case 'dueDate':
        event.active = 'header.dueDate';
        break;
      case 'numDoc':
        event.active = 'numDoc';
        break;
      case 'paymentMethod':
        event.active = 'paymentMethod';
        break;
      case 'status_budget':
        event.active = 'status_budget';
        break;
      case 'total':
        event.active = 'total';
        break;
      default:
        event.active = 'createdAt';
        break;
    }

    return event;
  }

  createPaymentLinkForBill(id: string) {
    return this.http.post<Stripe.Response<Stripe.PaymentLink>>(
      `:API_URL/budgets/create-payment-link`,
      {
        id,
      }
    );
  }

  sendPaymentLinkEmail(idBill: string, data) {
    return this.http.post(
      `:API_URL/budgets/send-payment-link-email/${idBill}`,
      data
    );
  }

  validateBDGSettings() {
    return checkIfCompanyIsValidForBilling();
  }
}
