import { Injectable } from '@angular/core';
import {
  FirebaseAuthentication,
  SignInResult,
} from '@capacitor-firebase/authentication';

@Injectable()
export class NativeAuthService {
  async loginWithGoogle(): Promise<SignInResult> {
    const result = await FirebaseAuthentication.signInWithGoogle({
      scopes: ['email', 'profile'],
    });
    return await this.getSignInResult(result);
  }

  async loginWithApple(): Promise<SignInResult> {
    const result = await FirebaseAuthentication.signInWithApple({
      scopes: ['email', 'name'],
    });
    return await this.getSignInResult(result);
  }

  private async getSignInResult(result: SignInResult): Promise<SignInResult> {
    const resultToken = await FirebaseAuthentication.getIdToken();
    const credential = {
      ...result.credential,
      idToken: resultToken.token,
    };

    return {
      ...result,
      credential,
    };
  }
}
